
// const originalGet = Storage.prototype.getItem as ( (item: string) => string );
const originalGet = localStorage.getItem as ( (item: string) => string );
const originalSet = localStorage.setItem as ( (key: string, item: string) => void );
// tslint:disable-next-line:only-arrow-functions
Storage.prototype.set = function<T>(key: string|StorageKeys, value: T): void {

  // console.log('setting value', key, value);
  // @ts-ignore
  if (value === null || value === undefined || value === 'null' || value === 'undefined') {
    localStorage.setItem(key, null);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// tslint:disable-next-line:only-arrow-functions
Storage.prototype.get = function<T>(item: string|StorageKeys, defaultVal?: T): T {
  const value = localStorage.getItem(item);

  // console.log('getting value', item, value, defaultValue);
  if (value === null || value === undefined || value === 'null' || value === 'undefined') {
    if (defaultVal !== null && defaultVal !== undefined) {

      localStorage.set(item, defaultVal);
      return defaultVal;
    }
    return null;
  }

  let parsed = value;
  try {
    parsed = JSON.parse(value);
  } catch (e) {}
  // @ts-ignore
  return parsed as T;
};


export enum StorageKeys  {
  Token = 'token',
  CurrentQuoteToken = 'currentQuoteToken',
  CurrentLocationNumber = 'currentLocationNumber',
  SectionTab = 'sectionTab',
  CurrentBuildingNumber = 'currentBuildingNumber',
  CurrentQuote = 'currentQuote'
}


