 <div class="chat-icon" id="online-chat">
    <div id='ciGEQ5' tabindex="-1"></div>
    <div id='scGEQ5' tabindex="-1"></div>
    <div id='sdGEQ5' style='display:none'></div>
 </div>

 <div class="ask-b-chat-icon mat-fab mat-warn" id="ask-b-chat-demo-2" color="warn" *ngIf="UiService.isAskBChat()">
   <span class="mat-button-wrapper">
        <a class="nav-items-link"  (click)="UiService.openAskBChat()" title="AI powered Chatbot">
            <img src="../../../assets/img/icons/askb-bankers-white.png"
                 alt="AskB"
                 id="ask-b-logo-demo-2">
      </a>
   </span>
 </div>
