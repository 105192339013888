import {TemplateRef} from '@angular/core';


export interface Button {
  type: ActionType;
  text: string;
  alt?: string;
  style: ButtonStyle;
  onClick?: () => void;
  isIcon?: boolean;
}

export interface DialogConfig {
  cancelBtn?: Button;
  otherBtns?: Button[];
  submitBtn?: Button;
  title?: string;
  msg?: string;
  template?: TemplateRef<any>;
  size?: number|string;
}

export interface PrettyDialogConfig extends DialogConfig {
  type: ActionType;
}

export enum ButtonStyle  {
  None = 'none',
  Stroked = 'stroked',
  Solid = 'solid',
  Circle = 'circle'
}

export enum ActionType {
  Info = 'info',
  Warn = 'warn',
  Success = 'success',
  Danger = 'danger',
  Other = 'other',
}
