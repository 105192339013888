<div style="{{config.size ? 'width: ' + config.size : ''}}">


  <div mat-dialog-title class="alert-{{config.type}}">
    <span [innerHTML]="config?.title || ''"></span>
  </div>

  <div mat-dialog-content fxLayout="column">
    <span [innerHTML]="msg"></span>
    <ng-container *ngIf="config.template"
                  [ngTemplateOutlet]="config.template"></ng-container>
  </div>

  <div mat-dialog-actions fxLayout="row [wrap]" fxLayoutAlign="center bottom" fxLayoutGap="1rem"
       style="padding-top: 1rem;">
    <app-button *ngIf="config.cancelBtn" [config]="config.cancelBtn" mat-dialog-close
                (clicked)="config.cancelBtn.onClick();"></app-button>
    <ng-container *ngFor="let btn of config.otherBtns">
      <app-button [config]="btn"></app-button>
    </ng-container>
    <app-button *ngIf="config.submitBtn" [config]="config.submitBtn"
                (clicked)="submit()"></app-button>
  </div>

</div>
