import { Pipe, PipeTransform } from '@angular/core';
import {Day} from '../models/agency/agent';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {


  static DayFullToChar = (value: string|Day) => {
    switch (value as Day) {
      case Day.Monday:
        return 'M';
      case Day.Tuesday:
        return 'T';
      case Day.Wednesday:
        return 'W';
      case Day.Thursday:
        return 'R';
      case Day.Friday:
        return 'F';
      case Day.Saturday:
        return 'S';
      case Day.Sunday:
        return 'N';
      default:
        return '-';
    }
  }
  static DayCharToFull = (value: string) => {
    switch (value.toLowerCase()) {
      case 'm':
        return 'Monday';
      case 't':
        return 'Tuesday';
      case 'w':
        return 'Wednesday';
      case 'r':
        return 'Thursday';
      case 'f':
        return 'Friday';
      case 's':
        return 'Saturday';
      case 'n':
        return 'Sunday';
      default:
        return 'N/A';
    }
  }

  transform(value: string|Day): string {

    if (value.length === 1) {
      return DayPipe.DayCharToFull(value);
    }

    return DayPipe.DayFullToChar(value);
  }

}
