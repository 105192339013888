import {Injectable} from '@angular/core';
import {INavPage, NavPage, NavProvider} from '../shared/models/nav-page.model';
import {Agency, Agent, Day} from '../shared/models/agency/agent';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {NetworkingUtils} from '../shared/networking/networking.utils';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyService implements NavProvider {

  constructor(private http: HttpClient) {
  }

  public agent: Agent;
  // public agency: Agency = this.sampleAgency;
  public agency: Agency;
  public _agents = new BehaviorSubject<Agent[]>([]);
  get agents() {
    return this._agents.asObservable();
  }

  public async loadAgent() {
    this.agent = await this.http.get<Agent>(`https://localhost:5002/agency/7777777/agents/imsgdemo1`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })
    ).toPromise();
  }



  public async getAgent(userName: string) {
    const agent = await this.http.get<Agent>(`https://localhost:5002/agency/7777777/agents/${userName}`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })
    ).toPromise();

    return agent;

  }

  public async loadAgents() {
    if (this._agents.value.length) {
      return;
    }

    const agents = await this.http.get<Agent[]>(`https://localhost:5002/agency/7777777/agents`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })
    ).toPromise();
    this._agents.next(agents);
  }

  public async getAgency() {
    if (this.agency) {
      return this.agency;
    }

    this.agency = await this.http.get<Agency>(`https://localhost:5002/agency/7777777`).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })
    ).toPromise();

    return this.agency;
  }

  get pages(): Promise<INavPage[]> {
    return new Promise<INavPage[]>( async resolve => {
      if (!this.agent) {
        // await this.loadAgent();
      }

      const pages = [
        // new NavPage({
        //   pageTitle: 'My Account',
        //   navLink: `agency/agents/${this.agent?.userName ?? 'imsgdemo1'}`,
        //   navIcon: 'assignment_ind',
        // })
      ];
      if (this.agent?.access?.roles?.includes?.('producer') || true) {
        pages.push(new NavPage({
          pageTitle: 'Agency Details',
          navLink: 'agency',
          navIcon: 'home_work',
          subPages: [
            new NavPage({pageTitle: 'Details', navLink: 'details', navIcon: 'home_work'}),
            new NavPage({pageTitle: 'Agents', navLink: 'agents', navIcon: 'group'}),
            new NavPage({pageTitle: 'Banking', navLink: 'banking', navIcon: 'account_balance'})
          ]
        }));
      }

      resolve(pages);
    });
  }
}
