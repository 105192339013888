/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';
import {BaseEnvironment, Environments} from './base-environment'; // Included with Angular CLI.

export class Environment extends BaseEnvironment {

  public readonly production: boolean = false;
  public name: Environments = Environments.Local;

  loadConfig(json: any) {
    super.loadConfig(json);
    this.endpoints.authNet = 'https://jstest.authorize.net/v3/AcceptUI.js';
  }
}

export const environment = new Environment();
