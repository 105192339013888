<app-dimmer [fullScreen]="true"></app-dimmer>

<app-header (sideNavToggle)="sidenav.toggle()" class="head"></app-header>
<mat-sidenav-container [class.is-mobile]="mobileQuery.matches">
  <!--
  <mat-sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
               [opened]="!mobileQuery.matches && (this.navSvc.sideBarStateCollapseChanged$ | async) === false"
               [disableClose]="!mobileQuery.matches"
               role="navigation"
               #sidenav
               [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
<!!--    <ng-container *ngIf="navSvc.navTemplate" [ngTemplateOutlet]="navSvc.navTemplate"></ng-container>--!!>
<!!--    <ng-template #defaultSideNav>--!!>
      <app-side-nav></app-side-nav>
<!!--    </ng-template>--!!>
  </mat-sidenav>
-->
  <mat-sidenav
    #helpNav
    class="help-bar"
    (closed)="navSvc.helpBarCollapsed = true"
    [opened]="(navSvc.helpBarStateCollapseChanged$ | async) === false"
    mode="over"
    position="end">
    <app-help></app-help>
  </mat-sidenav>
  <mat-sidenav-content>
    <main class="content" [@routerAnimation]="o.isActivated ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
      <!--      <app-footer class="footer"></app-footer>-->
    </main>
   <app-chat *ngIf="!isLocal"></app-chat>
  </mat-sidenav-content>
</mat-sidenav-container>

