import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StorageKeys} from '../extensions/storage.extension';
import {Environments} from '../../../environments/base-environment';
import * as LogRocket from 'logrocket';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {


  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;

    const quoteToken = localStorage.get(StorageKeys.CurrentQuoteToken);
    // console.log(req.headers.get('TokenName'), Object.isNullOrUndefined(req.headers.get('TokenName')));
    const tokenName = (Object.isNullOrUndefined(req.headers.get('TokenName')) ? StorageKeys.Token : req.headers.get('TokenName')) || StorageKeys.Token;
    let token = localStorage.get(tokenName);
    headers = headers.delete('TokenName');

    if (tokenName.toLowerCase() === 'skiptoken') {
      token = null;
    } else if (!token) {
      // throw new Error(`Required token (${tokenName}) not found`);
      console.log(`Required token (${tokenName}) not found. Redirecting to login page.`);
      this.router.navigate(['auth']);
    }

    if (token) {
      let sessionUrl = '';
      // (ignore 'This condition will always return' compile error as environment is switched out on build)
      // @ts-ignore
      if (environment.name === Environments.Sqa || environment.name === Environments.Prod) {
        try {
          const lrCookieArr = document.cookie.split('; ').filter(cookie => cookie.includes('lr-session='));
          const lrCookie = lrCookieArr.length === 1 ? lrCookieArr[0].replace('lr-session=', '') : '';
          sessionUrl = LogRocket.sessionURL;
          sessionUrl = sessionUrl ? sessionUrl : lrCookie;
        } catch (e) {
          sessionUrl = '';
        }
      }

      headers = headers.delete('TokenName');
      if (quoteToken) {
        headers = headers.append('Policy', quoteToken);
      }
      headers = headers.append('LR-Session', sessionUrl);
      const newRequest = req.clone({headers: headers.append('Authorization', 'Bearer ' + token)});
      return next.handle(newRequest);
    }
    return next.handle(req);
  }

}

export const SkipToken = (otherHeaders: {[name: string]: string} = {}) => TokenName('skiptoken', otherHeaders);
export const TokenName = (name: string, otherHeaders: {[name: string]: string} = {}) => {
  const headers = otherHeaders ?? {};
  headers['TokenName'] = name;
  return {
    headers
  };
};
