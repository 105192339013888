import {InjectionToken} from '@angular/core';

export interface INavPage {
  pageTitle: string;
  navTitle?: string;
  navIcon?: string;
  navLink?: string;
  subPages?: INavPage[];
  disabled?: string;
  parentPageName?: string;
  loading?: boolean;
  isAccordion?: boolean;
}

export class NavPage implements INavPage {
  pageTitle: string;
  navTitle: string;
  navIcon?: string;
  navLink: string;
  subPages?: INavPage[];
  disabled?: string;
  parentPageName?: string;
  loading?: boolean;
  isAccordion?: boolean;

  constructor(page: INavPage, parentPage: INavPage|string = null) {
    this.pageTitle = page.pageTitle;
    this.navTitle = page.navTitle ?? page.pageTitle;
    this.navIcon = page.navIcon;
    this.navLink = page.navLink || this.navTitle.toLowerCase().trim().replaceAll(' ', '-').replaceAll('/', '-');
    this.subPages = page.subPages;
    this.disabled = page.disabled;
    this.isAccordion = page.isAccordion;
    if ((this.subPages && !this.isAccordion) || parentPage) {

      if (!parentPage) {
        this.subPages.forEach(p => p.navLink = p.navLink[0] === '/' ? p.navLink : this.navLink + '/' + p.navLink);
      } else if (typeof parentPage === 'string') {
        this.subPages.forEach(p => p.navLink = p.navLink[0] === '/' ? p.navLink : parentPage + '/' + p.navLink);
      } else if (parentPage?.navLink) {
        this.subPages.forEach(p => p.navLink = p.navLink[0] === '/' ? p.navLink : parentPage.navLink + '/' + p.navLink);
      } else {
      }
    }
    this.parentPageName = page.parentPageName;
  }

}

declare global {
  interface String {
    replaceAll: (search: string, replace: string) => string;
  }
}

String.prototype.replaceAll = function(search: string, replace: string) {
  search = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  return this.replace(new RegExp(search, 'g'), replace);
};


export interface NavProvider {
  pages: Promise<INavPage[]>;
}

