import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable()
export class AppInitService {

  // Important: It should return a Promise
  public init(): Promise<any> {
    return new Promise<any>( async resolve => {
      const response = await fetch('assets/environment.json');
      environment.loadConfig(await response.json());
      resolve();
    });
  }
}
