import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {NavService} from './nav.service';
import {RouterAnimation} from '../shared/animations/router.animation';
import {environment} from '../../environments/environment';
import {Environments} from '../../environments/base-environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [RouterAnimation]
})
export class NavComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('helpNav') helpNav: MatSidenav;
  @ViewChild('defaultSideNav') defaultSideNav: TemplateRef<any>;

  isLocal: boolean;
  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private router: Router,
              public navSvc: NavService) {
    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.navSvc.navTemplate = this.defaultSideNav;
    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    this.isLocal = environment?.name === Environments.Local;
  }


  ngOnInit() {
    // this.navSvc.sideBarStateCollapseChanged$.subscribe(s => console.log('nav state: ', s));
  }

  togglNav() {
    if (this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
