import {Handle, INode, IVarNode, IWhenNode, NodeDataType} from './types.dsl';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {AbstractControl, FormGroup} from '@angular/forms';
import {debounceTime, switchMap, tap} from 'rxjs/operators';

export interface WatchedValue {
  prevVal?: any;
  value: any;
  target: any;
  varPath: string;
  sub?: Subscription;
  proxy?: {
    handle: Handle,
    revoke: () => void;
    proxy: any;
  };
  usedByInterpreters: Set<string>;
  onChanges: {[interpreterId: string]: (() => Promise<void>)[]};
  checkInterval?: any;
  pusher?: BehaviorSubject<Handle|null>;
}

export class Watcher {

  private static Values: Record<string, WatchedValue> = {};
  private static ValuesChanged$ = new BehaviorSubject<WatchedValue[]>([]);
  public static ValuesChanged = Watcher.ValuesChanged$.asObservable();

  private constructor() {
  }

  private static PushChanges() {
    this.ValuesChanged$.next(Object.keys(this.Values).map(k => this.Values[k]));
  }

  public static DisposeAllFor(id: string) {
    const targetsToDelete: string[] = [];
    for (const targetPath of Object.keys(this.Values)) {
      const value = this.Values[targetPath];
      delete value.onChanges[id];
      if (!Object.keys(value.onChanges).length) {
        targetsToDelete.push(targetPath);
      }
    }
    targetsToDelete.forEach(path => {
      const value = this.Values[path];
      this.DisposeVal(value);
    });
    this.PushChanges();

  }

  private static ResetValHooks(watchedVal: WatchedValue) {
    watchedVal.sub?.unsubscribe();
    if (watchedVal.proxy) {
      // console.log('proxy', watchedVal.proxy);
      // console.log('proxy', watchedVal.proxy.handle.pointer);
      // console.log('proxy', watchedVal.proxy.handle.finalProp);
      // console.log('proxy', watchedVal.value);
      // console.log('proxy', watchedVal.proxy.handle.pointer[watchedVal.proxy.handle.finalProp]);

      // console.log('-----')
      // revoke proxy
      watchedVal.proxy.revoke();

      // delete the proxy prop otherwise error is thrown when we try to set it back to normal non-proxy value
      // watchedVal.proxy.handle.pointerParent[watchedVal.proxy.handle.pointerParentKey] = proxy;
      delete watchedVal.proxy.handle.pointerParent[watchedVal.proxy.handle.pointerParentKey];

      // console.log('proxy', watchedVal.proxy.handle.pointer);
      // console.log('proxy', watchedVal.proxy.handle.finalProp);
      // console.log('proxy', watchedVal.value);
      // set last value back to object
      watchedVal.proxy.handle.pointerParent[watchedVal.proxy.handle.pointerParentKey] = watchedVal.proxy.handle.pointer;
      // console.log('proxy', watchedVal.proxy.handle.pointer[watchedVal.proxy.handle.finalProp]);
      // console.log('proxy', watchedVal.proxy.handle.pointerParent[watchedVal.proxy.handle.pointerParentKey]);
    }
  }

  public static DisposeVal(watchedVal: WatchedValue) {
    this.ResetValHooks(watchedVal);
    watchedVal.onChanges = {};
    clearInterval(watchedVal.checkInterval);
    delete this.Values[watchedVal.varPath];
    this.PushChanges();
  }


  public static Get = (target: string) => Watcher.Values[target];

  public static Hook(id: string, varNode: IVarNode,
                     execVar: (varAction: IVarNode) => Promise<Handle>,
                     onChange?: () => Promise<void>) {

    const path = `${varNode.target}.${varNode.props.join('.')}`;

    // const getVal = (target: Handle): any => {
    //   let obj: any;
    //   if (target.pointer instanceof FormGroup) {
    //     obj = target.pointer.controls[target.finalProp];
    //   } else {
    //     obj = target.pointer[target.finalProp];
    //     if (!obj) {
    //       obj = target.pointer.question?.[target.finalProp];
    //     }
    //   }
    //   return obj;
    // };

    const subject = this.Watch(varNode, execVar);

    const watcher = this.Values[path];
    watcher.usedByInterpreters.add(id);
    if (!watcher.onChanges[id]) {
      watcher.onChanges[id] = [];
    }
    if (onChange) {
      watcher.onChanges[id].push(onChange);
    }
    return subject;

  }

  private static Watch(varNode: IVarNode, execVar: (varAction: IVarNode) => Promise<Handle>) {
    const path = `${varNode.target}.${varNode.props.join('.')}`;

    if (this.Values[path]) {
      console.log('pusing existing', path);
      return this.Values[path].pusher.asObservable();
    }
    console.log('pusing new', path);
    const watchedVal: WatchedValue = {
      onChanges: {},
      target: undefined,
      usedByInterpreters: new Set<string>(),
      value: undefined,
      varPath: path,
      pusher: new BehaviorSubject<Handle|null>(null)
    };
    this.Values[path] = watchedVal;


    const tryVar = async (): Promise<Handle|null> => {
      try {
        return await execVar(varNode);
      } catch (e) {
        return null;
      }
    };


    // const applyListener = (target: Handle) => {
    //   this.ResetValHooks(watcher);
    // }

    let checking = false;
    const checker = async () => {
      if (checking) {
        return;
      }
      checking = true;
      const handle = await tryVar();
      const prevHandle = watchedVal.pusher.value;

      if (!handle && prevHandle || handle && !prevHandle) {
        watchedVal.pusher.next(handle);
      }
      checking = false;
      // const target = handle?.pointerParent;
      //
      // const proxiedTarget = target?.pointer?.__isProxy__ ? Object.assign({}, target) : target;
      // const proxiedPrevTarget = prevTarget?.pointer?.__isProxy__ ? Object.assign({}, prevTarget) : prevTarget;
      // // if (prevTarget === target || (target instanceof Proxy && Object.propsEqual(Object.assign({}, target), prevTarget))) {
      // // if (prevTarget === target || (target?.pointer?.__isProxy__ && Object.propsEqual(Object.assign({}, target), prevTarget))) {
      // if (Object.propsEqual(proxiedTarget, proxiedPrevTarget)) {
      // // if (prevTarget === target) {
      //   console.log('same', proxiedPrevTarget, proxiedTarget);
      //   checking = false;
      //   return;
      // }
      // console.log('new', proxiedPrevTarget, proxiedTarget);
      // watchedVal.pusher.next(handle);
      checking = false;
    };
    checker().then(() => {
      watchedVal.checkInterval = setInterval( checker, 500);
    });







    return watchedVal.pusher.pipe(
      tap(
        handle => {
          const watcher = this.Values[path];
          // watcher.prevVal = undefined;
          // watcher.value = undefined;
          // console.log('handle', handle);
          // console.log('++++');
          const isProxy = handle?.pointer?.__isProxy__;
          this.ResetValHooks(watcher);
          if (!handle) {
            return;
          }

          if (isProxy) {
            handle.pointer = handle.pointerParent[watcher.proxy.handle.pointerParentKey];
          }

          // console.log('=====');
          // console.log('handle', handle);

          let obj: any;
          if (handle.pointer instanceof FormGroup) {
            obj = handle.pointer.controls[handle.finalProp];
          } else {
            obj = handle.pointer[handle.finalProp];
          }
          if (!obj) {
            obj = handle.pointer.question?.[handle.finalProp];
          }

          const callOnChanges = async (newVal: any) => {
            watcher.prevVal = watcher.value;
            watcher.value = newVal;
            const onChanges = Object.keys(watcher.onChanges).map(_id => Promise.all(watcher.onChanges[_id].map(c => c())));
            await Promise.all(onChanges);
          };


          if (obj instanceof AbstractControl) {
            if (watcher.sub && !watcher.sub.closed) {
              this.PushChanges();
              return;
            }
            watcher.value = obj.value;
            watcher.sub = obj.valueChanges
              .pipe(
                debounceTime(50),
                switchMap(async val => {
                  if (watcher.value === val) {
                    return;
                  }
                  await callOnChanges(val);
                })
              ).subscribe();
            this.PushChanges();
            return;
          }
          if (!handle.pointerParent) {
            return;
          }

          if (watcher.proxy?.proxy) {
            this.PushChanges();
            return;
          }

          const proxyChanges: any[] = [];
          let processingProxyChange = false;

          const runProxyChange = async () => {
            // check length as undefined or null could be valid value
            if (!proxyChanges.length || processingProxyChange) {
              return;
            }
            processingProxyChange = true;
            // take from front as the changes are fifo
            const change = proxyChanges.shift();
            await callOnChanges(change);
            processingProxyChange = false;
            await runProxyChange();
          };

          const {proxy, revoke} = Proxy.revocable(handle.pointer, {
            set(proxyTarget: any, p: PropertyKey, value: any, receiver: any): boolean {
              if (p !== handle.finalProp) {
                return true;
              }
              if (watcher.value === value) {
                return true;
              }

              proxyChanges.push(value);
              runProxyChange();
              return true;
            },
            get(target: any, p: PropertyKey, receiver: any): any {
              if (p !== '__isProxy__') {
                return target[p];
              }
              return true;
            }
          });


          watcher.proxy = {
            proxy,
            revoke,
            handle
          };

          const parentKey = Object.keys(handle.pointerParent).find(k => handle.pointerParent[k] === handle.pointer);
          handle.pointerParent[parentKey] = proxy;
          handle.pointerParentKey = parentKey;

        }));
  }


  private async testImp(truthy: (target: NodeDataType) => Promise<boolean>, exec: (action: INode) => Promise<NodeDataType>) {
    const when: IWhenNode = null;
  }

}

