import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonStyle, ActionType, DialogConfig} from '../../models/dialog-config.interface';
import {QuestioningService} from '../../../questioning/questioning.service';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {

  data: DialogConfig;

  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>,
              public sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) data?: DialogConfig) {
    if (data) {
      data.otherBtns = data.otherBtns ?? [];
      data.otherBtns.forEach(b => b.onClick = b.onClick ?? (() => {
      }));

      // data.cancelBtn = data.cancelBtn ?? {
      //   type: ActionType.Warn,
      //   text: 'Cancel',
      //   style: ButtonStyle.None
      // };

      if (data.cancelBtn) {
        data.cancelBtn.onClick = data.cancelBtn.onClick ?? (() => {
        });
      }

      // data.submitBtn = data.submitBtn ?? {
      //   type: ActionType.Success,
      //   text: 'Okay',
      //   style: ButtonStyle.Solid
      // };

      if (data.submitBtn) {
        data.submitBtn.onClick = data.submitBtn.onClick ?? (() => {
        });
      }
      this.data = data;
    }
  }

  ngOnInit(): void {
  }


  submit() {
    this.data.submitBtn?.onClick?.();
    this.dialogRef.close();

  }

}
