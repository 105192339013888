<div id="offline">
  <img src="../../../assets/img/logos/Bankers-Logo-2021_Teal.svg"
       alt="Banker Insurance"
       id="offline-logo">
  <section id="offline-message">
    <h1>New Agent Portal Is Offline</h1>
    <div>
      <p>We apologize for the inconvenience, but we're performing some maintenance. You can still contact us at <a
        href="tel:1-800-627-0000">(800) 627-0000</a> Monday-Friday 8am-6pm (EST).</p>
      <br/>
      <ng-container *ngIf="maintenance !== true">
        <p>{{maintenance}}</p>
        <br/>
      </ng-container>
      <p>&mdash; Bankers Insurance Group</p>
    </div>
  </section>
</div>
