import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {HelpService} from './help.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {HelpPage} from './help.interface';
import {NavService} from '../nav.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  animations: [
    trigger('slideInOutSub', [
      transition(':enter', [
        style({transform: 'translateX(100%)', position: 'absolute'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)', position: 'absolute'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)', position: 'absolute'}))
      ])
    ]),
    trigger('slideInOutMain', [
      transition(':enter', [
        style({transform: 'translateX(-100%)', position: 'absolute'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)', position: 'absolute'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)', position: 'absolute'}))
      ])
    ]),
    trigger('fadeInDetails', [
      transition(':enter', [
        style({transform: 'translateY(2rem)', opacity: 0, position: 'absolute'}),
        animate('250ms ease-in-out', style({transform: 'translateY(0)', position: 'absolute', opacity: 1}))
      ]),
      transition(':leave', [
        animate('250ms ease-in-out', style({transform: 'translateY(2rem)', position: 'absolute', opacity: 0}))
      ])
    ]),
    trigger('fadeInOutSubPage', [
      state('1' , style({opacity: 0})),
      state('0', style({opacity: 1})),
      transition('1 => 0', animate('200ms')),
      transition('0 => 1', animate('200ms'))
    ])
  ]
})
export class HelpComponent implements OnInit {

  focusedPage: HelpPage;
  // focusedCategory: HelpPageCategory;

  constructor(private helpSvc: HelpService, private navSvc: NavService) {

  }

  ngOnInit() {
    this.helpSvc.focusedPage.subscribe(
      page => {
        this.focusedPage = page;
      }
    );
    // this.helpSvc.focusedCategory.subscribe(
    //   cat => this.focusedCategory = cat
    // );
  }

  // categoryClicked(cat: HelpPageCategory|string) {
  //   this.helpSvc.focusPage(cat as HelpPageCategory);
  // }

  closeBar() {
    this.navSvc.toggleHelpBar();
  }

  closePage() {
    this.helpSvc.focusPage(null);
  }
  // pageClicked(pageName: HelpPage) {
  //   this.helpSvc.focusPage(this.focusedCategory, pageName.title);
  // }

  // get categoryPages(): HelpPage[] {
  //   return HelpPages[this.focusedCategory];
  // }

}
