<div class="page" style="padding-left: 0;">
  <div class="page-header-inline" fxLayout="row [wrap]" fxLayoutAlign="start center"
       [@fadeInOutSubPage]="focusedPage !== null && focusedPage !== undefined">
    <div class="titles" fxFlex>
      <h1 class="header-secondary">Help</h1>
<!--      <h2 class="header-secondary" *ngIf="focusedCategory">{{focusedCategory}}</h2>-->
    </div>
    <button mat-stroked-button class="btn" fxFlex="nogrow" (click)="closeBar()">Close</button>
  </div>
  <mat-divider></mat-divider>
  <div class="page-content" fxLayout="row [wrap]" fxFlexAlign="center center">
    <div *ngIf="focusedPage" id="help-page-content" [@fadeInDetails]>
      <div fxLayout="row [wrap]" fxLayoutAlign="start center">
        <h2 fxFlex>{{focusedPage.title}}</h2>
        <button fxFlex="nogrow" class="btn mr-4" mat-icon-button (click)="closeBar()" *ngIf="!focusedPage?.locked">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!--      <mat-divider></mat-divider>-->
      <mat-list fxLayout="row [wrap]" fxLayoutAlign="center center">

        <mat-list-item *ngFor="let section of focusedPage.sections; let i = index">
          <h2 class="help-step-title" [innerHTML]="(focusedPage.numbered ? '<strong>' + (i + 1) + ')</strong> ' : '') +section.title"></h2>
          <p class="help-step-subtitle paragraph-sub" *ngIf="section.subTitle"><i [innerHTML]="section.subTitle"></i></p>
          <hr/>
          <p class="help-step-details paragraph-sub" *ngIf="section.details"><span [innerHTML]="section.details"></span></p>
        </mat-list-item>
      </mat-list>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
