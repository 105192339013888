import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavService} from '../nav.service';
import {HelpPage} from './help.interface';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private _focusedPageChanged = new BehaviorSubject<HelpPage>(null);
  public get focusedPage() {
    return this._focusedPageChanged.asObservable();
  }
  // public focusedCategory = this._focusedCategoryChanged.asObservable();
  // private listPages

  constructor(private navSvc: NavService) {
  }


  // public addHelpPage(pageName: HelpPage) {
  //
  // }
  //
  // public removeHelpPage(title: string) {
  //
  // }

  public unFocus() {
    this.focusPage();
  }

  // public focusPage(pageTitle?: string) {
  public focusPage(page?: HelpPage) {
    if (page) {
      this._focusedPageChanged.next(page);
    } else {
      this._focusedPageChanged.next(null);
    }

  }

  public openHelp() {
    this.navSvc.helpBarCollapsed = true;
  }

}
