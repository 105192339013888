import { Pipe, PipeTransform } from '@angular/core';
import {Address, FormatAddress, FormatAddress2} from '../models/address.interface';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address, options?: boolean|{inline?: boolean, align?: 'left'|'right'}): string {

    if (typeof options === 'boolean') {
      options = {inline: options};
    }

    return FormatAddress2(address, options);
  }

}

