
// tslint:disable-next-line:only-arrow-functions
Array.prototype.remove = function<T>(...items: T[]): T[] {

  const moddedArr = [...this];
  items.forEach(item => {
    const index = moddedArr.indexOf(item);
    if (index > -1) {
      moddedArr.splice(index, 1);
    }
  });

  return moddedArr;
};

// tslint:disable-next-line:only-arrow-functions
Array.prototype.replace = function<T>(find: T, replace: T): T[] {

  const moddedArr = [...this];
  const index = moddedArr.indexOf(find);
  if (index > -1) {
    moddedArr[index] = replace;
  }
  return moddedArr;
};

// tslint:disable-next-line:only-arrow-functions
Array.prototype.replaceBy = function<T>(find: T, replace: T, matchFunc: (item1: T) => boolean): T[] {

  const moddedArr = [...this];

  let index = -1;
  moddedArr.forEach((item, i) => {
    if (matchFunc(item)) {
      index = i;
      return;
    }
  });

  if (index > -1) {
    moddedArr[index] = replace;
  }
  return moddedArr;
};


// tslint:disable-next-line:only-arrow-functions
Array.prototype.first = function<T>(): T|null {
  return this[0] ?? null; // yes, technically js will return undefined if not at index, but we prefer null for missing/unset values
};

// tslint:disable-next-line:only-arrow-functions
Array.prototype.last = function<T>(): T|null {
  return !this.length ? null : this[this.length - 1];
};
