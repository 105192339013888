<div class="page">
  <form fxLayout="row [wrap]" fxLayoutAlign="center center" style="min-height: calc(80vh - 64px)" [formGroup]="form" (ngSubmit)="login()">
    <mat-card style="width: 25rem; padding-bottom: 2rem;" fxLayout="column" fxLayoutAlign="start">
      <h1 class="header-primary" style="text-align: center">Log-In</h1>
      <div fxLayout="row" fxLayoutAlign="center start">
        <mat-form-field fxFlex="50">
          <input matInput type="number" required placeholder="Agent Number" formControlName="agentNumber"/>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input matInput type="text" placeholder="User Name" formControlName="userName"/>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center start">
        <mat-form-field fxFlex="100">
          <input matInput type="password" required placeholder="Password" formControlName="password"/>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center start">
        <button mat-button class="btn-success" type="submit"> Log-in</button>
      </div>
    </mat-card>
  </form>
</div>
