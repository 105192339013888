import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LoggerService} from './app/shared/services/logger.service';
import {AbstractControl} from '@angular/forms';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {QuotingService} from './app/quoting/quoting.service';
import {NodeDataType} from './app/dsl/types.dsl';
import {Interpreter} from './app/dsl/interpreter.dsl';


declare const window: any;

LoggerService.Override(false);

if (environment.production) {
  enableProdMode();

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
  })
  .catch(err => console.error(err));
