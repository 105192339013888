import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Environments} from '../../../environments/base-environment';

export class NetworkingUtils {

  static CatchNetworkErrors(error: HttpErrorResponse): IApiErrorResponse {
    let errorMessage = '';

    if (!error.error) {
      errorMessage = error.statusText;
      if (error.status === 401) {
        if (errorMessage.toLowerCase() === 'ok') {
          errorMessage = 'Unauthorized'; // Chromes statustext in error is returning Ok while firefox returns Unauthorized... strange
        }
        // (ignore 'This condition will always return' compile error as environment is switched out on build)
        // @ts-ignore
        if (environment.name !== Environments.Local) {
          localStorage.clear();
        }
      }
    } else if (typeof error.error === 'string') {
      errorMessage = error.error;
    } else if (error.error.message) {
      errorMessage = error.error.message;
    } else if (error.error instanceof Array) {
      errorMessage = error.error.join();
    } else {
      const customError: any = error;
      errorMessage = 'Network error';
    }
    return {
      message: errorMessage,
      description: error.error?.Description ?? error.error?.description,
      statusCode: error.error?.statusCode ?? error.status,
    };

  }
}

export interface IApiErrorResponse {
  helpPage?: string;
  statusCode: number;
  Description?: string;
  description?: string;
  message?: string;
}
//
// export interface INetworkError {
//   errorMessage: string;
//   statusCode?: number;
// }

