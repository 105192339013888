import {FindSectionQuestion, IQuestionPageSection, QuestionPageSection} from './questions.model';
import {HelpInfo} from '../help-info.model';


export interface IQuestionPage {
  title: string;
  name?: string;
  description: string;
  icon?: string;
  enabled: boolean;
  current: boolean;
  visible?: boolean;
  sections?: IQuestionPageSection[];
  helpInfo?: HelpInfo;
  meta?: {[key: string]: any};
  script?: string;
}

export class QuestionPage implements IQuestionPage {
  title: string;
  name: string;
  description: string;
  icon: string;
  enabled: boolean;
  current: boolean;
  visible: boolean;
  sections: QuestionPageSection[];
  helpInfo: HelpInfo;
  meta?: {[key: string]: any};
  script?: string;

  constructor(page: IQuestionPage) {
    this.title = page.title;
    this.name = page.name;
    this.description = page.description;
    this.icon = page.icon;
    this.enabled = page.enabled;
    this.current = page.current;
    this.visible = page.visible ?? true;
    this.sections = page.sections?.map(s => new QuestionPageSection(s)) ?? [];
    this.helpInfo = page.helpInfo;
    this.meta = page.meta ?? {};
    this.script = page.script;
  }


}

