declare var window: any;

export abstract class BaseEnvironment {

  public readonly endpoints: EndpointsConfig = {};
  public readonly authNet: {
    readonly '3000': AuthNetConfig,
    readonly '5000': AuthNetConfig,
    readonly '8000': AuthNetConfig,
  };


  protected loadConfig(json: any) {
    Object.assign(this, json);
  }
  public maintenance: boolean|string;
}


export enum Environments {
  Local =  'local',
  Dev = 'dev',
  Sqa = 'sqa',
  Uat = 'uat',
  Prod = 'prod'
}

export interface AuthNetConfig {
  profile: string;
  client: string;
}


export interface EndpointsConfig {
  policy?: string;
  billing?: string;
  account?: string;
  notifications?: string;
  claims?: string;
  authNet?: string;
}
