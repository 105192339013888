import {Component, Input, OnInit, Output} from '@angular/core';
import {UiService} from '../../ui.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-dimmer',
  templateUrl: './dimmer.component.html',
  styleUrls: ['./dimmer.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          // style({transform: 'translateX(100%)', opacity: 0}),
          // animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          style({opacity: 0}),
          animate('150ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          // style({transform: 'translateX(0)', opacity: 1}),
          // animate('350ms', style({transform: 'translateX(100%)', opacity: 0})),
          style({opacity: 1}),
          animate('350ms', style({opacity: 0}))
        ])
      ]
    )
  ],
})
export class DimmerComponent implements OnInit {

  @Input() fullScreen = false;
  @Input() dimmed = false;

  @Input() title = '';
  @Input() message = '';

  private readonly barColors = ['teal', 'orange', 'green'];
  barColor = this.barColors[0];

  constructor(public uiSvc: UiService) {
    // if (this.fullScreen) {
    //   this.uiSvc.title.subscribe(title => this.title = title);
    //   this.uiSvc.message.subscribe(message => this.message = message);
    // }
  }

  ngOnInit(): void {
    let colorInterval: number;
    if (!this.fullScreen) {
      return;
    }

    this.uiSvc.dimmed.subscribe(isDimmed => {
      if (isDimmed) {
        if (colorInterval) {
          return;
        }
        let intervalIndex = 1;
        colorInterval = setInterval(() => {
          this.barColor = this.barColors[intervalIndex++];
          if (intervalIndex >= this.barColors.length) {
            intervalIndex = 0;
          }
        }, 2000);
      } else {
        clearInterval(colorInterval);
        colorInterval = null;
        this.barColor = this.barColors[0];
      }
    });
  }



}
