import { Component, OnInit } from '@angular/core';
import {NavService} from '../nav.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';
import {INavPage, NavPage} from '../../shared/models/nav-page.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideInOutSub', [
      transition(':enter', [
        style({transform: 'translateX(100%)', position: 'absolute'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)', position: 'absolute'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)', position: 'absolute'}))
      ])
    ]),
    trigger('slideInOutMain', [
      transition(':enter', [
        style({transform: 'translateX(-100%)', position: 'absolute'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)', position: 'absolute'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)', position: 'absolute'}))
      ])
    ])
  ]
})
export class SideNavComponent implements OnInit {

  currPage: INavPage;
  private url: string;

  constructor(private router: Router, public navSvc: NavService) {
    this.url = this.router.url;
    this.currPage = this.navSvc.pageFromRoute(this.router.url);
    this.navSvc.pages.subscribe(_ => {
      this.currPage = this.navSvc.pageFromRoute(this.router.url);
    });
    this.navSvc.loadPages();
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.currPage = this.navSvc.pageFromRoute(event.url);
        // console.log('Curr pageName changed', event.urlAfterRedirects,  this.currPage);
      }
    });
  }

  navClicked(page: INavPage) {
    this.currPage = page;
  }

}
