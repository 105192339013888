import {FormGroup} from '@angular/forms';
import {Comparator} from './comparator';

export interface Dependency {
  targets: DependencyTarget[];
  onMatch: DependencyMatch;
  requireAll: boolean;
}

export interface DependencyTarget {
  value: any;
  name: string;
  sectionName?: string | FormGroup;
  comparator: Comparator;
}

export enum DependencyMatchType {
  Show = 'show',
  Disable = 'disable',
  DefaultTo = 'defaultTo',
  Require = 'require',
  MakeTitle = 'makeTitle',
  MakeSubTitle = 'makeSubTitle',
  Reload = 'reload',
}

export interface DependencyMatch {
  show?: boolean;
  disable?: boolean;
  require?: boolean;
  makeTitle?: string;
  makeSubTitle?: string;
  reload?: boolean;
  defaultTo?: any;
}
