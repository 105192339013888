import {AbstractControl, FormGroup} from '@angular/forms';


export function getControlName(control: AbstractControl) {
  const group = control.parent as FormGroup;

  if (!group) {
    return null;
  }

  let name: string;

  Object.keys(group.controls).forEach(key => {
    const childControl = group.get(key);

    if (childControl !== control) {
      return;
    }

    name = key;
  });

  return name;
}
