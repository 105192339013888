export class Product {
    static  BusinessOwnersPolicy =  'bbop';
    static  ModelBusinessOwnersPolicy =  'mbop';
    static  BuildersRisk =  'bldr';
    static  CommercialUmbrella = 'bucp';
    static  Homeowners = 'ho';
    static  HomeOwnersAssociation = 'hoa';
    static  Pristine = 'pho';
    static  MobileHomePark = 'mhpk';
    static  ExcessFlood =  'xfld';
    static  DwellingProperty =  'dp';


    static IsHomeOwners = (product: string) => {
      product = product.toLowerCase().trim();
      return [Product.HomeOwnersAssociation, Product.Pristine, Product.Homeowners, Product.DwellingProperty].includes(product);
    }

    static IsBusiness = (product: string) => {
      product = product.toLowerCase().trim();
      return [Product.BusinessOwnersPolicy, Product.ModelBusinessOwnersPolicy].includes(product);
    }
}
