import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../../user/user.service';
import * as LogRocket from 'logrocket';
import {environment} from '../../../environments/environment';
import {Environments} from '../../../environments/base-environment';
import {UiService} from '../ui.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {


  constructor(private router: Router) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!UserService.IsLoggedIn()) {
      this.router.navigate(['/auth']);
      // (ignore 'This condition will always return' compile error as environment is switched out on build)
      // @ts-ignore
      if (environment.name === Environments.Sqa || environment.name === Environments.Prod) {
        LogRocket.startNewSession();
      }
      return false;
    }

    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    if (environment.name === Environments.Sqa || environment.name === Environments.Prod) {
      const agent = UserService.UserFromJwt;
      LogRocket.identify(agent.agencyNum, {
        name: (agent?.name.lastName ?? '') + ', ' + (agent?.name.firstName  ?? ''),
      });
    }

    UiService.ShowChat(true);

    return true;
  }

}
