<div mat-dialog-title>
  <span [innerHTML]="data?.title || ''"></span>
</div>
<div mat-dialog-content fxLayout="column">
  <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(data?.msg || '')"></span>
  <ng-container *ngIf="data.template"
    [ngTemplateOutlet]="data.template"></ng-container>
  <div fxLayout="row [wrap]" fxLayoutAlign="center bottom" fxLayoutGap="1rem" style="padding-top: 1rem;">
    <app-button *ngIf="data.cancelBtn" [config]="data.cancelBtn" mat-dialog-close
                (clicked)="data.cancelBtn.onClick();"></app-button>
    <ng-container *ngFor="let btn of data.otherBtns">
      <app-button [config]="btn"></app-button>
    </ng-container>
    <app-button *ngIf="data.submitBtn" [config]="data.submitBtn"
                (clicked)="submit()"></app-button>
  </div>
</div>
