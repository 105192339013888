import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import '../shared/extensions/string.extension';
import {GetControlName, GetTargetControl} from '../questioning/questioning.service';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {
  Handle,
  IActionNode,
  IAssignNode,
  ICommentNode,
  IDataNode,
  IFormCheckNode,
  IInfixAction,
  InfixOp,
  INode,
  IPrefixAction,
  IVarNode,
  IWhenNode,
  NodeDataType,
  NodeType,
  PrefixOp
} from './types.dsl';
import {WatchedValue, Watcher} from './watcher.dsl';
import {HttpClient} from '@angular/common/http';
import {QuestionComponent} from '../questioning/question/question.component';
import {QuestionPageSectionComponent} from '../questioning/question-page-section/question-page-section.component';

declare const window: any;

export interface InterpretCfg {
  self?: any;
  // selfParentName?: string;
  lookups?: {
    parent?: FormGroup | { [key: string]: any };
    meta?: { [key: string]: any };
    global?: { [key: string]: any };
  };
  actions?: { [key: string]: (interpreter: Interpreter, ...args: any[]) => Promise<NodeDataType>|undefined|Promise<void> };
}


function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

const isNode = (target: NodeDataType) => !!enumKeys(NodeType).find(t => NodeType[t] === (target as INode)?.type);

let Ids = 0;

export class Interpreter {

  private nodes: INode[] = [];
  public cfg: InterpretCfg;
  private readonly id: string = `${Ids++}`;
  private script: string|null = null;
  private readonly subs: Subscription[] = [];
  private static Scripts: {[key: string]: string} = {};


  public static async Download(path: string, http: HttpClient): Promise<string> {
    let script: string;
    try {
      if (!Object.isNullOrUndefined(this.Scripts[path])) {
        return this.Scripts[path];
      }
      script =  await http.get(path, {responseType: 'text'})
        .toPromise();
    } catch (e) {
      script = '';
    }
    if (script.startsWith('<')) {
      script = '';
    }

    this.Scripts[path] = script;
    return script;
  }

  public static async DownloadAndPars(path: string, http: HttpClient, actions: string[]): Promise<INode[]> {
    const script = await this.Download(path, http);
    return Parse(script, {actions});
  }



  constructor(cfg?: InterpretCfg) {
    this.cfg = cfg ?? {};
  }

  public dispose() {
    this.subs.forEach(s => s.unsubscribe());
    Watcher.DisposeAllFor(this.id);
  }

  private ensureCfg() {
    this.cfg.lookups = this.cfg.lookups ?? {};
    this.cfg.lookups.parent = this.cfg.lookups.parent ?? {};
    this.cfg.lookups.meta = this.cfg.lookups.meta ?? {};
    this.cfg.lookups.global = this.cfg.lookups.global ?? {};
    this.cfg.actions = this.cfg.actions ?? {};
  }

  private scopeStack: {[name: string]: NodeDataType}[] = [];

  public readonly exec = async (nodesOrSrc: string | INode[], cfg?: InterpretCfg): Promise<any | null> => {

    this.cfg = cfg ?? this.cfg;
    this.ensureCfg();

    let execNodes: INode[];
    if (Array.isArray(nodesOrSrc)) {
      execNodes = nodesOrSrc;
    } else {
      this.script = nodesOrSrc;
      execNodes = Parse(this.script, {actions: Object.keys(this.cfg.actions)});
    }
    this.nodes.push(...execNodes);


    if (!this.scopeStack.length) {
      this.scopeStack.push({});
    }

    // console.group('execing', this.nodes, this.script);
    if (!execNodes.length) {
      // console.groupEnd();
      return null;
    }
    // console.log(JSON.stringify(this.nodes, null, 2));

    let lastResult: NodeDataType = null;

    for (const node of execNodes) {
      lastResult = await this.execNode(node);
    }

    const lastAsHandle = lastResult as Handle;
    // console.groupEnd();
    if (lastAsHandle?.pointer && lastAsHandle?.finalProp) {
      return lastAsHandle.pointer[lastAsHandle.finalProp];
    }

    return lastResult;

  }

  public readonly truthy = async (target: NodeDataType): Promise<boolean> => {
    const val = await this.inferVal(target);
    if (Object.isNullOrUndefined(val)) {
      return false;
    }
    if (Array.isArray(val) || typeof val === 'string') {
      return !!val.length;
    }

    if (isNode(target)) {
      return await this.truthy(await  this.inferVal(target));
    }

    const handle = val as Handle;
    if (handle.pointer && handle.finalProp) {
      return await this.truthy(handle.pointer[handle.finalProp]);
    }
    return !!target;
  }

  public readonly inferVal = async (infer: NodeDataType): Promise<NodeDataType> => {
    const inferAction = infer as INode;
    let val: NodeDataType;
    if (typeof infer === 'string') {
      val = infer;
    } else if (typeof infer === 'number') {
      val = infer;
    } else if (typeof infer === 'boolean') {
      val = infer;
    } else if (infer as any instanceof Date) {
      val = infer;
    } else if (Array.isArray(infer)) {
      val = [];
      for (const elem of (infer as NodeDataType[])) {
        val.push(await this.inferVal(elem));
      }
    } else if (isNode(infer)) {
      val = await this.execNode(inferAction);
    } else {
      val = infer;
    }
    return val;
  }

  public readonly execVar = async <T>(varAction: IVarNode): Promise<T> => {
    const handle = await this.execVarHandle(varAction);
    if (handle.pointer instanceof FormGroup) {
      if (handle.pointer.hasOwnProperty(handle.finalProp)) {
        return (handle.pointer as any)[handle.finalProp];
      }
      return handle.pointer.controls[handle.finalProp] as unknown as T;
    }

    if (handle.pointer[handle.finalProp] === undefined && handle.pointer.question) {
      handle.pointer = handle.pointer.question;
    }
    if (handle.pointer[handle.finalProp] === undefined) {
      throw {
        context: handle.pointer,
        error: new Error(`Cannot find ${handle.finalProp} in context`)
      };
    }


    return handle.pointer[handle.finalProp] as unknown as T;
  }

  public readonly execVarHandle = async (varAction: IVarNode, allowNewVar: boolean = false): Promise<Handle> => {

    let pointer: any;
    let pointerParent: any = this.cfg.lookups;
    const props = [...varAction.props];
    const lastProp = props.pop();

    const setPtr = (prop: string) => {
      pointerParent = pointer;
      if (pointer instanceof FormGroup) {
        let newPointer = pointer.controls[prop];
        if (!newPointer && pointer?.question) {
          newPointer = (pointer.question as any)[prop];
        }
        pointer = newPointer;
      } else {
        let newPointer = pointer[prop];
        // the form var has question prop associated with it so try getting from that
        if (!newPointer && pointer?.question) {
          newPointer = pointer.question[prop];
        }
        pointer = newPointer;
      }

      if (!pointer) {
        throw {
          context: pointerParent,
          error: new Error(`Property ${prop} does not exist in context`)
        };
      }
    };
    const ptrHas = (prop: string) => {
      if (pointer instanceof FormGroup) {
        return !Object.isNullOrUndefined(pointer.controls[prop]);
      } else {
        const hasProp = Object.keys(pointer).includes(prop);
        if (hasProp) {
          return hasProp;
        }
        if (pointer?.question) {
          Object.keys(pointer.question).includes(prop);
        }
        return false;
      }
    };


    if (varAction.isSelf) {
      if (this.cfg.self instanceof AbstractControl) {
        if (!lastProp) {
          return {pointer: this.cfg.self.parent, finalProp: GetControlName(this.cfg.self)};
        }
        pointer = this.cfg.self;
        varAction.props.forEach(setPtr);
        return {pointer, finalProp: lastProp, pointerParent};
      }

      if (!lastProp) {


        const parentKey = Object.keys(this.cfg.lookups.parent).find(k => {
          return (this.cfg.lookups.parent as any)[k] === this.cfg.self;
        });

        if (parentKey) {
          return {pointer: this.cfg.lookups.parent, finalProp: parentKey};
        }
        return {pointer: {self: this.cfg.self}, finalProp: 'self'};
      }
      pointer = this.cfg.self;
      props.forEach(setPtr);
      return {pointer, finalProp: lastProp, pointerParent};

    }


    pointer = this.cfg.lookups.parent;
    if (this.cfg.lookups.parent instanceof FormGroup) {
      const ctrl = GetTargetControl(varAction.target, pointer);
      pointer = ctrl?.parent ?? this.cfg.lookups.parent;
    }

    if (varAction.isLookup) {
      pointer = this.cfg.lookups;
    }

    if (!ptrHas(varAction.target)) {
      pointer = this.cfg.lookups.meta;
    }
    if (!ptrHas(varAction.target)) {
      pointer = this.cfg.lookups.global;
    }

    if (!ptrHas(varAction.target)) {
      for (let i = this.scopeStack.length - 1; i >= 0; i--) {
        if (ptrHas(varAction.target)) {
          break;
        }
        pointer = this.scopeStack[i];
      }
    }

    if (!ptrHas(varAction.target)) {

      if (allowNewVar) {
        const scope = this.scopeStack[this.scopeStack.length - 1];
        scope[varAction.target] = null;
        return {pointer: scope, finalProp: varAction.target, pointerParent};
      }

      throw {
        context: this.cfg.lookups,
        error: new Error(`Cannot locate ${varAction.target} in context`)
      };
    }

    if (!lastProp) {
      return {pointer, finalProp: varAction.target, pointerParent};
    }
    setPtr(varAction.target);
    props.forEach(setPtr);
    return {pointer, finalProp: lastProp, pointerParent};
  }

  public readonly execAssign = async (assign: IAssignNode) => {
    const target = await this.execVarHandle(assign.target, true);

    const val = await this.inferVal(assign.value);

    if (target.pointer instanceof AbstractControl) {

      let ctr: AbstractControl;
      if (target.pointer instanceof FormGroup) {
        ctr = target.pointer.controls[target.finalProp];
        if (ctr) {
          ctr.patchValue(val);
          return;
        }
      }

      const trySet = (obj: any) => {
        if (!obj) {
          return;
        }
        const setDescriptor = Object.getOwnPropertyDescriptor(obj, target.finalProp)?.set;
        if (setDescriptor) {
          setDescriptor(val);
          return;
        }
        obj[target.finalProp] = val;
        return;
      };

      if (target.finalProp === 'value') {
        target.pointer.patchValue(val);
      } else {
        trySet(target.pointer);
        trySet(target.pointer.question);
        trySet(target.pointer.component);
        const comp = target.pointer.component as (QuestionPageSectionComponent|QuestionComponent) & {reloadTitles?: () => void};
        if (comp) {
          if (comp.reloadTitles) {
            comp.reloadTitles();
          }
          comp.cdr.detectChanges();
        }
      }
    } else {
      target.pointer[target.finalProp] = val;
    }

    return;
  }

  public readonly execAction = async (action: IActionNode): Promise<NodeDataType> => {
    const withAction = action as IActionNode;
    const args = [await this.inferVal(withAction.target)];

    if (withAction.condition && !await this.truthy(await this.inferVal(withAction.condition))) {
      return null;
    }

    for (const elem of withAction.with) {
      args.push(await this.inferVal(elem));
    }
    const actionFuncName = Object.keys(this.cfg.actions)
      .find(k => k.toLowerCase().trim() === withAction.action.trim().trim());
    const callResp = this.cfg.actions[actionFuncName](this, ...args);
    if (!(callResp instanceof Object)) {
      if (typeof callResp === 'string' || typeof callResp === 'number' || typeof callResp === 'boolean') {
        return callResp;
      }
      return null;
    }
    if (callResp instanceof Promise) {
       const resp = await callResp;
       if (typeof resp === 'string' || typeof resp === 'number' || typeof resp === 'boolean') {
          return resp;
       }
       if (!(resp instanceof Object)) {
         return null;
       }
       return resp ?? null;
    }
    return callResp ?? null;
  }

  public readonly execFormCheck = async (formCheck: IFormCheckNode): Promise<boolean> => {

    if (formCheck.check === 'changes') {
      const varTarget = formCheck.target;
      const targetStr = `${varTarget.target}.${varTarget.props.join('.')}`;
      return !Object.propsEqual(Watcher.Get(targetStr)?.prevVal, Watcher.Get(targetStr)?.value);
    }
    if (formCheck.check === 'invalid') {
      const form = await this.execVar<AbstractControl>(formCheck.target);
      return !form.valid;
    }
    if (formCheck.check === 'valid') {
      const form = await this.execVar<AbstractControl>(formCheck.target);
      return form.valid;
    }
    if (formCheck.check === 'exists') {
      try {
        await this.execVar<AbstractControl>(formCheck.target);
        return true;
      } catch (e) {
        return false;
      }
    }
    throw new Error(`Form check of type ${formCheck.check} not implemented`);
  }

  public readonly execPrefix = async (prefix: IPrefixAction): Promise<NodeDataType> => {
    const val = await this.truthy(prefix.right);
    switch (prefix.op) {
      case PrefixOp.Negate:
        return !(val);
    }
    return null;
  }

  public readonly execInfix = async (infix: IInfixAction): Promise<NodeDataType> => {
    let str = '';

    if (infix.op === InfixOp.Pow) {
      let leftVal = await this.inferVal(infix.left);
      if (leftVal instanceof AbstractControl) {
        leftVal = leftVal.value;
      }
      if (typeof leftVal === 'string') {
        leftVal = `'${leftVal.replaceAll('\'', '\'')}'`;
      }
      let rightVal = await this.inferVal(infix.right);
      if (rightVal instanceof AbstractControl) {
        rightVal = rightVal.value;
      }
      if (typeof rightVal === 'string') {
        rightVal = `'${rightVal.replaceAll('\'', '\'')}'`;
      }
      str = `Math.pow(${leftVal}, ${rightVal})`;
    } else {
      const objCheck: {obj: any, op: InfixOp}[] = [];
      while (infix.type === NodeType.Infix) {
        let leftVal = await this.inferVal(infix.left);
        if (leftVal instanceof AbstractControl) {
          leftVal = leftVal.value;
        }
        if (typeof leftVal === 'string') {
          leftVal = `'${leftVal.replaceAll('\'', '\'')}'`;
        }
        if (Object.isPrimitive(leftVal)) {
          str += leftVal;
          str += ` ${infix.op} `;
        } else {
          objCheck.push({obj: leftVal, op: infix.op});
        }
        infix = infix.right as IInfixAction;
      }

      let rightVal = await this.inferVal(infix);

      if (rightVal instanceof AbstractControl) {
        rightVal = rightVal.value;
      }
      if (typeof rightVal === 'string') {
        rightVal = `'${rightVal.replaceAll('\'', '\'')}'`;
      }

      const objectOp = (obj1: any, obj2: any, op: InfixOp) => {
        switch (op) {
          case InfixOp.Equals:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() === obj2.getTime();
            }
            return Object.propsEqual(obj1, obj2);
          case InfixOp.NotEquals:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() !== obj2.getTime();
            }
            // do not just use not equals as we want to check props
            return !Object.propsEqual(obj1, obj2);
          case InfixOp.GreaterThan:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() >= obj2.getTime();
            }
            break;
          case InfixOp.LessThan:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() < obj2.getTime();
            }
            break;
          case InfixOp.GreaterThanOrEqual:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() >= obj2.getTime();
            }
            break;
          case InfixOp.LessThanOrEqual:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return obj1.getTime() <= obj2.getTime();
            }
            break;
          case InfixOp.Add:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return new Date(obj1.getTime() + obj2.getTime());
            }
            break;
          case InfixOp.Sub:
            if (obj1 instanceof Date && obj2 instanceof Date) {
              return new Date(obj1.getTime() - obj2.getTime());
            }
            break;
        }
        return null;
      };

      // this logic is no way right. Might work for single checks...
      // need to rewrite infix eval anyways
      for (let i = 0; i < objCheck.length; i++) {
        const obj1 = objCheck[i];
        const obj2 = objCheck[i + 1];
        if (objCheck.length >= i + 1) {
          str += objectOp(obj1, rightVal, obj1.op);
          continue;
        }
        str += objectOp(obj1, obj2, obj1.op) + ` ${obj2.op}`;
        i++;
      }

      if (!objCheck.length) {
        str += rightVal;
      }


    }
    console.log(`return ${str}`);
    return new Function(`return ${str}`)();
  }

  public readonly execWhen = async (when: IWhenNode): Promise<void> => {

    const varReadies = new Array(when.varsToWatch.length);
    const allVarsReady = () => {
      return !varReadies.length || !varReadies.filter(v => v !== true).length;
    };
    const checkCondition = async () => {
      if (!allVarsReady()) {
        return;
      }
      const condition = await this.execNode(when.condition);
      if (await this.truthy(condition)) {
        this.scopeStack.push({});
        for (const then of when.then) {
          await this.execNode(then);
        }
        this.scopeStack.pop();
      }
    };

    if (!when.varsToWatch.length) {
      await checkCondition();
      return null;
    }


    for (let i = 0; i <  when.varsToWatch.length; i++) {
      const varNode = when.varsToWatch[i];
      this.subs.push(Watcher.Hook(this.id, varNode, this.execVarHandle, checkCondition)
        .pipe(
          tap(handle => {
            // console.log('var Tap', handle)
          })
        )
        .subscribe(handle => {
          varReadies[i] = !!handle;
          // console.log('var readyies', handle);
          checkCondition();
        }));
    }

    return null;
  }

  public readonly execNode = async (action: INode): Promise<NodeDataType> => {
    switch (action.type) {
      case NodeType.Var:
        return await this.execVar(action as IVarNode);
      case NodeType.Assign:
        await this.execAssign(action as IAssignNode);
        return null;
      case NodeType.Action:
        return await this.execAction(action as IActionNode);
      case NodeType.Infix:
        return await this.execInfix(action as IInfixAction);
      case NodeType.Prefix:
        return await this.execPrefix(action as IPrefixAction);
      case NodeType.When:
        await this.execWhen(action as IWhenNode);
        return null;
      case NodeType.Data:
        return await this.inferVal((action as IDataNode).data);
      case NodeType.FormCheck:
        return await this.execFormCheck(action as IFormCheckNode);
      case NodeType.Comment:
        return null;
    }
  }

}

const CachedParse: {[src: string]: INode[]} = {};

export function Parse(src: string, cfg: { actions: string[] }): INode[] {

  if (CachedParse[src]) {
    // return CachedParse[src];
  }
  const lines = src.split('\n')
    .map(l => l.trim())
    .filter(l => l.length && !l.startsWith('#'))
    .map(l => l.endsWith(';') ? l : l + ';').join('').split(';')
    .map(l => l.trim()).filter(l => l.length);
  // console.log(src, lines);

  let actions: (INode|null)[] = [];

  const nodeParse = (line: string): INode => {

    if (line.startsWith('#')) {
      return {
        type: NodeType.Comment,
        line
      } as ICommentNode;
    }

    const action = assignParse(line) || actionParse(line) || prefixParse(line) ||  infixParse(line) ||
      formCheckParse(line) || strParse(line)?.node || boolNoneParse(line) || identPropParse(line);
    if (action) {
      return action;
    }

    // @ts-ignore
    if (!isNaN(line)) {
      return {
        type: 'data',
        data: Number(line)
      } as IDataNode;
    }
    if (!isNaN(Date.parse(line))) {
      return {
        type: 'data',
        data: new Date(line)
      } as IDataNode;
    }

    return {
      type: 'data',
      data: line
    } as IDataNode;
  };

  const identPropParse = (line: string): IVarNode | null => {
    // const regex = /^(?<name>\$\w+)(?<props>\..*?(\s|$))?/;
    const regex = /^(?<name>[$@]\w+)(?<props>\..*?([^\w.]|$))?/;
    const matches = (line.match(regex) || {}).groups || {};
    if (!matches['name']) {
      return null;
    }
    const name = matches['name'].replaceAll('$', '').replaceAll('@', '');
    return {
      type: NodeType.Var,
      isSelf: name.toLowerCase() === 'self',
      isLookup: matches['name'].startsWith('@'),
      target: name,
      props: (matches['props'] || '')
        .split('.')
        .map(p => p.trim().replaceAll('$', ''))
        .filter(p => p.length)
    };
  };

  const assignParse = (line: string): IAssignNode | null => {
    const regex = /(?<target>\$.*?)\s+=\s+(?<value>.*)/;
    const matches = (line.match(regex) || {}).groups || {};
    if (matches['target']) {
      return {
        type: NodeType.Assign,
        target: identPropParse(matches['target']),
        value: nodeParse(matches['value']),
      };
    }
    return null;
  };

  const actionParse = (line: string): IActionNode | null => {
    const regex = new RegExp(`(?<action>(?:${cfg.actions.join('|')}))\\s+(?<target>.*?)(?:\\s+with\\s+(?<with>.*?))?(?:$|\\s+if\\s+(?<if>.*))`, 'i');
    // d = /(?<action>(?:hide))\s+(?<target>.*?)(?:\s+with\s+(?<with>.*?))?(?:$|\s+if\s+(?<if>.*))/;
    const match = (regex.exec(line) || {}).groups || {};

    if (!match['action']) {
      return null;
    }

    return {
      type: NodeType.Action,
      action: match['action'].toLowerCase(),
      target: nodeParse(match['target']),
      condition: match['if'] ? nodeParse(match['if']) : null,
      with: (match['with'] || '').split(',')
        .map(a => a.trim())
        .filter(a => a.length)
        .map(a => nodeParse(a)),
    };
  };

  const formCheckParse = (line: string): IFormCheckNode | null => {
    const match = (line.match(/(?<target>.*?)\s+(?<check>exists|changes|is\s+(?:not\s+(?:valid|invalid)|changed|valid|invalid))(?:\s|$)/i) || {}).groups || {};
    const str = match['target'];
    if (Object.isNullOrUndefined(str)) {
      return null;
    }

    let check: string;
    switch (match['check'].toLowerCase()) {
      case 'changes':
      case 'is changed':
        check = 'changes';
        break;
      case 'is valid':
      case 'is not invalid':
        check = 'valid';
        break;
      case 'is invalid':
      case 'is not valid':
        check = 'invalid';
        break;
      case 'exists':
        check = 'exists';
        break;
    }

    return {
      type: NodeType.FormCheck,
      target: identPropParse(match['target']),
      check: check as any,
    };
  };

  const boolNoneParse = (line: string): IDataNode|null => {
    if (line.toLowerCase() === 'none') {
      return {
        type: NodeType.Data,
        data: null,
      };
    }
    if (line.toLowerCase() === 'true') {
      return {
        type: NodeType.Data,
        data: true,
      };
    } else if (line.toLowerCase() === 'false') {
      return {
        type: NodeType.Data,
        data: false,
      };
    }
    return null;
  };

  const strParse = (line: string): { node: IDataNode, quoteMark: string } | null => {
    const parse = (quoteMark: string) => {

      if (line === `${quoteMark}${quoteMark}`) {
        return {
          node: {
            type: NodeType.Data,
            data: ''
          }, quoteMark
        };
      }


      const regexStr = `.*?${quoteMark}(?<str>(?:\\\\|\\${quoteMark}|[^${quoteMark}])*.)${quoteMark}`;
      const regex = new RegExp(regexStr);
      const match = (regex.exec(line) || {}).groups || {};
      const str = match['str'];
      if (Object.isNullOrUndefined(str)) {
        return null;
      }

      return {
        node: {
          type: NodeType.Data,
          data: str
        }, quoteMark
      };
    };

    const singleMatch = parse('\'');
    const doubleMatch = parse('"');

    if (!singleMatch && !doubleMatch) {
      return null;
    }

    if (!doubleMatch) {
      return singleMatch;
    }
    if (!singleMatch) {
      return doubleMatch;
    }

    const singleIndex = line.indexOf(`'${singleMatch.node.data}'`);
    const doubleIndex = line.indexOf(`'${singleMatch.node.data}'`);
    return singleIndex > doubleIndex ? doubleMatch : singleMatch;
  };

  const prefixParse = (line: string): IPrefixAction => {
    const regex = /^(?<prefix>not|!(?!\s+))(?:\s+)?(?<right>.*)/i;
    const match = (line.match(regex) || {}).groups || {};

    if (!match['prefix']) {
      return null;
    }
    if (!match['right']) {
      throw new Error(`Invalid ${match['prefix']} prefix`);
    }

    let prefix: PrefixOp;
    switch (match['prefix'].toLowerCase()) {
      case 'not':
      case '!':
        prefix = PrefixOp.Negate;
        break;
      default:
        throw new Error(`Prefix op not mapped: ${match['prefix']}`);
    }

    return {
      type: NodeType.Prefix,
      op: prefix,
      right: nodeParse(match['right']),
    };
  };

  const infixParse = (line: string): IInfixAction => {

    const stringMatches: { node: IDataNode, tempName: string, quoteMark: string }[] = [];

    let foundStr = false;

    const namedOps = [
      'is',
      'is not',
      'greater than',
      'less than',
      'greater than or equal to',
      'less than or equal to',
      'or',
      'and',
    ].sort().sort((a, b) => b.length - a.length);

    do {
      const str = strParse(line);

      foundStr = !!str;

      if (foundStr) {
        const dataStr = str.node.data as string;
        const charCodeStr = dataStr.split('').map(c => c.charCodeAt(0).toString()).join('');

        // let tempName = dataStr.replace(/[^a-zA-Z]+/g, '');
        let tempName = charCodeStr;
        // flip named ops around on themselves
        namedOps.forEach(o => tempName = tempName.replaceAll(o, o.split('').reverse().join('')));
        tempName = `__${tempName}__`;

        stringMatches.push({node: str.node, tempName, quoteMark: str.quoteMark});

        // console.log(line, dataStr, tempName, line.replaceAll(dataStr, tempName));
        line = line.replaceAll(`${str.quoteMark}${dataStr}${str.quoteMark}`, tempName);
      }

    } while (foundStr);

    const wordRegex = /(?<left>.*?)\s+(?<op>and|or|xor|is(?!\s+(?:not\s+)?(?:valid|invalid|changed))(?:\s+not)?(?:\s+(?:less|greater)\s+than(?:\s+or\s+equal\s+to)?)?).*?(?:\s+(?<right>.*))?/i;
    const wordMatch = (line.match(wordRegex) || {}).groups || {};

    const symbolRegex = /(?<left>.*?)(?:\s+)?(?<op>\+|-|\/|\*|>(?:=)?|<(?:<|=)?|==|!=|&&|\|\|)(?:\s+)?(?<right>.*)/;
    const symbolMatch = (line.match(symbolRegex) || {}).groups || {};

    const popMatch = (group: any) => ({
      left: group['left'] as string,
      op: (group['op'] as string)?.toLowerCase(),
      right: group['right'] as string,
      index: group['op'] ? line.indexOf(group['op']) : -1,
    });

    const wordMatchGroup = popMatch(wordMatch);
    const symbolMatchGroup = popMatch(symbolMatch);

    if (wordMatchGroup.index === -1 && symbolMatchGroup.index === -1) {
      return null;
    }

    let match: any;
    if (wordMatchGroup.index === -1) {
      match = symbolMatchGroup;
    } else if (symbolMatchGroup.index === -1) {
      match = wordMatchGroup;
    } else {
      match = wordMatchGroup.index > symbolMatchGroup.index ? symbolMatchGroup : wordMatchGroup;
    }

    // stringMatches.filter(s => match.left.includes(s.tempName))
    //   .forEach(s => match.left = match.left.replaceAll(s.tempName, s.action.data as string));

    stringMatches
      .forEach(s => {
        const str = s.node.data as string;
        const fixedStr = `${s.quoteMark}${str}${s.quoteMark}`;
        match.left = match.left.replaceAll(s.tempName, fixedStr);
        match.right = match.right.replaceAll(s.tempName, fixedStr);
      });

    let op: InfixOp;
    switch (match.op) {
      case 'is':
      case '==':
        op = InfixOp.Equals;
        break;
      case 'is not':
      case '<>':
      case '!=':
        op = InfixOp.NotEquals;
        break;
      case '>':
      case 'greater than':
        op = InfixOp.GreaterThan;
        break;
      case '<':
      case 'less than':
        op = InfixOp.LessThan;
        break;
      case '>=':
      case 'greater than or equal to':
        op = InfixOp.GreaterThanOrEqual;
        break;
      case '<=':
      case 'less than or equal to':
        op = InfixOp.LessThanOrEqual;
        break;
      case '&&':
      case 'and':
        op = InfixOp.And;
        break;
      case '||':
      case 'or':
        op = InfixOp.Or;
        break;
      case '+':
        op = InfixOp.Add;
        break;
      case '-':
        op = InfixOp.Sub;
        break;
      case '*':
        op = InfixOp.Mul;
        break;
      case '/':
        op = InfixOp.Div;
        break;
      case '^':
        op = InfixOp.Pow;
        break;
      default:
        throw new Error(`Infix op not mapped: ${op}`);
    }
    return {
      type: NodeType.Infix,
      left: nodeParse(match.left),
      right: nodeParse(match.right),
      op,
    };
  };

  // console.log(lines);
  let whenDepth = 0;
  for (let i = 0; i < lines.length; i++) {

    const parseLine = (line: string): INode | null => {

      const lineLower = line.toLowerCase();

      const isWhen = lineLower.startsWith('when');
      // const isSaveLoad = lineLower.startsWith('save') || lineLower.startsWith('load');
      if (cfg.actions.find(a => lineLower.startsWith(a))) {
        return actionParse(line);
      }

      if (isWhen) {
        whenDepth++;
        const whenThenRegex = /when\s+(?<when>.*?)\s+(then;|then\s+(?<then>.*?)(\s+end;?|;|$)|then$)/i;
        const whenThenMatches = line.match(whenThenRegex).groups || {};
        let thens: INode[] = [];
        if (whenThenMatches['then']) {
          thens = [parseLine(whenThenMatches['then'])];
        } else {
          while (!lines[i + 1].toLowerCase().startsWith('end')) {
            const resp = parseLine(lines[++i].trim());
            if (resp !== null) {
              thens.push(resp);
            }
          }
          i++;
        }

        const varsToWatchRegex = /\$\w+(\..*?([^\w.]|$))?/gm;

        let varsToWatch: IVarNode[] = [];
        if (whenDepth === 1) {
          const varsWatchStr = (whenThenMatches['when'].match(varsToWatchRegex) ?? []);
          varsToWatch = varsWatchStr.map(identPropParse).filter(v => v);
        }

        whenDepth--;
        return {
          type: NodeType.When,
          varsToWatch,
          condition: nodeParse(whenThenMatches['when']),
          then: thens,
        } as IWhenNode;
      }

      if (!line.trim().length) {
        return null;
      }

      return {
        type: NodeType.Data,
        data: nodeParse(line)
      } as IDataNode;
    };

    actions.push(parseLine(lines[i].trim()));
  }

  actions = actions.filter(a => a);
  // console.log(JSON.stringify(actions, null, 2));

  CachedParse[src] = actions;
  return actions;

}
