import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  IInputQuestion,
  QuestionInputType,
  QuestionType,
  SelectQuestion
} from '../shared/models/quoting/questions.model';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import * as assert from 'assert';
import {UiService} from '../shared/ui.service';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {debounceTime} from 'rxjs/operators';
import {Dependency, DependencyMatchType, DependencyTarget} from '../shared/models/quoting/hooks/dependency';
import {Comparator} from '../shared/models/quoting/hooks/comparator';


declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class QuestioningService {

  constructor() {
    window.quesSvc = this;
  }

  readonly GetTargetControl = GetTargetControl;
  readonly ProcessLookupQuery = ProcessLookupQuery;
  readonly ProcessLookupQueryPipe = ProcessLookupQueryPipe;
  readonly GetInvalidFormControls = GetInvalidFormControls;

}

export function GetControlName(control: AbstractControl): string | null {
  const group = control.parent as FormGroup;

  if (!group) {
    return null;
  }

  let name: string;

  Object.keys(group.controls).forEach(key => {
    const childControl = group.get(key);

    if (childControl !== control) {
      return;
    }

    name = key;
  });
  if (!name?.trim()?.length) {
    name = GetControlName(group);
  }

  return name;
}

export function GetParentControlName(control: AbstractControl): string | null {
  return GetControlName(control.parent);
}

export enum QuestioningSectionHook {
  Init = 'init',
  DeInit = 'deinit',
  Ready = 'ready',
  Loaded = 'loaded',
}

// export type OnDepTargetCall = (type: DependencyMatchType, match: boolean, val?: any | null | undefined, previousVal?: any | null | undefined) => Promise<void>;
export type OnDepTargetCall = (dep: Dependency, type: DependencyMatchType, match: boolean) => Promise<void>;

export interface WatchDependenciesCfg {
  onTarget: OnDepTargetCall;
  dependency: Dependency;
  context: FormGroup;
  lookups?: {
    meta?: { [key: string]: any };
    global?: { [key: string]: any };
  };
  only?: DependencyMatchType[];
}

let WatchId = 0;

export async function WatchDependencies(cfg: WatchDependenciesCfg): Promise<Subscription[]> {

  const id = WatchId++;
  let processing = false;

  cfg.lookups = cfg.lookups ?? {};
  cfg.lookups.meta = cfg.lookups.meta ?? {};
  cfg.lookups.global = cfg.lookups.global ?? {};
  cfg.only = cfg.only ?? [];


  const dependentTargets: AbstractControl[] = new Array(cfg.dependency.targets.length);
  const subs: Subscription[] = [];

  const should = cfg.dependency.onMatch;

  const depByTarget = new Map<AbstractControl, DependencyTarget[]>();
  for (const dep of cfg.dependency.targets) {

    let targetControl = GetTargetControl(dep.name, cfg.context, dep.sectionName);
    if (!targetControl) {
      // this is hacky. Need to update to build proper dependency tree.
      //  Should only occur if the depenedent form has not yet been loaded by dom
      for (let j = 0; j < 10; j++) {
        targetControl = GetTargetControl(dep.name, cfg.context, dep.sectionName);
        if (targetControl) {
          break;
        }
        console.log('looking for', dep.name, dep.sectionName, dep, cfg.dependency);
        await UiService.sleep(10);
      }
    }

    if (!targetControl) {
      return subs;
    }
    assert(targetControl, `Missing target control ${dep.name}`);
    const existingDepTarget = depByTarget.get(targetControl);
    if (existingDepTarget) {
      existingDepTarget.push(dep);
    } else {
      depByTarget.set(targetControl, [dep]);
    }
  }

  const dependentMatches: boolean[] = new Array(depByTarget.size);

  const targetKeys = Array.from(depByTarget.keys());
  for (let i = 0; i < targetKeys.length; i++) {
    const target = targetKeys[i];
    const deps = depByTarget.get(target);
    let previousVal: any;

    dependentMatches[i] = false;
    const targetDepMatches: boolean[] = new Array(deps.length);

    const checkTargetMatches = async (val: any) => {
      const getVal = (newVal: any) => {
        if (typeof newVal !== 'string' || !(newVal as string).includes('$[')) {
          return newVal;
        }
        return ProcessLookupQuery(newVal, cfg.context, cfg.lookups.meta, cfg.lookups.global);
      };

      for (let j = 0; j < deps.length; j++) {
        const dep = deps[j];

        let depMatch = true;
        if (dep.value === '*' && val) {
        } else if (val instanceof Array) {
          if (!val.includes(dep.value)) {
            depMatch = false;
          }
          // } else if (val !== dep.value) {
        } else {
          switch (dep.comparator) {
            case Comparator.Equals:

              if (dep.value instanceof Array) {
                // don't use include as we don't want strict equals
                // tslint:disable-next-line:triple-equals
                depMatch = !!dep.value.find(v => v == val);
              } else {
                // tslint:disable-next-line:triple-equals
                depMatch = val == dep.value;
              }

              break;
            case Comparator.GreaterThan:
              depMatch = +val > dep.value || val === null || val === undefined;
              break;
            case Comparator.LessThan:
              depMatch = +val < dep.value || val === null || val === undefined;
              break;
            case Comparator.AnythingBut:
              if (dep.value instanceof Array) {
                // don't use include as we don't want strict equals
                // tslint:disable-next-line:triple-equals
                depMatch = !dep.value.find(v => v == val);
              } else {
                // tslint:disable-next-line:triple-equals
                depMatch = val != dep.value;
              }
              break;
            case Comparator.Valid:
              depMatch = target.valid;
              break;
            case Comparator.Invalid:
              depMatch = !target.valid;
              break;
            case Comparator.Changed:
              // tslint:disable-next-line:triple-equals
              depMatch = val != previousVal;
              break;
            case Comparator.True:
              depMatch = getVal(dep.value) as boolean;
              break;
            case Comparator.False:
              depMatch = getVal(dep.value) as boolean;
              break;
          }
        }
        targetDepMatches[j] = depMatch;
      }

      const matchChecker = (arr: boolean[]) => (!cfg.dependency.requireAll && arr.some(m => !!m)) || arr.every(m => !!m);
      dependentMatches[i] = matchChecker(targetDepMatches);

      const match = matchChecker(dependentMatches);


      const tryCall = async (type: DependencyMatchType) => {
        if (cfg.only.length && !cfg.only.includes(type)) {
          return;
        }
        // await cfg.onTarget(dep, type, match, targetControl, matchVal, previousVal);
        await cfg.onTarget(cfg.dependency, type, match);
      };

      if (!Object.isNullOrUndefined(should.show)) {
        await tryCall(DependencyMatchType.Show);
      }
      if (!Object.isNullOrUndefined(should.disable)) {
        await tryCall(DependencyMatchType.Disable);
      }
      if (!Object.isNullOrUndefined(should.defaultTo)) {
        await tryCall(DependencyMatchType.DefaultTo);
      }
      if (!Object.isNullOrUndefined(should.makeTitle)) {
        await tryCall(DependencyMatchType.MakeTitle);
      }
      if (!Object.isNullOrUndefined(should.makeSubTitle)) {
        await tryCall(DependencyMatchType.MakeSubTitle);
      }
      if (!Object.isNullOrUndefined(should.reload) && match) {
        await tryCall(DependencyMatchType.Reload);
      }
      if (!Object.isNullOrUndefined(should.require)) {
        await tryCall(DependencyMatchType.Require);
      }
      previousVal = val;
      processing = false;
    };

    subs.push(target.valueChanges
      .pipe(
        debounceTime((target.question?.type === QuestionType.Input && (target.question as IInputQuestion<any>).inputType === QuestionInputType.Number) ? 300 : 0),
      )
      .subscribe(async val => {
      if (val === previousVal || processing) {
        return;
      }
      processing = true;
      await checkTargetMatches(val);
    }));
    await checkTargetMatches(target.value);
  }
  return subs;
}

window.GetTargetControl = GetTargetControl;

export function GetTargetControl<T extends AbstractControl>(name: string, parentForm?: FormGroup, section?: string | FormGroup): T | null {
  if (section) {
    // let's try traversing down each form
    const findForm = (form: FormGroup) => {
      let targetForm: FormGroup;
      if (!form) {
        return null;
      }

      targetForm = form.get(section as string) as FormGroup;
      if (targetForm) {
        return targetForm;
      }

      const forms = Object.keys(form.controls).map(ctrlName => form.get(ctrlName)).filter(ctrl => ctrl instanceof FormGroup);
      for (const subForm of forms) {
        targetForm = findForm(subForm as FormGroup);
        if (targetForm) {
          return targetForm;
        }
      }
      return null;
    };


    if (section instanceof FormGroup) {
      return section.controls?.[name] as T;
    }
    // go up level then decend until no more parent, this should get closes named one incase of name collision
    let onForm = parentForm;
    do {
      const targetForm = findForm(onForm);
      const targetCtrl = targetForm?.controls?.[name] as T;
      if (targetCtrl) {
        return targetCtrl;
      }
      onForm = onForm.parent as FormGroup;
    } while (onForm);

    return null;
  } else {
    let onForm = parentForm;
    let targetCtrl: T;
    do {
      targetCtrl = parentForm.get(name) as T;
      if (targetCtrl !== null) {
        return targetCtrl;
      }

      const checkSubForms = (form: FormGroup) => {
        let subTargetCtrl = form.get(name) as T;
        if (subTargetCtrl) {
          return subTargetCtrl;
        }
        const forms = Object.keys(form.controls).map(ctrlName => form.get(ctrlName)).filter(ctrl => ctrl instanceof FormGroup);
        for (const subForm of forms) {
          subTargetCtrl = checkSubForms(subForm as FormGroup);
          if (subTargetCtrl) {
            return subTargetCtrl;
          }
        }
        return null;
      };
      targetCtrl = checkSubForms(onForm);
      onForm = onForm.parent as FormGroup;
    } while (onForm && !targetCtrl);
    return targetCtrl;
  }
}

export function ProcessLookupQuery(val: any, context: FormGroup, meta: { [key: string]: any } = {}, globalLookups: { [key: string]: any } = {}): any {


  if (typeof val !== 'string' || !(val as string).includes('$[')) {
    return val;
  }
  const str = val as string;

  let newStr = str;
  const matches = str.match(/\$\[(.*?)\]/g) ?? [];

  let lastQueryVal: any = str;

  matches.forEach((m, i) => {
    if (m.startsWith('$[ques:')) {
      const regexMatch = /\$\[ques:'(?<selector>.*?)'(:'(?<format>.*?)')?\]/gm.exec(m);
      const selector = regexMatch[1];
      const format = regexMatch[3];
      m = `$[@${selector}`;
      if (format) {
        m += `:format:${format}`;
      }
      m += `]`;
    }

    lastQueryVal = ProcessLookupQueryPipe(m, context, meta, globalLookups);
    if (lastQueryVal === null && m.includes('format:currency')) {
      lastQueryVal = '$0';
    }
    newStr = newStr.replace(matches[i], lastQueryVal);
  });

  if (str === matches[0]) {
    return lastQueryVal;
  }
  return newStr;
}

export function ProcessLookupQueryPipe(query: string, context: FormGroup, meta: { [key: string]: any } = {}, globalLookups: { [key: string]: any } = {}): any {
  try {
    meta = meta ?? {};

    if (query.startsWith('$[')) {
      query = query.substr(2);
    }
    if (query.endsWith(']')) {
      query = query.substr(0, query.length - 1);
    }

    const pipes = query.split(':').filter(q => q);


    let pipeIndex = 0;

    const parsePipe = (pipe: string, argVal?: any): any => {
      let retVal: any;
      if (!pipe) {
        return null;
      }


      if (pipe.startsWith('@')) {
        pipe = pipe.substr(1);
        const selectorParts = pipe.split('.').filter(p => p);
        const questionName = selectorParts.pop();
        const ctrl = GetTargetControl(questionName, context, selectorParts.length ? selectorParts[0] : null);


        const nextPipe = pipes[pipeIndex + 1];
        // legacy support. insert val pipe here
        if (!['val', 'prop'].includes(nextPipe)) {
          retVal = parsePipe('val', ctrl);
        } else {
          retVal = parsePipe(pipes[++pipeIndex], ctrl);
        }

      } else if (pipe.startsWith('$')) {
        const propParts = pipe.split('.').filter(p => p);
        // const targetName = propParts.pop();
        // retVal = QuotingService.quote;
        retVal = globalLookups;
        propParts.forEach(p => {
          retVal = retVal?.[p];
        });
      } else if (pipe === 'val') {
        let targetVal = argVal.value;
        if (argVal.question?.type === QuestionType.Select) {
          const option = (argVal.question as SelectQuestion<any>).options.find(o => o.value === targetVal);
          if (meta.querySelectVal) {
            targetVal = option?.value ?? targetVal;
          } else {
            targetVal = option?.text ?? targetVal;
          }
        }
        retVal = targetVal;
      } else if (pipe === 'prop') {
        const propName = pipes[pipeIndex + 1];
        retVal = argVal[propName];
        if (argVal instanceof AbstractControl) {
          if (argVal.value !== null && argVal.value !== undefined) {
            retVal = argVal.value[propName];
          }
          if (typeof retVal === 'function') {
            retVal = retVal.call(argVal.value);
          }
        }
        if (!retVal) {
          retVal = argVal.question?.[pipes[pipeIndex + 1]];
        }
        pipeIndex++;
      } else if (pipe === 'format') {
        retVal = parsePipe(pipes[++pipeIndex], argVal);
      } else if (pipe === 'numeric') {
        if (argVal === null || argVal === undefined) {
          return '';
        }
        retVal = new DecimalPipe('en-us').transform(argVal);
      } else if (pipe === 'currency') {
        if (argVal === null || argVal === undefined) {
          return '';
        }
        if (typeof argVal === 'string' && argVal.startsWith('$')) {
          retVal = argVal;
        } else {
          retVal = new CurrencyPipe('en-us').transform(argVal, 'USD', 'symbol', '1.0-0');
        }
      } else if (pipe === 'in') {
        let vals = (parsePipe(pipes[++pipeIndex]) as string).split(',').map(v => v.trim());
        vals = vals.map(v => ProcessLookupQueryPipe(v, context, globalLookups));
        // tslint:disable-next-line:triple-equals
        retVal = !!vals.find(v => v == argVal);
      } else if (pipe === '*') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = argVal * num as number;
      } else if (pipe === '/') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) / Number(num);
      } else if (pipe === '+') {
        let num = parsePipe(pipes[++pipeIndex]);
        // if one of the values is a number, then make sure the other is
        if (num instanceof Number || typeof num === 'number') {
          argVal = Number(argVal);
        } else if (argVal instanceof Number || typeof argVal === 'number') {
          num = Number(num);
        }
        retVal = argVal + num;
      } else if (pipe === '-') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) - Number(num);
      } else if (pipe === '>') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) > Number(num);
      } else if (pipe === '<') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) < Number(num);
      } else if (pipe === '==') {
        const num = parsePipe(pipes[++pipeIndex]);
        // tslint:disable-next-line:triple-equals
        retVal = argVal == num;
      } else if (pipe === '!=') {
        const num = parsePipe(pipes[++pipeIndex]);
        // tslint:disable-next-line:triple-equals
        retVal = argVal != num;
      } else if (pipe === '<=') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) <= Number(num);
      } else if (pipe === '>=') {
        const num = parsePipe(pipes[++pipeIndex]);
        retVal = Number(argVal) >= Number(num);
      } else {
        const asNum = parseFloat(pipe);
        retVal = isNaN(asNum) ? pipe : asNum;
      }
      return retVal;
    };


    let pipeVal: any;
    // tslint:disable-next-line:prefer-for-of
    for (pipeIndex = 0; pipeIndex < pipes.length; pipeIndex++) {
      pipeVal = parsePipe(pipes[pipeIndex], pipeVal);
    }

    return pipeVal;
  } catch (e) {
    console.error(`Error processing query: ${query}`);
    throw e;
  }
}

export function GetInvalidFormControls(rootForm: FormGroup) {

  if (!rootForm) {
    return [];
  }
  const baseData: any[] = [];
  const loop = (data: any[], form: FormGroup) => {
    // tslint:disable-next-line:forin
    for (const ctrlName in form.controls) {
      const ctrl = form.get(ctrlName);
      if (ctrl instanceof FormControl) {
        if (ctrl.invalid) {
          data.push(ctrlName);
        }
      } else if (ctrl instanceof FormGroup) {
        const subErrors: any[] = [];
        if (!ctrl.invalid) {
          continue;
        }
        loop(subErrors, ctrl);
        if (subErrors.length) {
          const errors = {};
          // @ts-ignore
          errors[ctrlName] = subErrors;
          data.push(errors);
        }
      }
    }
  };
  loop(baseData, rootForm);
  return baseData;
}



