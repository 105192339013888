<header>
  <mat-toolbar id="header" fxLayoutAlign="space-between center">

    <div fxHide.gt-sm>
      <button class="icon" mat-icon-button (click)="toggleNav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

    <!-- Logo -->
    <a routerLink="/">
      <img src="../../../assets/img/logos/Bankers-Logo-2021_Teal.svg"
           alt="Logo"
           id="header-logo">

    </a>
    <span style="float: left; color: white" *ngIf="isLocal">
      <span style="color: white" fxHide fxShow.xs>xs</span>
      <span style="color: white" fxHide fxShow.sm>sm</span>
      <span style="color: white" fxHide fxShow.md>md</span>
      <span style="color: white" fxHide fxShow.lg>lg</span>
      <span style="color: white" fxHide fxShow.xl>xl</span>
    </span>
    <ul class="nav-items" fxLayout fxLayoutGap="20px" fxLayoutAlign="center center" fxHide.xs>
      <!--
      <li>
        <a class="nav-items-link" routerLink="resources">
          <mat-icon class="navigation-items__icon">help</mat-icon>
        </a>
      </li>
      -->
      <li *ngIf="UiService.isAskBChat()">
        <a class="nav-items-link" (click)="UiService.openAskBChat()" matTooltip="AI powered Chatbot">
          <img src="../../../assets/img/icons/askb-chatbot-white.png"
               alt="AskB" style="width: 2rem; height: auto; border-radius: 50%;"
               id="ask-b-logo">
        </a>
      </li>
      <li *ngIf="!UserService.isBridgedAgent">
        <a class="nav-items-link" routerLink="quoting">
          <mat-icon class="navigation-items__icon">home</mat-icon>
        </a>
      </li>
      <li *ngIf="!UserService.isBridgedAgent">
        <a class="nav-items-link" routerLink="my-account" [matTooltip]="agencyDetailsHtml">
          <mat-icon class="navigation-items__icon">perm_identity</mat-icon>
        </a>
      </li>
      <li>
        <a class="nav-items-link" (click)="logout()">
          <mat-icon class="navigation-items__icon">power_settings_new</mat-icon>
        </a>
      </li>
    </ul>
  </mat-toolbar>
</header>
