import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {NetworkingUtils} from '../shared/networking/networking.utils';
import {SkipToken, TokenName} from '../shared/intercepters/http-auth.interceptor';
import * as jwtDecode from 'jwt-decode';
import {StorageKeys} from '../shared/extensions/storage.extension';
import {Agent} from '../shared/models/agency/agent';
import * as LogRocket from 'logrocket';
import {Environments} from '../../environments/base-environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }


  static IsLoggedIn() {
    const token = localStorage.get('token');

    if (!token) {
      return false;
    }
    const decoded: any = jwtDecode(token);
    if (!decoded.exp) {
      return false;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    // check if expiry is in future
    return date.valueOf() > new Date().valueOf();
  }

  async login(agentNumber: string, userName: string, password: string) {
    const resp = await this.http.post<{ jwt: string }>(`${environment.endpoints.policy}/auth`,
      {AgentNumber: agentNumber.toString(), Password: password, UserName: userName}, SkipToken())
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })
      ).toPromise();
    localStorage.set('token', resp?.jwt);
  }

  // todo: implement actual user
  static get UserFromJwt(): Agent|null {
    const authToken = localStorage.get(StorageKeys.Token);
    if (!authToken) {
      return null;
    }
    const decoded: any = jwtDecode(authToken);
    return {
      access: {days: [], time: {end: 0, start: 0}},
      agencyNum: decoded.AgentNumber,
      userName: `${decoded.AgentFirstName} ${decoded.AgentLastName}`,
      name: {
        firstName: decoded.AgentFirstName,
        lastName: decoded.AgentLastName
      }
    };
  }

  async loginWithToken(token: string) {
    try {
      const decoded: any = jwtDecode(token);
      localStorage.clear();
      localStorage.set('token', token);
    } catch (e) {
      throw e;
    }
  }

  logout() {
    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    if (environment.name === Environments.Sqa || environment.name === Environments.Prod) {
      LogRocket.startNewSession();
    }
    localStorage.clear();
  }

  static get isBridgedAgent(): boolean {
    const authToken = localStorage.get(StorageKeys.Token);
    if (!authToken) {
      return null;
    }
    const decoded: any = jwtDecode(authToken);
    if (decoded.role === 'Bridged') {
      return true;
    }
    return  false;
  }

  isAppalachianAgent(agentNbr: string): boolean {
    if (agentNbr === '104389') {
      return true;
    }
    return  false;
  }

  get isAppalachianRedirect(): boolean {
    const authToken = localStorage.get(StorageKeys.Token);
    if (authToken) {
      const decoded: any = jwtDecode(authToken);
      if (decoded.location_guid) {
        return true;
      }
    }
    return false;
  }
}
