import { Pipe, PipeTransform } from '@angular/core';
import {Product} from '../models/product.data';

@Pipe({
  name: 'productIcon'
})
export class ProductIconPipe implements PipeTransform {

  transform(value: string): string {
    switch (value.toLowerCase().trim()) {
      case Product.BusinessOwnersPolicy:
        return 'business';
      case Product.BuildersRisk:
        return 'build';
      case Product.Homeowners:
      case Product.HomeOwnersAssociation:
      case Product.DwellingProperty:
      case Product.Pristine:
        return 'home';
      case Product.MobileHomePark:
        return 'directions_bus';
      case Product.ExcessFlood:
        return 'pool';
      default:
        return 'receipt';
    }
  }

}
