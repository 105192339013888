import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '([formControlName], [formControl])[disabledControl]',
})
export class DisableControlDirective {
  @Input() set disabledControl(state: boolean) {
    this.ngControl.control[state ? 'disable' : 'enable']();
  }

  constructor(private readonly ngControl: NgControl) {}
}
