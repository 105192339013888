import {AbstractControl} from '@angular/forms';

export enum NodeType {
  Var = 'var',
  Assign = 'assign',
  Action = 'action',
  Infix = 'infix',
  Prefix = 'prefix',
  When = 'when',
  Data = 'data',
  FormCheck = 'formCheck',
  Comment = 'comment'
  // Key = 'key',
}

export interface INode {
  type: NodeType;
}

export interface ICommentNode extends INode {
  line: string;
}

export interface IVarNode extends INode {
  isSelf: boolean;
  target: string;
  props: string[];
  isLookup?: boolean;
}

export interface IFormCheckNode extends INode {
  target: IVarNode;
  check: 'changes' | 'valid' | 'invalid' | 'exists';
}

export interface Handle {
  pointer: any;
  finalProp: string;
  pointerParent?: any;
  pointerParentKey?: string;
  // path: string;
}

export type NodeDataType = null | string | number | boolean | Date | (NodeDataType[]) | { [key: string]: any } | Handle | AbstractControl;

export interface IDataNode extends INode {
  data: NodeDataType;
}

export interface IWhenNode extends INode {
  varsToWatch: IVarNode[];
  condition: INode;
  then: INode[];
}

export interface IAssignNode extends INode {
  target: IVarNode;
  value: NodeDataType;
}

export interface IActionNode extends INode {
  action: string;
  target: INode;
  with: NodeDataType[];
  condition?: INode;
}

export enum InfixOp {
  Equals = '==',
  NotEquals = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  And = '&&',
  Or = '||',
  Add = '+',
  Sub = '-',
  Mul = '*',
  Div = '/',
  Pow = '/'
}

export interface IInfixAction extends INode {
  left: INode;
  right: INode;
  op: InfixOp;
}

export interface IPrefixAction extends INode {
  op: PrefixOp;
  right: INode;
}

export enum PrefixOp {
  Negate = 'negate',
}
