import {Component, HostListener} from '@angular/core';
import * as LogRocket from 'logrocket';
import {environment} from '../environments/environment';
import {Environments} from '../environments/base-environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Quoting-Ui';
  private hiddenType = '';
  inMaintenance = environment.maintenance;

  constructor() {
    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    if (environment.name === Environments.Sqa || environment.name === Environments.Prod) {
      // Add this to your existing init call, do not call init twice!
      LogRocket.init('0gh5yw/ins-portal', {
        network: {
          requestSanitizer: request => {
            // Scrub out authorize.net body requests so we don't log card info
            if (request['url'].toLowerCase().indexOf('authorize.net') !== -1) {
              request['body'] = null;
            }

            return request;
          },
        },
      });
      LogRocket.getSessionURL(url => {
        document.cookie = `lr-session=${url}`;
      });
    }

    if (localStorage.getItem('backdoor')) {
      this.inMaintenance = false;
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.inMaintenance) {
      return;
    }
    if (!event.key) {
      this.hiddenType = '';
      return;
    }
    if (event.key === 'Escape') {
      this.hiddenType = '';
    } else {
      this.hiddenType += event.key;
    }
    if (this.hiddenType === '-letmein') {
      localStorage.setItem('backdoor', 'true');
      this.inMaintenance = false;
    }
  }

}
