<!--<div *ngIf="config.visible && config.dimmed" class="tooltip-dimmer" (click)="close()"></div>-->
<div class="tooltip-form-wrapper wrapper" [ngStyle]="position" #wrapper [hidden]="!config.visible">
  <div fxLayout="column" fxLayoutAlign="space-between center" [class]="'tooltip-form-' + config.side">

    <!--    <ng-container *ngIf="parentForm">-->
    <!--      <ng-template [ngTemplateOutlet]="parentForm"></ng-template>-->
    <!--    </ng-container>-->
    <div fxLayout="column">
      <p class="section-header">{{title}}</p>
      <!--      <app-question [question]="subQuestion" [parentForm]="parentForm" *ngFor="let subQuestion of questions"></app-question>-->
    </div>
    <ng-content></ng-content>

    <div fxLayout="row [wrap]" fxLayoutAlign="center bottom" fxLayoutGap="1rem">
      <button mat-stroked-button class="btn-warn" (click)="close()">Cancel</button>
      <!--      <span>{{saveEnabled}} {{valid}} {{ready}}</span>-->
      <button mat-button class="btn-success" [disabled]="!saveEnabled || !valid" (click)="save()">Okay</button>
    </div>
  </div>
</div>
