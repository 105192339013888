<button mat-stroked-button
        *ngIf="config.style == buttonStyles.Stroked"
        (click)="click()"
        [class.btn-warn]="config.type == buttonTypes.Warn"
        [class.btn-info]="config.type == buttonTypes.Info"
        [class.btn-success]="config.type == buttonTypes.Success"
        [class.btn-error]="config.type == buttonTypes.Danger"
        [class.btn]="config.type == buttonTypes.Other"
        [disabled]="disabled"
        [innerHTML]="config.text || ''"></button>

<button mat-flat-button
        *ngIf="config.style == buttonStyles.None"
        (click)="click()"
        [class.btn-warn]="config.type == buttonTypes.Warn"
        [class.btn-info]="config.type == buttonTypes.Info"
        [class.btn-success]="config.type == buttonTypes.Success"
        [class.btn-error]="config.type == buttonTypes.Danger"
        [class.btn]="config.type == buttonTypes.Other"
        [disabled]="disabled"
        [innerHTML]="config.text || ''"></button>
<button mat-button
        *ngIf="config.style == buttonStyles.Solid"
        (click)="click()"
        [class.btn-warn]="config.type == buttonTypes.Warn"
        [class.btn-info]="config.type == buttonTypes.Info"
        [class.btn-success]="config.type == buttonTypes.Success"
        [class.btn-error]="config.type == buttonTypes.Danger"
        [class.btn]="config.type == buttonTypes.Other"
        [disabled]="disabled"
        [innerHTML]="config.text || ''"></button>
<button mat-fab
        *ngIf="config.style == buttonStyles.Circle"
        (click)="click()"
        [class.btn-warn]="config.type == buttonTypes.Warn"
        [class.btn-info]="config.type == buttonTypes.Info"
        [class.btn-success]="config.type == buttonTypes.Success"
        [class.btn-error]="config.type == buttonTypes.Danger"
        [class.btn]="config.type == buttonTypes.Other"
        [disabled]="disabled"
        [innerHTML]="config.text || ''"></button>
<button mat-icon-button
        *ngIf="config.isIcon"
        (click)="click()"
        [class.btn-warn]="config.type == buttonTypes.Warn"
        [class.btn-info]="config.type == buttonTypes.Info"
        [class.btn-success]="config.type == buttonTypes.Success"
        [class.btn-error]="config.type == buttonTypes.Danger"
        [class.btn]="config.type == buttonTypes.Other"
        [disabled]="disabled"
        [innerHTML]="config.text || ''"></button>
