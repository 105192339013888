import {ApplicationRef, Inject, Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {INavPage, NavPage, NavProvider} from '../shared/models/nav-page.model';
import {BehaviorSubject} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';
import {environment} from '../../environments/environment';
import {AgencyService} from '../agency/agency.service';
import {ReportsService} from '../reports/reports.service';
import {LoggerService} from '../shared/services/logger.service';

LoggerService.Register('Nav');

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {

  public returnPage: INavPage;
  private _subNavItems: INavPage[] = [];
  private _currentPage: INavPage = null;
  private _currentSubPage: INavPage = null;
  private _mobileQueryListener: () => void;
  private mobileQuery: MediaQueryList;

  private _sideBarCollapsed = false;
  private _helpBarCollapsed = true;
  private _sideBarStateChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._sideBarCollapsed);
  private _helpBarStateChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._helpBarCollapsed);
  public sideBarStateCollapseChanged$ = this._sideBarStateChanged.asObservable();
  public helpBarStateCollapseChanged$ = this._helpBarStateChanged.asObservable();


  // private _navTemplate: TemplateRef<any>;
  public navTemplate: TemplateRef<any>;


  set sideBarCollapsed(isCollapsed: boolean) {
    this._sideBarCollapsed = isCollapsed;
    this._sideBarStateChanged.next(isCollapsed);
  }

  set helpBarCollapsed(isCollapsed: boolean) {
    this._helpBarCollapsed = isCollapsed;
    this._helpBarStateChanged.next(isCollapsed);
  }

  private _pageChanged = new BehaviorSubject<INavPage>(this._currentPage);
  private _subPageChanged = new BehaviorSubject<INavPage>(this._currentSubPage);

  public _pages = new BehaviorSubject<INavPage[]>([]);

  get pages() {
    return this._pages.asObservable();
  }

  public currentPage = this._pageChanged.asObservable();
  public currentSubPage = this._subPageChanged.asObservable();

  constructor(private readonly changeDetectorRef: ApplicationRef,
              private readonly media: MediaMatcher,
              private readonly agencySvc: AgencyService,
              private readonly reportsSvc: ReportsService) {

    this.mobileQuery = media.matchMedia('(max-width: 959px)');
    if (changeDetectorRef.components.length > 0) {
      this._mobileQueryListener = () => changeDetectorRef.components[0].changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  get isMobile(): boolean {
    return this.mobileQuery.matches;
  }

  public toggleHelpBar() {
    this.helpBarCollapsed = !this._helpBarCollapsed;
  }

  async loadPages() {
    const pages = [
      new NavPage({pageTitle: 'Dashboard', navIcon: 'dashboard', navLink: '/'}),
      new NavPage({
        pageTitle: 'Quoting',
        navIcon: 'description',
        subPages: [
          new NavPage({pageTitle: 'Begin/Search Quote', navLink: 'quoting', navIcon: 'description'}),
          new NavPage({pageTitle: 'Active Quotes', navLink: 'active-quotes', navIcon: 'plagiarism'}),
          new NavPage({pageTitle: 'Quoting Preferences', navLink: 'preferences', navIcon: 'settings'}),
        ]
      }),
      new NavPage({
        pageTitle: 'Policies',
        navIcon: 'business_center',
      }),
      // new NavPage({pageTitle: 'Claims Center', navIcon: 'business_center', navLink: 'claims'}),
      ...(await this.agencySvc.pages),
      ...(await this.reportsSvc.pages),
      new NavPage({pageTitle: 'Resources', navIcon: 'help'}),
    ];
    this._pages.next(pages);
  }

  public pageFromRoute(url: string): INavPage {
    url = url.split(';')[0];

    const checkPage = (page: INavPage): INavPage => {

      const absolutPath = page.navLink?.[0] === '/' ? page.navLink : '/' + page.navLink;
      if (absolutPath === url) {
        return page;
      }
      return page.subPages?.find(checkPage);
      // if (subPage) {
      //   return subPage;
      // }
      // console.log(url, absolutPath);
      // if (url.includes(absolutPath)) {
      //   return pageName;
      // }
      // return null;
    };

    let foundPage = this._pages.value.find(checkPage);
    const urlParts = url.split('/');

    while (urlParts.length && !foundPage) {
      urlParts.pop();
      url = urlParts.join('/');
      foundPage = this._pages.value.find(checkPage);
    }

    // const pageName = this._pages.value.find(p => {
    //   if (p.navLink[0] === '/') {
    //     return p.navLink === url;
    //   } else if (p.subPages) {
    //     const subPage = p.subPages.find(sub => {
    //       this._subPageChanged.next(sub);
    //       return '/' + sub.navLink === url;
    //     });
    //     return !!subPage || '/' + p.navLink === url;
    //   } else {
    //     return '/' + p.navLink === url;
    //   }
    // });

    if (!this._currentPage || this._currentPage.navLink !== foundPage?.navLink) {
      this._currentPage = foundPage;
      this._pageChanged.next(this._currentPage);
    }
    // console.log('Nav', url, foundPage, this._pages.value);
    return foundPage;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
