<div class="dimmer" [class.dimmer-fullscreen]="fullScreen" *ngIf="(fullScreen && (uiSvc.dimmed | async)) || dimmed" fxLayout="column" fxLayoutAlign="start center" [@enterAnimation]>
  <h2 class="dimmer-title" [innerHTML]="fullScreen ? (uiSvc.title | async) : (title || '')"></h2>
  <p class="paragraph dimmer-message" [innerHTML]="fullScreen ? (uiSvc.message | async) : (message || '')"></p>
  <div class="progress-line {{barColor}}"></div>
<!--  <mat-spinner [strokeWidth]="3" class="dimmer-spinner"></mat-spinner>-->
</div>

<!--<div class="dimmer" [class.dimmer-fullscreen]="fullScreen"  fxLayout="column" fxLayoutAlign="start center" [@enterAnimation]>-->
<!--  <h2 class="dimmer-title">This is a title</h2>-->
<!--  <p class="paragraph dimmer-message">This is a message</p>-->
<!--  <mat-spinner [strokeWidth]="3" class="dimmer-spinner"></mat-spinner>-->
<!--  <div class="progress-line {{barColor}}"></div>-->

<!--</div>-->

<!--<div class="popup-dimmer" [@enterAnimation] *ngIf="uiSvc.popupDisplayed | async"></div>-->

<!--<div class="dimmer" [class.dimmer-fullscreen]="fullScreen" *ngIf="(fullScreen && true) || dimmed" fxLayout="column" fxLayoutAlign="start center" [@enterAnimation]>-->
<!--  &lt;!&ndash;  <h2 class="dimmer-title" [innerHTML]="fullScreen ? (uiSvc.title | async) : (title || '')"></h2>&ndash;&gt;-->
<!--  <h2 class="dimmer-title">This isa test</h2>-->
<!--  &lt;!&ndash;  <p class="paragraph dimmer-message" [innerHTML]="fullScreen ? (uiSvc.message | async) : (message || '')"></p>&ndash;&gt;-->
<!--  <p class="paragraph dimmer-message">This sia a messge</p>-->
<!--  <mat-spinner [strokeWidth]="3" class="dimmer-spinner"></mat-spinner>-->
<!--</div>-->
