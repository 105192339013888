import {AbstractControl, FormGroup} from '@angular/forms';
import {HelpPage} from '../../../nav/help/help.interface';
import {Dependency} from './hooks/dependency';


export interface IQuestionPageSection {
  readonly title?: string;
  readonly icon?: string;
  readonly name?: string;
  readonly orientation?: 'row' | 'column';
  readonly size?: string;
  readonly subSections?: IQuestionPageSection[];
  readonly questions?: IGenericQuestion[];
  readonly isFenced?: boolean;
  readonly hidden?: boolean;
  readonly disabled?: boolean;
  readonly noDivider?: boolean;
  readonly meta?: { [key: string]: any };
  dependencies: Dependency[];
  script?: string;
}

export class QuestionPageSection implements IQuestionPageSection {

  title: string;
  readonly icon: string;
  readonly name: string;
  readonly orientation: 'row' | 'column';
  readonly size: string;
  readonly subSections: QuestionPageSection[];
  readonly questions: GenericQuestion[];
  readonly isFenced: boolean;
  readonly hidden: boolean;
  readonly disabled: boolean;
  readonly noDivider: boolean;
  readonly meta: { [key: string]: any };
  dependencies: Dependency[];
  script?: string;

  constructor(sec: IQuestionPageSection) {
    this.title = sec.title;
    this.icon = sec.icon;
    this.name = sec.name;
    this.orientation = sec.orientation ?? 'row';
    this.size = sec.size;
    this.subSections = sec.subSections?.map(s => new QuestionPageSection(s)) ?? [];
    this.questions = sec.questions?.map(q => MapQuestion(q)) ?? [];
    this.isFenced = sec.isFenced;
    this.hidden = sec.hidden;
    this.disabled = sec.disabled;
    this.noDivider = sec.noDivider;
    this.meta = sec.meta ?? {};
    this.dependencies = sec.dependencies ?? [];
    this.script = sec.script;

    if ((sec as any).dependency) {
      this.dependencies.push((sec as any).dependency as Dependency);
    }
  }


  private recursiveGetter(propTarget: string) {
    const getter = (section?: QuestionPageSection) => {
      if (!section) {
        return {};
      }
      let val: {[name: string]: any} = {};
      section.subSections.forEach(s => {
        if (s.name?.trim()?.length) {
          val[s.name] = getter(s);
        } else {
          val = {...val, ...getter(s)};
        }
      });

      section.questions.forEach(q => {
        if (q.type === QuestionType.Verbiage) {
          return;
        }
        val[q.name] = (q as any)[propTarget];
        const subQuesVal = getter(q.subQuestions);
        if (q.subQuestions?.name?.trim()?.length) {
          val[q.subQuestions.name] = subQuesVal;
        } else {
          val = {...val, ...subQuesVal};
        }
      });

      return val;
    };

    return getter(this);
  }

  public getDefaultsValues(): any {
    return this.recursiveGetter('defaultValue');
  }
  public getSelectedValues(): any {
    return this.recursiveGetter('selectedValue');
  }

}


export enum QuestionType {
  Input = 'input',
  Radio = 'radio',
  Select = 'select',
  Checkbox = 'checkbox',
  Verbiage = 'verbiage',
  Table = 'table',
  Popup = 'popup',
}

export interface IQuestion<T> {
  type: QuestionType;
  name?: string;
  disabled?: boolean;
  selectedValue?: T | T[];
  defaultValue?: T | T[];
  helpText?: string;
  title?: string;
  subTitle?: string;
  hidden?: boolean;
  required?: boolean;
  subQuestions?: IQuestionPageSection;
  meta?: { [key: string]: any };
  size?: number | string;
  dependencies: Dependency[];
  script?: string;
}


export class Question<T> implements IQuestion<T> {
  type: QuestionType;
  name: string;
  disabled: boolean;
  selectedValue?: T | T[];
  defaultValue?: T | T[];
  helpText?: string;
  title?: string;
  subTitle?: string;
  hidden: boolean;
  required: boolean;
  subQuestions?: QuestionPageSection;
  meta?: { [key: string]: any };
  size?: number | string;
  dependencies: Dependency[];
  script?: string;

  constructor(question: IQuestion<T>) {
    this.type = question.type;
    this.name = question.name;
    this.disabled = question.disabled;
    this.selectedValue = question.selectedValue;
    this.defaultValue = question.defaultValue;
    this.helpText = question.helpText;
    this.title = question.title;
    this.subTitle = question.subTitle;
    this.hidden = question.hidden;
    this.required = question.required;
    this.subQuestions = question.subQuestions ? new QuestionPageSection(question.subQuestions) : null;
    this.meta = question.meta ?? {};
    this.size = question.size;
    this.dependencies = question.dependencies;
    this.script = question.script;
  }


}


export interface QuestionOption<T> {
  value: T;
  text?: string;
  icon?: string;
}


export function FindSectionQuestion(section: QuestionPageSection, name: string, sectionName?: string) {

  const search = (subSection: QuestionPageSection, quesSubSectionName?: string): GenericQuestion | null => {
    let ques = subSection.questions.find(q => FindSubQuestion(q, name, quesSubSectionName));
    if (ques) {
      return ques;
    }
    for (const subSubSection of subSection?.subSections) {
      ques = search(subSection, quesSubSectionName);
      if (ques) {
        return ques;
      }
    }
    return null;
  };

  let searchSection = section;
  let targetSectionName = sectionName;
  if (sectionName?.length) {
    const findSection = (subSubSection: QuestionPageSection): QuestionPageSection => {
      if (subSubSection.name === sectionName) {
        return subSubSection;
      }
      return subSubSection.subSections?.find(findSection);
    };
    searchSection = findSection(searchSection) ?? searchSection;
    targetSectionName = searchSection.name === sectionName ? null : sectionName;
  }

  return search(searchSection, targetSectionName);
}

export type IGenericQuestion =
  (IQuestion<any> | IInputQuestion<any> | ISelectQuestion<any> | IRadioQuestion<any> | ICheckboxQuestion<any> | ITableQuestion<any> | IPopupQuestion<any>);

export type GenericQuestion =
  (Question<any> | InputQuestion<any> | SelectQuestion<any> | RadioQuestion<any> | CheckboxQuestion<any> | TableQuestion<any> | PopupQuestion<any>);


export function MapQuestion<T>(ques: IGenericQuestion): GenericQuestion {
  switch (ques.type) {
    case QuestionType.Input:
      return new InputQuestion(ques as IInputQuestion<T>);
    case QuestionType.Radio:
      return new RadioQuestion(ques as IRadioQuestion<T>);
    case QuestionType.Select:
      return new SelectQuestion(ques as ISelectQuestion<T>);
    case QuestionType.Checkbox:
      return new CheckboxQuestion(ques as ICheckboxQuestion<T>);
    case QuestionType.Verbiage:
      return new Question(ques as IQuestion<T>);
    case QuestionType.Table:
      return new TableQuestion(ques as ITableQuestion<T>);
    case QuestionType.Popup:
      return new PopupQuestion(ques as IPopupQuestion<T>);
  }
}


export interface ICheckboxQuestion<T> extends IQuestion<T> {
  options: QuestionOption<T>[];
  orientation?: 'row' | 'column';
}

export class CheckboxQuestion<T> extends Question<T> {
  options: QuestionOption<T>[];
  orientation?: 'row' | 'column';

  constructor(question: ICheckboxQuestion<T>) {
    super(question);
    this.options = question.options;
    this.orientation = question.orientation ?? 'row';
  }
}

export interface IInputQuestion<T> extends IQuestion<T> {
  inputType: QuestionInputType;
  pattern?: string;
  mask?: string;
  minLength?: number;
  maxLength?: number;
  isCurrency?: boolean;
  minVal?: number;
  maxVal?: number;
  multiple?: boolean;
}

export class InputQuestion<T> extends Question<T> implements IInputQuestion<T> {
  inputType: QuestionInputType;
  pattern?: string;
  mask?: string;
  minLength?: number;
  maxLength?: number;
  isCurrency?: boolean;
  minVal?: number;
  maxVal?: number;
  multiple?: boolean;

  constructor(ques: IInputQuestion<T>) {
    if (ques.inputType === QuestionInputType.Date && typeof ques.selectedValue === 'string') {
      ques.selectedValue = new Date(ques.selectedValue as string) as unknown as T;
    }
    super(ques);
    this.inputType = ques.inputType;
    this.pattern = ques.pattern;
    this.mask = ques.mask;
    this.minLength = ques.minLength;
    this.maxLength = ques.maxLength;
    this.isCurrency = ques.isCurrency;
    this.minVal = ques.minVal;
    this.maxVal = ques.maxVal;
    if (ques.inputType === QuestionInputType.File && typeof ques.multiple === 'boolean') {
      this.multiple = ques.multiple;
    } else {
      this.multiple = true;
    }
  }
}

export enum QuestionInputType {
  Text = 'text',
  Number = 'number',
  Paragraph = 'paragraph',
  AlphaNumeric = 'alphaNumeric',
  Email = 'email',
  Phone = 'phone',
  Address = 'address',
  File = 'file',
  Date = 'date',
  SocialSecurity = 'socialSecurity',
}


export interface IPopupQuestion<T> extends IQuestion<T> {
  readonly okayBtn?: string;
  readonly helpInfo?: HelpPage;
}

export class PopupQuestion<T> extends Question<T> implements IPopupQuestion<T> {
  readonly okayBtn?: string;
  readonly helpInfo: HelpPage;

  constructor(ques: IPopupQuestion<T>) {
    super(ques);
    this.okayBtn = ques.okayBtn;
    this.helpInfo = ques.helpInfo;
  }

}

export interface IRadioQuestion<T> extends IQuestion<T> {
  options: QuestionOption<T>[];
  useImageAsButton?: boolean;
  orientation?: 'row' | 'column';
}

export class RadioQuestion<T> extends Question<T> implements IRadioQuestion<T> {
  options: QuestionOption<T>[];
  useImageAsButton?: boolean;
  orientation?: 'row' | 'column';

  constructor(question: IRadioQuestion<T>) {
    super(question);
    this.options = question.options;
    this.useImageAsButton = question.useImageAsButton;
    this.orientation = question.orientation ?? 'row';
  }
}


export interface ISelectQuestion<T> extends IQuestion<T> {
  options: QuestionOption<T>[];
  showNoSelectOption?: boolean;
}

export class SelectQuestion<T> extends Question<T> implements ISelectQuestion<T> {

  options: QuestionOption<T>[];
  showNoSelectOption?: boolean;

  constructor(question: ISelectQuestion<T>) {
    super(question);
    this.options = question.options;
    this.showNoSelectOption = question.showNoSelectOption;
  }


  verifyValue(value: any) {
    // tslint:disable-next-line:triple-equals
    return this.options.find(o => o.value == value)?.value ?? null;
  }

}

export interface ITableQuestion<T> extends IQuestion<T> {
  rows: T[];
  columns?: T[];
  noRowsMessage?: string;
  orientation?: 'row' | 'column';
  helpInfo?: HelpPage;
  displayLookup?: { [key: string]: T };
}

export class TableQuestion<T> extends Question<T> implements ITableQuestion<T> {
  rows: T[];
  columns: T[];
  noRowsMessage: string;
  orientation: 'row' | 'column';
  helpInfo: HelpPage;
  displayLookup: { [key: string]: T };

  constructor(ques: ITableQuestion<T>) {
    super(ques);
    this.rows = ques.rows ?? [];
    this.columns = ques.columns ?? [];
    this.noRowsMessage = ques.noRowsMessage ?? '';
    this.orientation = ques.orientation ?? 'row';
    this.helpInfo = ques.helpInfo;
    this.displayLookup = ques.displayLookup ?? {};
  }

}

export function FindSubQuestion(question: GenericQuestion, name: string, sectionName?: string): GenericQuestion | null {
  const foundSection = !sectionName?.length;
  let possibleQues: GenericQuestion;
  const search = (ques: GenericQuestion): GenericQuestion | null => {
    if (ques.name === name) {
      possibleQues = ques;
      if (foundSection) {
        return possibleQues;
      }
    }

    let subQues = ques.subQuestions?.questions.find(q => search(q));
    if (subQues) {
      possibleQues = subQues;
      if (foundSection) {
        return possibleQues;
      }
    }


    for (const subSection of ques.subQuestions?.subSections ?? []) {
      subQues = FindSectionQuestion(subSection, name, sectionName);
      if (subQues) {
        possibleQues = subQues;
        if (foundSection) {
          return possibleQues;
        }
      }
    }
    return null;
  };

  return search(question);

}
