<div class="page">
  <div [class.page-header]="!inline" [class.page-header-inline]="inline">
    <div class="titles">
      <h1 class="header-primary" slot="header">
        <ng-content select="[slot=header]"></ng-content>
      </h1>
      <h2 class="header-secondary">
        <ng-content select="[slot=subheader]"></ng-content>
      </h2>
    </div>
    <div class="page-actions">
      <ng-content select="[slot=actions-left]"></ng-content>
    </div>
  </div>
  <div class="page-content" fxLayout="column" fxLayoutAlign="start start">
    <ng-content select="[slot=content]"></ng-content>
  </div>
</div>


