import {Compiler, ComponentFactoryResolver, Injectable, Injector, Type} from '@angular/core';
import {LogicService} from './logic-injector/logic-service.interface';
import {IQuote, QuestionSaveResponse, QuoteNotification} from '../shared/models/quoting/quote.model';
import * as assert from 'assert';
import {GenericQuestion, MapQuestion, QuestionOption, QuestionPageSection} from '../shared/models/quoting/questions.model';
import {QuestionPage} from '../shared/models/quoting/quote-page.model';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ProcessLookupQuery, QuestioningService} from '../questioning/questioning.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ProductKeys} from '../shared/models/quoting/product-keys';
import {environment} from '../../environments/environment';
// import {} from 'jwt-decode';
import * as jwtDecode from 'jwt-decode';
import {StorageKeys} from '../shared/extensions/storage.extension';
import {Address} from '../shared/models/address.interface';
import {IBuilding, ILocation} from '../shared/models/quoting/quote-location.interface';
import {Product} from '../shared/models/product.data';
import {NavService} from '../nav/nav.service';
import {LoggerService} from '../shared/services/logger.service';
import {catchError, map, tap} from 'rxjs/operators';
import {NetworkingUtils} from '../shared/networking/networking.utils';
import {UiService} from '../shared/ui.service';
import {AbstractControl, FormGroup} from '@angular/forms';
// const jwtDecode = require('jwt-decode');
import {Pages} from 'src/app/shared/models/pages.data';
import {Environments} from '../../environments/base-environment';
import {IDataNode, IVarNode, NodeDataType, NodeType} from '../dsl/types.dsl';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {QuestionComponent} from '../questioning/question/question.component';
import {InterpretCfg, Interpreter} from '../dsl/interpreter.dsl';

const log = LoggerService.Register('Quoting');
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class QuotingService {

  public static ProductLogicMap: { [productName: string]: Type<LogicService> } = {};
  private pageInstanceLoaded = false;
  private allProductLogic: { [productName: string]: LogicService } = {};

  private get product(): string {
    return QuotingService.quote.productAbv;
  }
  public showQuesNames = false;

  public pageName: string;

  public previousPageData: any;

  public pageStatus = new BehaviorSubject<QuotePageStatus>(QuotePageStatus.Untouched);

  public setPageStatus(status: QuotePageStatus) {
    this.pageStatus.next(status);
  }

  public pages = new BehaviorSubject<QuestionPage[]>([]);

  private locationNumber = localStorage.get(StorageKeys.CurrentLocationNumber, 1);
  private buildingNumber = localStorage.get(StorageKeys.CurrentBuildingNumber, 1);
  private _currentLocation: BehaviorSubject<ILocation>;
  private _currentBuilding: BehaviorSubject<IBuilding>;

  get currentLocationSnapshot() {
    return this._currentLocation.value;
  }

  get currentLocation() {
    // console.log('LOCIDENX: ', this.locationNumber, this._currentLocation.value);
    return this._currentLocation.asObservable();
  }

  get currentBuildingSnapshot() {
    return this._currentBuilding.value;
  }

  get currentBuilding() {
    // console.log('BLDID: ', this.buildingNumber, this._currentBuilding.value);
    return this._currentBuilding.asObservable();
  }

  // set currentLocationNumber(locationNum: number) {
  //   if (this.locationNumber === locationNum) {
  //     return;
  //   }
  //   this.locationNumber = locationNum;
  //   const loc = QuotingService.quote?.locations?.find(l => l.locationNumber === this.locationNumber);
  //   this._currentLocation.next(loc ?? QuotingService.quote.locations?.[0]);
  //   localStorage.set(StorageKeys.CurrentLocationNumber, locationNum || 1);
  //   this.currentBuildingNumber = 1;
  // }
  //
  // set currentBuildingNumber(buildingNum: number) {
  //   // if (buildingNum === 0) {
  //   console.trace('buildin--' + buildingNum);
  //   this.buildingNumber = buildingNum;
  //   localStorage.set(StorageKeys.CurrentBuildingNumber, buildingNum || 1);
  //   const build = this._currentLocation.value?.buildings?.find(b => b.buildingNumber === this.buildingNumber);
  //   this._currentBuilding.next(build ?? this._currentLocation.value?.buildings?.[0]);
  // }

  // private currentBuilding = new BehaviorSubject(QuotingService.quote?.locations[this.locationNumber]);


  public premium = new BehaviorSubject<number>(0);
  private static _quote: IQuote;

  static get quote(): IQuote {
    if (!QuotingService._quote) {
      QuotingService._quote = localStorage.get(StorageKeys.CurrentQuote);
      if (QuotingService._quote) {
        QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);
      }
    }
    return QuotingService._quote;
  }



  private _currentPage: BehaviorSubject<QuestionPage|null>;

  get currentPage() {
    return this._currentPage.asObservable();
  }

  public static PageForm: FormGroup = new FormGroup({});

  constructor(private compiler: Compiler, private injector: Injector,
              private navSvc: NavService,
              private componentResolver: ComponentFactoryResolver,
              private questionSvc: QuestioningService,
              private uiSvc: UiService,
              private http: HttpClient) {
    this.initSubjects();
    window.quoteSvc = this;
    window.statics = window.statics ?? {};
    window.statics.Quote = QuotingService;

    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    if (environment.name === Environments.Local) {
      window._utils = window._utils ?? {};
      window._utils.quote = this.utils;
    }
  }

  public readonly utils = {
    findSection: (...names: string[]) => {

      names = names.filter(n => n);

      const search = (sections: QuestionPageSection[], name: string) => sections.find(s => s.name === name);
      const recursiveSearch = (subSec: QuestionPageSection, name: string): QuestionPageSection|undefined => {
        if (subSec.name === name) {
          return subSec;
        }
        return subSec.subSections.find(s => recursiveSearch(s, name));
      };


      let section: QuestionPageSection|undefined;


      for (const name of names) {
        if (!name.trim().length) {
          continue;
        }
        const sections = section?.subSections ?? this._currentPage.value.sections;
        section = search(sections, name);
        if (section) {
          continue;
        }

        section = sections.find(s => recursiveSearch(s, name));

      }

      return section;

    },
    findQuestion: (name: string, sectionName?: string) => {

      const search = (section?: QuestionPageSection): GenericQuestion|null => {
        if (!section) {
          return null;
        }
        let question = section.questions.find(q => {
          if (q.name === name) {
            return q;
          }
          return search(q.subQuestions);
        });

        if (question) {
          return question;
        }

        for (const subSec of (section.subSections ?? [])) {
          question = search(subSec);
          if (question) {
            return question;
          }
        }
        return null;
      };

      const desiredSection = this.utils.findSection(sectionName);
      if (desiredSection) {
        return search(desiredSection);
      }
      for (const sec of this._currentPage.value.sections) {
        const question = search(sec);
        if (question) {
          return question;
        }
      }
      return null;
    },
  };

  private initSubjects() {

    this._currentPage?.next(null);
    this._currentLocation?.next(null);
    this._currentBuilding?.next(null);
    this.premium?.next(0);

    this._currentPage?.complete();
    this._currentLocation?.complete();
    this._currentBuilding?.complete();
    this.premium?.complete();

    this._currentPage = new BehaviorSubject<QuestionPage|null>(null);
    this.premium = new BehaviorSubject<number>(0);
    this._currentLocation = new BehaviorSubject<ILocation>(QuotingService.quote?.locations?.find(l => l.locationNumber === this.locationNumber));
    this._currentBuilding = new BehaviorSubject<IBuilding>(this._currentLocation.value?.buildings?.find(b => b.buildingNumber === this.buildingNumber));
  }


  async allLoaded() {
    return;
  }



  public async reloadInLocation() {
    return this.loadInLocation(this.locationNumber || 1, this.buildingNumber || 1);
  }
  public async loadInLocation(locationNum: number, buildingNum?: number) {

    // if (this.locationNumber === locationNum && this.buildingNumber === buildingNum) {
    //   console.log('swkipping');
    //   return;
    // }

    if (!locationNum) {
      this.locationNumber = 0;
      this.buildingNumber = 0;
      localStorage.set(StorageKeys.CurrentLocationNumber, 0);
      localStorage.set(StorageKeys.CurrentBuildingNumber, 0);
      this._currentLocation.next(null);
      this._currentBuilding.next(null);
      return;
    }

    localStorage.set(StorageKeys.CurrentLocationNumber, locationNum || 1);
    this.locationNumber = locationNum;

    const loc = QuotingService.quote?.locations?.find(l => l.locationNumber === this.locationNumber) ?? QuotingService.quote.locations?.[0];


    let building: IBuilding;
    if (buildingNum) {
      this.buildingNumber = buildingNum;
      localStorage.set(StorageKeys.CurrentBuildingNumber, buildingNum || 1);
      building =  loc?.buildings?.find(b => b.buildingNumber === this.buildingNumber);
      if (!building) {
        localStorage.set(StorageKeys.CurrentBuildingNumber, 1);
      }
    }
    this._currentLocation.next(loc);
    this._currentBuilding.next(building ?? loc?.buildings?.[0]);


  }

  // private searchStreaming: Observable<{keys: ProductKeys, location: Address, applicant: string}>;
  // private searchStreaming: Subscription;


  streamQuoteSearch(term: string, searchproduct: string): Observable<{ keys: ProductKeys, location: Address, applicant: string, token: string }[]> {
    // if (this.searchStreaming) {
    //   this.searchStreaming.unsubscribe();
    // }
    return this.http.get<any>(`${environment.endpoints.policy}/quote/search?term=${term}&productFilter=${searchproduct}`);
  }

  async searchAllQuotes(product: string): Promise<{ keys: ProductKeys, location: Address, applicant: string, token: string, status: string }[]> {
    return await this.http.get<{ keys: ProductKeys, location: Address, applicant: string,
      token: string, status: string }[]>(`${environment.endpoints.policy}/quote/list?productFilter=${product}`).toPromise();
  }


  async reload() {
    return new Promise(async resolve => {
      this.pageStatus.next(QuotePageStatus.Loading);
      const sub = this.pageStatus.subscribe(s => {
        if (s === QuotePageStatus.Ready) {
          resolve();
        }
      });
      // await this.loadItemPage();
    });
  }

  async getQuotePdf() {
    this.uiSvc.dim(`Generating ${QuotingService.quote.policyNumber ? 'Application' : 'Quote'} PDF`, `Please wait while we generate the ${QuotingService.quote.policyNumber ? 'application' : 'non-binding Quote'} PDF`);
    try {
      const headers = new HttpHeaders().set('Accept', 'applicant/pdf');
      const fileName = QuotingService.quote.policyNumber ? QuotingService.quote.policyNumber : QuotingService.quote.quoteNumber;
      const pdf = await this.http.get(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pdf`, {headers, responseType: 'blob' as 'json'}).pipe(
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })).toPromise();
      download(`${fileName}.pdf`, pdf as Blob);
      this.uiSvc.undim();
    } catch (e) {
      this.uiSvc.error('Unable to Generate Quote Pdf', e);
    }
    // log.log(pdf);
  }

  // Not loaded from jwt
  async readAndLoadQuote(quote: string) {

    // double check to make sure it's not a jwt passed in
    let decoded: {[key: string]: any};
    try {
      decoded = jwtDecode(quote);
    } catch (e) {
    }
    if (decoded?.QuoteNumber) {
      // await UiService.sleep(10000);
      return this.loadInQuote(decoded.QuoteNumber, quote);
    }

    localStorage.removeItem(StorageKeys.CurrentQuote);
    localStorage.removeItem(StorageKeys.CurrentQuoteToken);

    const quoteResp = await this.readQuote(quote);
    QuotingService._quote = quoteResp;
    QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);
    localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
    localStorage.set(StorageKeys.CurrentQuoteToken, quoteResp.jwt);
    this.navSvc.sideBarCollapsed = true;
    const pages = await this.getPages();
    this.pages.next(pages);
    const current = this.pages.value.find(p => p.current) ?? this.pages.value[0];
    await this.tryLoadPage(current?.name);
  }

  async loadInQuote(quoteNum: string, jwt: string) {

    localStorage.removeItem(StorageKeys.CurrentQuote);
    localStorage.set(StorageKeys.CurrentQuoteToken, jwt);

    QuotingService._quote = await this.readQuote(quoteNum);
    QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);
    localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
    this.navSvc.sideBarCollapsed = true;
    const pages = await this.getPages();
    this.pages.next(pages);
    const current = this.pages.value.find(p => p.current) ?? this.pages.value[0];
    await this.tryLoadPage(current?.name);
  }

  async copyQuote(keys: ProductKeys) {
    localStorage.removeItem(StorageKeys.CurrentQuote);
    const copyQuote = await this.http.post<{ quote: IQuote, token: string }>(`${environment.endpoints.policy}/quote/${keys.quoteNumber}/copy`,
      {drpId: keys.drpId, productAbv: keys.productAbv} ).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
    copyQuote.quote.token = copyQuote.token;
    localStorage.set(StorageKeys.CurrentQuote, copyQuote.quote);
    localStorage.set(StorageKeys.CurrentQuoteToken, copyQuote.token);
    this.navSvc.sideBarCollapsed = true;
    QuotingService._quote = copyQuote.quote;
    QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);
  }

  async readQuote(quoteNum: string): Promise<IQuote & {jwt: string}> {

    return await this.http.get<HttpResponse<IQuote>>(`${environment.endpoints.policy}/quote/${quoteNum}`, {observe: 'response' as 'body'}).pipe(
      map((resp): IQuote & {jwt: string} => {
        const jwt = resp.headers.get('Policy') ?? (resp.body as any).jwt;
        const quote = (resp.body as any).quote ?? resp.body;


        return {...quote, jwt: jwt ?? localStorage.get(StorageKeys.CurrentQuoteToken)};
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  public static jwtToQuote(jwt: string): IQuote {
    const decoded: any = jwtDecode(jwt);
    return {
      quoteId: decoded.Id,
      productAbv: decoded.ProductAbv,
      sublineAbv: decoded.SublineAbv,
      stateAbv: decoded.StateAbv,
      drpId: decoded.DrpId,
      policyNumber: decoded.PolicyNumber,
      quoteNumber: decoded.QuoteNumber,
      effectiveDate: new Date(decoded.EffectiveDate),
      isReadOnly: decoded.isReadOnly
    };
  }

  async loadQuote(quoteNumber?: string) {

    QuotingService._quote = localStorage.get(StorageKeys.CurrentQuote);
    if (quoteNumber || !QuotingService._quote) {
      QuotingService._quote = QuotingService.jwtToQuote(localStorage.get(StorageKeys.CurrentQuoteToken));
      localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
    }
    QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);

    this.navSvc.sideBarCollapsed = true;
    const pages = await this.getPages();
    this.pages.next(pages);
    const current = this.pages.value.find(p => p.current) ?? this.pages.value[0];
    await this.tryLoadPage(current?.name);
    // this.loadItemPage().then(() => this.pageStatus.next(QuotePageStatus.Ready));
    // this.pageStatus = QuotePageStatus.Untouched;
  }

  async newQuote(keys: ProductKeys) {
    const newQuote = await this.http.post<{ quote: IQuote, token: string }>(`${environment.endpoints.policy}/quote`, keys).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
    newQuote.quote.token = newQuote.token;
    localStorage.set(StorageKeys.CurrentQuote, newQuote.quote);
    localStorage.set(StorageKeys.CurrentQuoteToken, newQuote.token);
    this.navSvc.sideBarCollapsed = true;
    QuotingService._quote = newQuote.quote;
    QuotingService._quote.effectiveDate = new Date(QuotingService._quote.effectiveDate);
    // this.pages.next(await this.getPages());
    // this.loadItemPage().then(() => this.pageStatus.next(QuotePageStatus.Ready));
  }

  async getPages(): Promise<QuestionPage[]> {
    return this.http.get<QuestionPage[]>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages`).pipe(
      map(pages => pages.map(p => new QuestionPage(p))),
      tap(pages => this.pages.next(pages)),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  // async getPageSection(pageName: string, section: string): Promise<IQuestionPageSection> {
  //   return getBopPageSection(pageName, section);
  // }
  //
  // async getPageSectionNames(pageName: string): Promise<string[]> {
  //   return getBopPageSectionNames(pageName);
  // }
  //
  // async getPageSections(pageName: string): Promise<IQuestionPageSection[]> {
  //   return getBopPageSections(pageName);
  // }

  get previousPage(): string {
    const currentPageIndex = this.pages.value.findIndex(p => p.name === this.pageName);
    if (currentPageIndex === 0) {
      return this.pageName;
    }
    return this.pages.value[currentPageIndex - 1]?.name;
  }

  get nextPage(): string {
    const currentPageIndex = this.pages.value.findIndex(p => p.name === this.pageName);
    // this.currentPageName.next(this.pages.value[currentPageIndex + 1]?.name);
    return this.pages.value[currentPageIndex + 1]?.name ?? this.pages.value[currentPageIndex]?.name;
  }

  private buildQueryParams(page: string, customQuery: { [key: string]: string } = {}) {
    let query = '';
    if ((page.toLowerCase().includes('location') || page.toLowerCase().includes('building')) && this._currentLocation.value?.locationNumber) {
      query = `?locationNumber=${this._currentLocation.value.locationNumber}`;
    }
    if (page.toLowerCase().includes('building') && this._currentBuilding.value?.buildingNumber) {
      query += `&buildingNumber=${this._currentBuilding.value?.buildingNumber}`;
    }
    const section = localStorage.get('sectionTab', -1);
    if (section >= 0) {
      query += `${(query.length ? '&' : '?')}section=${section}`;
    }
    customQuery = customQuery ?? {};
    const queryKeys = Object.keys(customQuery);
    if (queryKeys.length) {
      query = query + (query.length ? '&' : '?') + queryKeys.map(key => `${key}=${customQuery[key]}`).join('&');
    }

    return query;
  }

  async getQuestionScript(page: string, section: string, question: string): Promise<string> {
    return await Interpreter.Download(
      `assets/scripts/${QuotingService.quote.productAbv.toLowerCase()}/${page}/questions/${question}.rule`,
      this.http);
  }
  async getPageSectionQuestion<T extends GenericQuestion>(page: string, section: string, question: string, customQuery: { [key: string]: string } = {}): Promise<T> {

    return this.http.get<T>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${page}/${section}/${question}${this.buildQueryParams(page, customQuery)}`).pipe(
      map(ques => MapQuestion(ques) as T),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async getPageSection(page: string, section: string, customQuery: { [key: string]: string } = {}): Promise<QuestionPageSection> {

    return this.http.get<QuestionPageSection>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${page}/${section}${this.buildQueryParams(page, customQuery)}`).pipe(
      map(ques => new QuestionPageSection(ques)),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async getPage(page: string, customQuery: { [key: string]: string } = {}): Promise<QuestionPage> {

    return this.http.get<QuestionPage>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${page}${this.buildQueryParams(page, customQuery)}`).pipe(
      map(pageData => {
        return  new QuestionPage(pageData);
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async saveQuote() {
    // await (await this.productLogic).service.saveQuote(QuotingService.quote);
  }

  private handleQuoteNotifs(statusIfNoError: QuotePageStatus, silentErrors = false) {
    const errorAmnt = QuotingService._quote.notifications.filter(n => n.type === 'error').length;
    const nonErrorAmnt = QuotingService._quote.notifications.filter(n => n.type !== 'error').length;

    if (errorAmnt && !silentErrors) {
      // this.pageStatus.next(QuotePageStatus.Error);
      this.uiSvc.error('Unable to Proceed',
        `
          <li style="list-style: none">${QuotingService._quote.notifications.map(n => {
          return `<ul><p class="paragraph"> ${errorAmnt === 1 ? '' : '<strong>-</strong>'} ${ProcessLookupQuery(n.message, QuotingService.PageForm)}</p></ul>`;
        }).join('')}</li>
          `);
      // return;
    } else if (nonErrorAmnt) {
      this.uiSvc.info('Notification'.plurality(QuotingService._quote.notifications) + ` For Page`,
        `
          <li style="list-style: none">${QuotingService._quote.notifications.filter(n => n.type !== 'error').map(n => {
          return `<ul><p class="paragraph"> ${nonErrorAmnt === 1 ? '' : '<strong>-</strong>'} ${ProcessLookupQuery(n.message, QuotingService.PageForm)}</p></ul>`;
        }).join('')}</li>
          `);
    }
    this.pageStatus.next(statusIfNoError);
  }


  get pageDataHasChanged() {
    if (!this.previousPageData) {
      this.previousPageData = {};
      Object.assign(this.previousPageData, QuotingService.PageForm.value);
      return false;
    }
    if (!QuotingService.PageForm.dirty) {
      return false;
    }
    // console.group('Equalsing', this.previousPageData, QuotingService.currentPageForm.value);
    const same = Object.propsEqual(this.previousPageData, QuotingService.PageForm.value);
    // console.groupEnd();
    // console.log(same);
    return !same;
  }

  async beforeSavePage(pageData: any, isPatch: boolean, customQuery?: { [key: string]: string }) {
    const productSvc = QuotingService.ProductLogicMap[QuotingService.quote.productAbv];
    if (productSvc) {
      if (!this.allProductLogic[this.product]) {
        this.allProductLogic[this.product] = this.injector.get<LogicService>(productSvc);
      }
      await this.allProductLogic[this.product].events.beforePageSave(QuotingService.quote, this._currentPage.value, pageData, isPatch);
    }
  }
  async savePage(pageData: any, silentErrors = false, customQuery?: { [key: string]: string }) {
    if (!this.pageName || this.pageStatus.value === QuotePageStatus.Saving) {
      return;
    }
    const oldStatus = this.pageStatus.value;
    if (pageData) {
      log.log('Saving: ', this.pageName, pageData);
      const productSvc = QuotingService.ProductLogicMap[QuotingService.quote.productAbv];
      if (productSvc) {
        if (!this.allProductLogic[this.product]) {
          this.allProductLogic[this.product] = this.injector.get<LogicService>(productSvc);
        }
        await this.allProductLogic[this.product].events.onPageSave(QuotingService.quote, this._currentPage.value, pageData, false);
      }
      const page = this._currentPage.value.name;
      // if ((page.toLowerCase().includes('location') || page.toLowerCase().includes('building')) && this._currentLocation.value?.locationNumber) {
      //   pageData.address = pageData.address ?? this._currentLocation.value.address;
      // }
      // if (page.toLowerCase().includes('building') && this._currentBuilding.value?.buildingNumber) {
      //   pageData.buildingNumber = pageData.buildingNumber ?? this._currentBuilding.value?.buildingNumber;
      // }

      this.pageStatus.next(QuotePageStatus.Saving);
      try {
        const quoteResp = await this.http.post<IQuote>(
          `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${this.pageName}${this.buildQueryParams(this.pageName, customQuery)}`, pageData).pipe(
          catchError((error: HttpErrorResponse) => {
            throw NetworkingUtils.CatchNetworkErrors(error);
          })).toPromise();
        QuotingService._quote.notifications = quoteResp.notifications;
        QuotingService._quote.premium = quoteResp.premium;
        QuotingService._quote.policyNumber = quoteResp.policyNumber;

        localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);

        this.handleQuoteNotifs(oldStatus, silentErrors);
      } catch (e) {
        this.pageStatus.next(oldStatus);
        throw e;
      }
    }
    // this.pageStatus.next(QuotePageStatus.Ready);
  }


  async savePageSectionQuestion(section: string, question: string, data: any, customQuery?: { [key: string]: string }) {
    const oldStatus = this.pageStatus.value;
    const queryParams = this.buildQueryParams(this.pageName, customQuery);
    const resp = await this.http.put<QuestionSaveResponse>
    (`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${this.pageName}/${section}/${question}${queryParams}`, data)
      .pipe(
        tap(respData => {
          if (respData.reload) {
            Object.keys(respData.reload.questions).forEach(name => {
              respData.reload.questions[name] = MapQuestion(respData.reload.questions[name]);
            });
          }
          return respData;
        }),
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })).toPromise();
    QuotingService._quote.notifications = resp.notifications;
    localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
    this.handleQuoteNotifs(oldStatus);
    return resp;
  }

  async savePageSection(section: string, pageData: any, isPartial: boolean, customQuery?: { [key: string]: string }) {
    if (!pageData) {
      this.pageStatus.next(QuotePageStatus.Ready);
      return;
    }

    const query = this.buildQueryParams(this.pageName, customQuery);

    const oldStatus = this.pageStatus.value;
    this.pageStatus.next(QuotePageStatus.Saving);
    try {

      if (isPartial) {
        await this.http.patch
        (`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${this.pageName}/${section}${query}`, pageData).pipe(
          catchError((error: HttpErrorResponse) => {
            throw NetworkingUtils.CatchNetworkErrors(error);
          })).toPromise();
      } else {
        const notifications = await this.http.post<QuoteNotification[]>
        (`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${this.pageName}/${section}${query}`, pageData).pipe(
          catchError((error: HttpErrorResponse) => {
            throw NetworkingUtils.CatchNetworkErrors(error);
          })).toPromise();

        QuotingService._quote.notifications = notifications;
        localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
        this.handleQuoteNotifs(oldStatus);
      }
    } catch (e) {
      this.pageStatus.next(oldStatus);
      throw e;
    }
  }

  async updatePage(pageData: any, isExiting: boolean, customQuery?: { [key: string]: string }) {
    if (!this.pageName || this.pageStatus.value === QuotePageStatus.Saving) {
      return;
    }
    const query = this.buildQueryParams(this.pageName, customQuery);
    if (pageData) {
      const oldStatus = this.pageStatus.value;

      const productSvc = QuotingService.ProductLogicMap[QuotingService.quote.productAbv];
      if (productSvc) {
        if (!this.allProductLogic[this.product]) {
          this.allProductLogic[this.product] = this.injector.get<LogicService>(productSvc);
        }
        await this.allProductLogic[this.product].events.onPageSave(QuotingService.quote, this._currentPage.value, pageData, true);
      }


      this.pageStatus.next(QuotePageStatus.Saving);

      try {
        const quoteResp = await this.http.patch<IQuote>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/pages/${this.pageName}${query}`, pageData).pipe(
          catchError((error: HttpErrorResponse) => {
            throw NetworkingUtils.CatchNetworkErrors(error);
          })).toPromise();
        if (isExiting) {
          this.pageStatus.next(QuotePageStatus.Ready);
          return;
        }
        QuotingService._quote.notifications = quoteResp.notifications;
        QuotingService._quote.premium = quoteResp.premium;
        QuotingService._quote.policyNumber = quoteResp.policyNumber;

        localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
        if (!isExiting) {
          this.handleQuoteNotifs(oldStatus);
        }
      } catch (e) {
        this.pageStatus.next(oldStatus);
        throw e;
      }
    }
    this.pageStatus.next(QuotePageStatus.Ready);
  }

  async reloadPage(skipPageGet: boolean = false) {

    // this._questionPage.next(null);
    const page = this.pages.value.find(p => p.name === this.pageName);
    this.pageName = null;
    await this.tryLoadPage(page.name, skipPageGet);
    // this.questionSvc.pageActions().next({});
    // this._questionPage.next(await this.getPage(this.pageName));
  }

  async tryLoadPage(pageName: string, skipPageGet: boolean = false) {
    if (pageName === this.pageName) {
      const page = this.pages.value.find(p => p.name === pageName);
      if (page) {
        return;
      }
    }

    assert(this.pageStatus.value !== QuotePageStatus.Saving && this.pageStatus.value !== QuotePageStatus.Loading, `Attempt to load page ${pageName} while ine ${this.pageStatus.value}`);

    let title: string;
    let msg: string;
    if (!this.pages.value?.length) {
      title = 'Loading Quote';
      msg = 'Please wait while we load this quote';
    } else {
      const nextPage = this.pages.value.find(p => p.name === pageName);
      title = `Loading ${nextPage.title}`;
      msg = `Please wait while we load the page`;
    }
    const dimLock = this.uiSvc.dimLock(title, msg);
    this.pageStatus.next(QuotePageStatus.Loading);

    if (!this.pages.value?.length || !skipPageGet) {
      await this.getPages();
    }
    const pages = this.pages.value;


    const targetPageIndex = pages.findIndex(p => p.name === pageName);
    const apiTargetIndex = pages.findIndex(p => p.current);


    const navCur = pages[targetPageIndex < apiTargetIndex ? targetPageIndex : apiTargetIndex];

    pages.forEach((page, i) => page.current = navCur ? navCur.name === page.name : i === 0);

    const newPage = pages.find(p => p.current);


    let apiPage: QuestionPage;
    try {
      apiPage = await this.getPage(newPage.name);
    } catch (e) {
      apiPage = this.pages.value.find(p => p.name === pageName);
      this.uiSvc.error(`Error Loading ${newPage.title}`, e);
    }
    let sub: Subscription = new Subscription();
    sub = this.pageStatus.subscribe(async s => {
      if (s === QuotePageStatus.Ready) {
        await this.uiSvc.undim(dimLock);
        sub.unsubscribe();
        const productSvc = QuotingService.ProductLogicMap[QuotingService.quote.productAbv];
        if (productSvc) {
          if (!this.allProductLogic[this.product]) {
            this.allProductLogic[this.product] = this.injector.get<LogicService>(productSvc);
          }
          await this.allProductLogic[this.product].events.onPageLoad(QuotingService.quote, this._currentPage.value);
        }
      }
    });
    QuotingService.PageForm = new FormGroup({});
    QuotingService.InterpretCfg.lookups.parent = QuotingService.PageForm;
    QuotingService.InterpretCfg.lookups.meta['page'] = apiPage;
    this.pageName = apiPage.name;
    this.pages.next(pages);

    this._currentPage.next(apiPage);
  }


  exitQuote() {
    this.pages.next([]);
    QuotingService._quote = null;
    this.pageName = null;

    Object.keys(localStorage).forEach(key => {
      if (key !== StorageKeys.Token) {
        localStorage.removeItem(key);
      }
    });

    this.locationNumber = 0;
    this.buildingNumber = 0;
    this.initSubjects();
  }


  async getLocations<T extends ILocation>(): Promise<T[]> {
    return this.http.get<T[]>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations`).pipe(
      tap(locations => {
        QuotingService.quote.locations = locations;
        this.checkCurrentLocations();
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async getDeductible(locationNumber: number, excludeWind: boolean = null): Promise<QuestionOption<string>[]> {
    const excludeWindStr = excludeWind != null ? `?excludeWind=${excludeWind}` : '';
    return this.http.get<{ fieldName: string, value: string }[]>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${locationNumber}/deductible${excludeWindStr}`)
      .pipe(
        map(options => {
          return options.map(o => ({
            text: o.fieldName,
            value: o.value
          } as QuestionOption<string>));
        }),
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })
      )
      .toPromise();
  }

  async getLocation<T extends ILocation>(locationNumber: number): Promise<T> {
    return this.http.get<T>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${locationNumber}`).pipe(
      tap((location) => {
        const index = QuotingService.quote.locations.findIndex(l => l.locationNumber === locationNumber);
        QuotingService.quote.locations[index] = location;
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  // async addLocation(location: Address): Promise<Address> {
  //   return this.http.post<Address>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations`, location).toPromise();
  // }


  private checkCurrentLocations() {
    QuotingService.quote.locations = QuotingService.quote.locations ?? [];
    const location = QuotingService.quote.locations.find(l => l.locationNumber === this.locationNumber);
    if (!location) {
      this.loadInLocation(0);
    } else if (!location.buildings?.find(b => b.buildingNumber === this.buildingNumber)) {
      this.loadInLocation(location.locationNumber, 1);
    }
    // this.currentLocationNumber = this.locationNumber;
    // this.currentBuildingNumber = this.buildingNumber;
  }


  async deleteLocation(location: ILocation | number) {
    if (typeof location !== 'number') {
      location = location.locationNumber;
    }
    return this.http.delete(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${location}`).pipe(
      tap(() => {
        const locationIndex = QuotingService.quote.locations.findIndex(l => l.locationNumber === location);
        QuotingService.quote.locations.splice(locationIndex, 1);
        this.checkCurrentLocations();
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async saveLocation<T extends ILocation>(location: ILocation): Promise<T> {
    if (!QuotingService.quote.locations) {
      QuotingService.quote.locations = [];
    }
    if (!location.locationNumber) {
      return this.http.post<T>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations`, location).pipe(
        tap(loc => {
          QuotingService.quote.locations.push(loc);
          this.loadInLocation(loc.locationNumber);
        }),
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })).toPromise();
    } else {
      return this.http.put<T>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${location.locationNumber}`, location).pipe(
        tap(loc => {
          if (loc.locationNumber === this.locationNumber) {
            const index = QuotingService.quote.locations.findIndex(l => l.locationNumber === loc.locationNumber);
            QuotingService.quote.locations[index] = loc;
            this.loadInLocation(loc.locationNumber, this.buildingNumber);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })).toPromise();
    }

  }

  async addBuilding(location: ILocation, description: string): Promise<IBuilding> {
    return this.http.post<IBuilding>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${location.locationNumber}/buildings`, {description}).pipe(
      tap(building => {
        this._currentLocation.value.buildings.push(building);
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async cloneBuilding(location: ILocation, buildingNumber: number, description: string): Promise<IBuilding> {
    return this.http.put<IBuilding>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${location.locationNumber}/buildings/${buildingNumber}`,
      {description}).pipe(
      tap(building => {
        this._currentLocation.value.buildings.push(building);
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async deleteBuilding(location: ILocation, buildingNumber: number): Promise<void> {
    return this.http.delete<void>(
      `${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/locations/${location.locationNumber}/buildings/${buildingNumber}`).pipe(
      tap(() => {
        const buildingIndex = QuotingService.quote.locations.find(l => l.locationNumber === location.locationNumber).buildings.findIndex(b => b.buildingNumber === buildingNumber);
        QuotingService.quote.locations.find(l => l.locationNumber === location.locationNumber).buildings.splice(buildingIndex, 1);
        this.checkCurrentLocations();
      }),
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();
  }

  async updateEffectiveDate(newEffectiveDate: Date, newTermLength?: number, skipSave = false) {
    if (!skipSave) {
      await this.updatePage(QuotingService.PageForm?.value, true);
    }
    const updatedQuoteToken = await this.http.put<any>(`${environment.endpoints.policy}/quote/${QuotingService.quote.quoteNumber}/effectivedate`,
      {date: newEffectiveDate, term: newTermLength}).pipe(
      catchError((error: HttpErrorResponse) => {
        throw NetworkingUtils.CatchNetworkErrors(error);
      })).toPromise();

    QuotingService._quote.effectiveDate = newEffectiveDate;
    QuotingService._quote.termLength = newTermLength;
    localStorage.set(StorageKeys.CurrentQuote, QuotingService._quote);
    localStorage.set(StorageKeys.CurrentQuoteToken, updatedQuoteToken.jwt);

    const page = QuotingService.quote.productAbv === Product.BuildersRisk.toUpperCase() ? Pages.Applicant : Pages.Eligibility;

    if (this.pageName === page) {
      await this.reloadPage(false);
    } else {
      await this.tryLoadPage(page, false);
    }
  }

  async getAccess() {
    const access = (await this.http.get<{
      productAbv: string, product: string, sublineAbv: string, subline: string, stateAbv: string, state: string, stateCode: string | number,
      effectiveDate: Date, effectiveMinDate: Date | null, effectiveMaxDate: Date | null
    }[]>(`${environment.endpoints.policy}/agent/products`).toPromise());
    access.forEach(a => {
      a.stateCode = +a.stateCode;
      a.effectiveDate = new Date(a.effectiveDate as unknown as string);
      a.effectiveMinDate = a.effectiveMinDate ? new Date(a.effectiveMinDate as unknown as string) : null;
      a.effectiveMaxDate = a.effectiveMaxDate ? new Date(a.effectiveMaxDate as unknown as string) : null;
    });
    return access;
  }

  static readonly InterpretCfgWithForm = (form?: FormGroup, meta?: {[key: string]: any}, self?: any): InterpretCfg => {
    const cfg = {...QuotingService.InterpretCfg};
    cfg.lookups = {...cfg.lookups};
    cfg.lookups.meta = {...cfg.lookups.meta, ...(meta ?? {})};
    cfg.lookups.parent = form ?? cfg.lookups.parent;
    cfg.self = self;
    return cfg;
  }
  static readonly InterpretCfg: InterpretCfg = {
    lookups: {
      parent: QuotingService.PageForm,
      global: QuotingService.quote,
      meta: {page: {}}
    },
    actions: {
      log: async (interpreter: Interpreter, ...args: NodeDataType[]) => console.log(...(args).map(a => {
        // return (a instanceof AbstractControl) ? a.value : a;
        return a;
      })),
      last: async (interpreter: Interpreter, args: any[]) => {
        return args[args.length - 1] ?? null;
      },
      lookup: async (interpreter: Interpreter, pathNode: any, pathNode2?: any) => {
        if (typeof pathNode === 'string') {
          pathNode = interpreter.execVar({
            type: NodeType.Var,
            target: pathNode,
            props: [],
            isSelf: false,
          });
        }
        if (pathNode2) {
          if (typeof pathNode2 !== 'string' && typeof pathNode2 !== 'number') {
            pathNode2 = interpreter.execVar({
              type: NodeType.Var,
              target: pathNode2,
              props: [],
              isSelf: false,
            });
          }
          pathNode = pathNode[pathNode2];
        }

        return pathNode;
      },
      toggleVis: async (interpreter: Interpreter, ctrl: any, shouldShow: boolean) => {
        if (ctrl instanceof AbstractControl) {
          const component = ctrl.component;
          component.hidden = !shouldShow;
          return;
        }
        ctrl.hidden = !shouldShow;
      },
      hide: async (interpreter: Interpreter, ctrl: any) => {

        if (ctrl instanceof AbstractControl) {
          const component = ctrl.component;
          component.hidden = true;
          return;
        }
        ctrl.hidden = true;
      },
      show: async (interpreter: Interpreter, ctrl: any) => {
        if (ctrl instanceof AbstractControl) {
          const component = ctrl.component;
          component.hidden = false;
          return;
        }
        ctrl.hidden = false;
      },
      disable: async (interpreter: Interpreter, ctrl: AbstractControl, shouldDisable?: boolean) => {
        const component = ctrl.component;
        component.disabled = shouldDisable ?? false;
      },
      call: async (interpreter: Interpreter, func: (...args: any[]) => any, ...args: NodeDataType[]) => {
        // @ts-ignore
        const result = func.call(...args);
        if (result instanceof Promise) {
          return await result;
        }
        return result;
      },
      require: async (interpreter: Interpreter, ctrl: AbstractControl, require: boolean) => {
        const component = ctrl.component;
        (component as QuestionComponent)?.setRequired?.(require);
      },
      concat: async (interpreter: Interpreter, ...args: NodeDataType[]) => {
        return args.join('');
      },
      format: async (interpreter: Interpreter, val: any, type: string) => {
        val = val?.value ?? val;
        type = type.toLowerCase().trim();
        if (type === 'numeric') {
          if (val === null || val === undefined) {
            return '';
          }
          return new DecimalPipe('en-us').transform(val);
        }
        if (type === 'currency') {
          if (val === null || val === undefined) {
            return '';
          }
          if (typeof val === 'string' && val.startsWith('$')) {
            return val;
          } else {
            return new CurrencyPipe('en-us').transform(val, 'USD', 'symbol', '1.0-0');
          }
        }
        throw new Error(`Format ${type} not supported`);
      },
      select: async (interpreter: Interpreter, arr: any[], propPath: string) => {
        return  arr.map(v => {
          propPath.split('.').forEach(p => {
            v = v[p];
          });
          return v;
        });
      }
      // makeTitle: async (...args: NodeDataType[]) => {
      //   const ctrl = args[0] as AbstractControl;
      //   ctrl.question.t
      // },
    }
  };
}


interface ProductLogicData {
  service?: LogicService;
  // factories: {
  //   [pageName: string]: {
  //     page?: ComponentFactory<LogicComponent>;
  //     sections?: {
  //       [sectionName: string]: ComponentFactory<LogicComponent>;
  //     }
  //   };
  // };
  // instances: {
  //   [pageName: string]: {
  //     page?: ComponentRef<LogicComponent>;
  //     sections?: {
  //       [sectionName: string]: ComponentRef<LogicComponent>;
  //     }
  //   };
  // };
}


export enum QuotePageStatus {
  Untouched,
  Ready,
  Loading,
  Saving,
  Valid,
  InValid,
  // Error,
  Warning
}

// @ts-ignore
function download(filename: string, blob: Blob) {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
}
