// tslint:disable-next-line:only-arrow-functions
String.prototype.replaceAll = function(find: string, replace: string): string {
  find = find.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  return this.replace(new RegExp(find, 'g'), replace);
};

// tslint:disable-next-line:only-arrow-functions
String.prototype.removeAll = function(find: string): string {
  find = find.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  return this.replace(new RegExp(find, 'g'), '');
};

// tslint:disable-next-line:only-arrow-functions
String.prototype.toId = function(): string {
  const onlyAlphaNumeric = this.replace(/[^a-z0-9\s-]/ig, '');
  // Change Camel/Pascal case to kebab case
  return onlyAlphaNumeric.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-');
};

String.prototype.toProperCase = function(): string {
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

String.prototype.toCamelCase = function(): string {
  const proper = this.toProperCase().padStart(1, ' ');

  return proper[0].toLowerCase() + proper.substr(1);
};

String.prototype.plurality = function(lengthOrArr): string {

  if (Array.isArray(lengthOrArr)) {
    lengthOrArr = lengthOrArr.length;
  }
  return this + (lengthOrArr === 1 ? '' : 's');
};

String.prototype.removeSuffix = function(suffix): string {
  const len = suffix.length;
  return this.substr(0, this.length - len);
};
