<div id="sidenav" fxLayout="column" fxLayoutAlign="space-between start">
  <ng-container [ngTemplateOutlet]="!currPage?.subPages?.length ? mainNav : subNav"></ng-container>
  <!--  <ng-container *ngIf="currPage" [ngTemplateOutlet]="mainNav"></ng-container>-->
  <ul id="sidenav-bottom">
    <li>
      <!--      <span class="quoting-pageName-item" (click)="saveAndExit()"><mat-icon>exit_to_app</mat-icon>Save & Exit Quote</span>-->
    </li>
  </ul>
</div>


<ng-template #mainNav>
  <ul fxFlex [@slideInOutMain]>
    <li *ngFor="let page of navSvc.pages | async" [routerLink]="page.navLink" (click)="navClicked(page)"
        class="page-item" [class.disabled]="page.isAccordion && !page.subPages">
      <ng-container *ngIf="!page.isAccordion">
        <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ page: page }"></ng-container>
      </ng-container>
      <mat-expansion-panel *ngIf="page.isAccordion" class="inline">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ page: page }"></ng-container>
          </mat-panel-title>
          <ul>
            <li *ngFor="let subPage of page.subPages" [routerLink]="subPage.navLink" (click)="navClicked(subPage)"
                class="page-item">
              <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ page: subPage }"></ng-container>
          </ul>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </li>
  </ul>
</ng-template>

<ng-template #pageNameDisplay let-page="page">
  <span><mat-icon *ngIf="page.navIcon">{{page.navIcon}}</mat-icon>{{page.navTitle}}</span>
</ng-template>

<ng-template #subNav>
  <ul fxFlex [@slideInOutSub]>

    <li class="page-item" (click)="currPage = null">
      <mat-icon class="u-font-white">arrow_back</mat-icon>
      <span class="nav-caption">Main Menu</span>
    </li>

    <mat-expansion-panel class="inline" [hideToggle]="!page.isAccordion"
                         *ngFor="let page of (currPage?.subPages || [])"
                         [routerLink]="page.isAccordion ? null : page.navLink"
                         [disabled]="!page.isAccordion"
                         [expanded]="currPage.navLink === page.navLink">
      <mat-expansion-panel-header class="page-item">
        <mat-panel-title>
          <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ page: page }"></ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <li *ngFor="let subPage of page.subPages" [routerLink]="subPage.navLink" class="sub-page-item">
          <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ page: subPage }"></ng-container>
      </ul>
    </mat-expansion-panel>

<!--    <li *ngFor="let pageName of (currPage?.subPages || [])" [routerLink]="pageName.navLink" class="pageName-item"-->
<!--        [class.pageName-item-compact]="!pageName.navIcon">-->

<!--&lt;!&ndash;      <ng-container *ngIf="!pageName.isAccordion">&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ pageName: pageName }"></ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;      </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;      <mat-expansion-panel *ngIf="pageName.isAccordion" class="inline">&ndash;&gt;-->
<!--      <mat-expansion-panel class="inline" [hideToggle]="!pageName.isAccordion">-->
<!--        <mat-expansion-panel-header>-->
<!--          <mat-panel-title>-->
<!--            <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ pageName: pageName }"></ng-container>-->
<!--          </mat-panel-title>-->
<!--        </mat-expansion-panel-header>-->
<!--          <ul>-->
<!--            <li *ngFor="let subPage of pageName.subPages" [routerLink]="subPage.navLink" class="pageName-item">-->
<!--              <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ pageName: subPage }"></ng-container>-->
<!--          </ul>-->
<!--      </mat-expansion-panel>-->

<!--&lt;!&ndash;      <ng-container [ngTemplateOutlet]="pageNameDisplay" [ngTemplateOutletContext]="{ pageName: pageName }"></ng-container>&ndash;&gt;-->
<!--    </li>-->
  </ul>
</ng-template>
