import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UserService} from '../../user/user.service';
import {Router} from '@angular/router';
import {Environments} from '../../../environments/base-environment';
import {StorageKeys} from '../../shared/extensions/storage.extension';
import * as jwtDecode from 'jwt-decode';
import {UiService} from '../../shared/ui.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() sideNavToggle = new EventEmitter<void>();

  notifications: Notification[];
  hasNewNotification = false;
  showBeta = false;
  isLocal = false;
  public readonly UserService = UserService;
  public readonly UiService = UiService;

  constructor(private userSvc: UserService, private router: Router) { }

  ngOnInit() {
    this.notifications = [];
    this.hasNewNotification = this.notifications.some(notification => {
      // return notification.hasRead === false;
    });

    // (ignore 'This condition will always return' compile error as environment is switched out on build)
    // @ts-ignore
    this.isLocal = environment?.name === Environments.Local;
  }

  toggleNav() {
    this.sideNavToggle.emit();
  }

  logout() {
    if (UserService.isBridgedAgent) {
      this.router.navigate(['maverick']);
    } else {
      this.userSvc.logout();
      this.router.navigate(['auth']);
    }
  }

  markNotifsRead() {
    this.hasNewNotification = false;
  }

  get agencyDetailsHtml() {
    const authToken = localStorage.get(StorageKeys.Token);
    if (authToken) {
      const decoded: any = jwtDecode(authToken);
      return `Agent#: ${decoded.AgentNumber} \n Agent Name: ${decoded.AgentFirstName} ${decoded.AgentLastName}`;
    } else {
      return ``;
    }
  }

}
