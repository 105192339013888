import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user/user.service';
import {UiService} from '../../shared/ui.service';

@Component({
  selector: 'app-maverick',
  templateUrl: './maverick.component.html',
  styleUrls: ['./maverick.component.scss']
})
export class MaverickComponent implements OnInit {

  constructor(private userSvc: UserService, private uiSvc: UiService) { }

  ngOnInit(): void {
    this.uiSvc.dim('Redirecting', 'Redirecting to Maverick Insurance and Warranties');
    this.userSvc.logout();
    this.uiSvc.undim();
    window.location.href = 'https://www.maverickbuilders.com';
  }

}
