import { Component, OnInit, Renderer2 } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {StorageKeys} from '../../extensions/storage.extension';
import {UiService} from '../../ui.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  private chatScript: HTMLScriptElement;
  private updatedIcon = false;
  public readonly UiService = UiService;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    /*
    if (environment.name === 'local') {
      return;
    }
    */

    this.chatScript = this.renderer.createElement('script');

    this.chatScript.type = 'text/javascript';

    this.chatScript.charset = 'utf-8';
    this.chatScript.innerHTML = `
     var seGEQ5=document.createElement('script');
    seGEQ5.type='text/javascript';
    var seGEQ5s=(location.protocol.indexOf('https')==0?'https://secure.providesupport.com/image':'http://image.providesupport.com')
    +'/js/bankers/safe-standard.js?ps_h=GEQ5\u0026ps_t='+new Date().getTime();
    setTimeout('seGEQ5.src=seGEQ5s;if (document.getElementById(\"sdGEQ5\")) {document.getElementById(\"sdGEQ5\").appendChild(seGEQ5)}',1000) `
    ;

    const chatrIconNode = document.querySelector('#scGEQ5');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation: MutationRecord) => {
        for (let i = 0; i < mutation.addedNodes.length; i++) {
          // @ts-ignore
          if (!this.updatedIcon && mutation.addedNodes.item(i).localName === 'a') {
            const childElement: Element = chatrIconNode.getElementsByTagName('img').item(0);
            mutation.addedNodes.item(i).removeChild(childElement);
            // this.updatedIcon = true;
            chatrIconNode.getElementsByTagName('a').item(0).insertAdjacentHTML('beforeend',
              '<div _ngcontent-c10=""' +
              'class="chat-icon mat-fab mat-warn"' +
              'color="warn"' +
              'mat-fab="" target="#" onclick="return false;"' +
              'ng-reflect-color="warn"' +
              'tabindex="0"' +
              'aria-disabled="false">' +
              '<span class="mat-button-wrapper">' +
              '<mat-icon _ngcontent-c10="" class="mat-icon material-icons" role="img" aria-hidden="true">headset_mic</mat-icon>' +
              '</span>' +
              '<div class="mat-button-ripple mat-ripple mat-button-ripple-round"' +
              ' matripple="" ng-reflect-centered="false" ng-reflect-disabled="false">' +
              '</div>' +
              '<div class="mat-button-focus-overlay"></div></div>');
          }
        }

      });
    });
    observer.observe(chatrIconNode, {
      attributes: true,
      childList: true,
      characterData: true
    });


    this.renderer.appendChild(document.body, this.chatScript);
  }

}
