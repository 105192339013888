import * as assert from 'assert';

type TaggedLogFunc = (tag: string, ...data: any[]) => void;
type LogFunc = (...data: any[]) => void;

const ErrorOverrides = ['ERROR', 'Unhandled Error', 'AssertionError', 'Unhandled Promise', 'enableProdMode'];

export interface ILogger {
  readonly log: LogFunc;
  readonly error: LogFunc;
  readonly warn: LogFunc;
  readonly group: LogFunc;
  readonly groupCollapsed: LogFunc;
  readonly groupEnd: LogFunc;
}

export class Logger implements ILogger {

  public readonly log = (...data: any[]) => !this.silenced ? LoggerService.Log(this.tag, ...data) : () => {};
  public readonly error = (...data: any[]) => !this.silenced ? LoggerService.Error(this.tag, ...data) : () => {};
  public readonly warn = (...data: any[]) => !this.silenced ? LoggerService.Warn(this.tag, ...data) : () => {};
  public readonly group = (...data: any[]) => !this.silenced ? LoggerService.Group(this.tag, ...data) : () => {};
  public readonly groupCollapsed = (...data: any[]) => !this.silenced ? LoggerService.GroupCollapsed(this.tag, ...data) : () => {};
  public readonly groupEnd: () => void = () => !this.silenced ? originalConsole.groupEnd() : () => {};
  // public meta: {[key: string]: any} = {};
  private _tag: string;

  public silenced = false;

  public get tag() {
    return this._tag;
  }

  public constructor(tag: string) {
    this._tag = tag;
  }

  public changeTag(tag: string, style: string|string[] = null) {
    Object.apply(this, LoggerService.Register(tag, style));
    this._tag = tag;
  }


}

const originalConsole: Console = console;

export abstract class LoggerService {

  private static Tags: string[][] = [];
  private static ForceTags = false;
  private static Logs: { [tag: string]: any[] } = {};
  private static originalAssert: (value: any, message?: string | Error) => void;

  static Register(tag: string, style: string|string[] = null) {
    style = style ?? '';
    if (style instanceof Array) {
      style = style.join(';');
    }
    const tagIndex = LoggerService.Tags.findIndex(t => t[0] === tag);
    if (tagIndex !== -1) {
      LoggerService.Tags[tagIndex] = [tag, style];
    } else {
      LoggerService.Tags.push([tag, style]);
    }
    return new Logger(tag);
  }

  static Override(forceTags?: boolean) {

    LoggerService.ForceTags = forceTags;
    LoggerService.Tags.push(['Unhandled Error', 'background-color: white; color: red']);

    // const _ = new ConsoleOverride(originalConsole);
  }

  static Count(tag: string) {
    return LoggerService.Logs[tag]?.length ?? 0;
  }

  private static TryOverride(func: TaggedLogFunc, tag: string|Error, ...data: any[]) {
    const origTag = tag;
    if (tag instanceof Error) {
      data = [tag, ...data];
      tag = 'Unhandled Error';
    }
    // @ts-ignore
    tag = LoggerService.Tags.find(t => t[0] === tag) ?? tag;
    if (tag instanceof Array) {
      let colorTag = '';
      if (tag.length === 2) {
        colorTag = '%c';
        data.unshift(tag[1]);
      }

      func(`${colorTag}[${tag[0]}]`, ...data);
    } else {
      if (tag === 'ERROR') {
        return;
      }

      if (LoggerService.ForceTags && !ErrorOverrides.find(e => {
        if (origTag instanceof Error) {
          return ErrorOverrides.includes(origTag.name);
        } else {
          return (tag as string).includes(e);
        }
      })) {
        // We could do normal assert but this is useful for breakpoints to track down non-compliant logs
        const _ = false;
        assert(false, `Missing required console tag for ${tag}`);
      }
      func((tag as string), ...data);
    }
  }

  static Assert(value: any, message?: string | Error): void {
    if (!value) { // Used for assert breakpoint support
      const _ = false;
    }
    LoggerService.originalAssert(value, message);
  }

  static Group(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.group, tag, ...data);
  }

  static GroupCollapsed(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.group, tag, ...data);
  }

  static Log(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.log, tag, ...data);
  }

  static Warn(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.warn, tag, ...data);
  }

  static Debug(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.debug, tag, ...data);
  }

  static Exception(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.exception, tag, ...data);
  }

  static Error(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.error, tag, ...data);
  }

  static Info(tag: string, ...data: any[]) {
    LoggerService.TryOverride(originalConsole.info, tag, ...data);
  }

}


class ConsoleOverride implements Console {

  private originalConsole: Console = this;

  constructor(orig: Console) {
    this.originalConsole = orig;
    console = this;
  }
  memory: any = this.originalConsole.memory;

  assert: (value?: any, ...message: (any)[]) => void = this.originalConsole.assert;

  clear: () => void = this.originalConsole.assert;

  count: (label?: string) => void = this.originalConsole.count;

  countReset: (label?: string) => void = this.originalConsole.countReset;

  debug: (message?: any, ...optionalParams: any[]) => void = LoggerService.Debug;

  dir: (obj?: any, options?: any) => void = this.originalConsole.dir;

  dirxml: (...data: any[]) => void = this.originalConsole.dirxml;

  error: (message?: any, ...optionalParams: any[]) => void = LoggerService.Error;

  exception: (message?: string, ...optionalParams: any[]) => void = LoggerService.Exception;

  group: (...label: any[]) => void = this.originalConsole.group;

  groupCollapsed: (...label: any[]) => void = this.originalConsole.groupCollapsed;

  groupEnd: () => void = this.originalConsole.groupEnd;

  info: (message?: any, ...optionalParams: any[]) => void = LoggerService.Info;

  log: (message?: any, ...optionalParams: any[]) => void = LoggerService.Log;

  table: (tabularData?: any, properties?: string[]) => void = this.originalConsole.table;

  time: (label?: string) => void = this.originalConsole.time;

  timeEnd: (label?: string) => void = this.originalConsole.timeEnd;

  timeLog: (label?: string, ...data: any[]) => void = this.originalConsole.timeLog;

  timeStamp: (label?: string) => void = this.originalConsole.timeStamp;

  timelineEnd: (label?: string) => void = this.originalConsole.timeEnd;

  trace: (...message: (any)[]) => void = this.originalConsole.trace;

  warn: (...message: (any)[]) => void = LoggerService.Warn;

}
