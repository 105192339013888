export interface Address {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  inCityLimits?: string;
  verified?: string;
  county?: string;
}


export function TruthyAddress(address: Address) {
  return address.address1 && address.city && address.zip && address.state && address.state.length === 2;
}

export function CheckAddressMatch(address1: Address, address2: Address): boolean {
  if ((address1 && !address2) || (!address1 && address2)) {
    return false;
  }

  for (const pair of [
    [address1.address1, address2.address1],
    [address1.address2, address2.address2],
    [address1.city, address2.city],
    [address1.state, address2.state],
    [address1.zip, address2.zip],
  ]) {
    const first = pair[0];
    const seconds = pair[1];
    if (first?.trim()?.toLowerCase() !== seconds?.trim()?.toLowerCase()) {
      return false;
    }
  }
  return true;
}

export function FormatAddress2(address: Address, options?: {inline?: boolean, align?: 'left'|'right'}): string {
  if (!address) {
    return '';
  }
  if (!options || options.inline) {
    return FormatAddress(address, true);
  }
  options.align = options.align ?? 'left';

  address.address1 = address.address1.toProperCase();
  address.address2 = address.address2?.toProperCase();
  address.city = address.city.toProperCase();
  address.state = address.state.toUpperCase();

  return `<span class="text-${options.align}">${address.address1 ?? ''}${address.address2 ? `, ${address.address2}` : ''}</span><br/>
      <span class="text-${options.align}">${address.city ?? ''}, ${address.state ?? ''} ${address.zip ?? ''}</span>`;



}
export function FormatAddress(address: Address, inline?: boolean): string {
  if (!address) {
    return '';
  }
  if (inline === true) {
    return `${address.address1 ?? ''}${(address.address2 ? `, ${address.address2}` : '')},
      ${address.city ?? ''}, `.toProperCase() + `${address.state ?? ''} ${address.zip ?? ''}`.toUpperCase();
  }

  return `${address.address1 ?? ''}${(address.address2 ? `<br/>${address.address2}` : '')}<br/>
      ${address.city ?? ''}, `.toProperCase() + `${address.state ?? ''} ${address.zip ?? ''}`.toUpperCase();
}
