export enum Comparator {
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  AnythingBut = 'anythingBut',
  Valid = 'valid',
  Invalid = 'invalid',
  Changed = 'changed',
  True = 'true',
  False = 'true',
}
