import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import * as assert from 'assert';
import {Observable} from 'rxjs';
import {UiService} from '../../ui.service';
import {BaseComponent} from '../../models/base-component.interface';
const ARROW_PIXELS = 20;

@Component({
  selector: 'app-targeted-dialog',
  templateUrl: './targeted-dialog.component.html',
  styleUrls: ['./targeted-dialog.component.scss']
})
export class TargetedDialogComponent implements OnInit, AfterViewInit {

  // @Input('form') parentForm: TemplateRef<FormTemplateComponent>;
  // @Input('form2') parentForm2: ComponentRef<FormTemplateComponent>;
  private _config: ToolTipConfig;
  ready = false;
  private originalState: any[];

  @Output() closed = new EventEmitter<object | null>();


  @Input()
  set config(config: ToolTipConfig) {
    this._config = config;
    this.checkDimmed();
  }

  @Input() shouldDim = false;

  @ViewChild('wrapper', {static: true}) wrapper: ElementRef<HTMLDivElement>;

  get width(): number {
    return this.wrapper?.nativeElement.offsetWidth ?? 0;
  }

  get height(): number {
    return this.wrapper?.nativeElement.offsetHeight ?? 0;
  }


  get position(): { top?: number|string, left?: number|string, right?: number|string, bottom?: number|string } {


    let elemRef: HTMLElement;
    let shouldUseOffset = false;
    if (this.config.relativeToId) {
      elemRef = document.getElementById(this.config.relativeToId);
      shouldUseOffset = true;
    } else if (this.config.relativeToRef) {
      elemRef = this.config.relativeToRef.elementRef.nativeElement;
      shouldUseOffset = true;
    } else if (this.config.relativeToElem) {
      // debugger
      elemRef = (this.config.relativeToElem as BaseComponent)?.elementRef?.nativeElement ?? this.config.relativeToElem;
      shouldUseOffset = true;
    }
    if (!elemRef) {
      elemRef = this.ref.nativeElement;
    }
    const elemTop = elemRef.offsetTop;
    const elemBottom = elemRef.offsetTop + elemRef.offsetHeight;
    const elemHeight = elemRef.offsetHeight;
    const elemWidth = elemRef.offsetWidth;
    const elemLeft = elemRef.offsetLeft;
    const elemRight = elemRef.offsetLeft + elemRef.offsetWidth;

    if (!this.config.visible) {
      return {top: `-${elemHeight}px`, left: `-${elemWidth}px`};
    }

    const style: { [pos: string]: number } = {};

    const leftValForLeft = elemLeft - ARROW_PIXELS - this.width;
    const leftValForVert = (elemLeft + elemRef.offsetWidth / 2) - (this.width / 2);
    const leftValForRight = elemRight + ARROW_PIXELS;
    if (shouldUseOffset) {

      // @ts-ignore
      window.test = this.wrapper.nativeElement;
      // @ts-ignore
      window.elemRef = elemRef;

      // console.log(leftValForRight, this.ref.nativeElement.offsetWidth, leftValForRight + (+this.ref.nativeElement.offsetWidth), window.innerWidth);
      if (this.config.side === 'left' && leftValForLeft < 0) {
        this.config.side = 'top';
      } else if (this.config.side === 'right' && leftValForRight + elemWidth > window.innerWidth) {
        this.config.side = 'top';
      }

      if (this.config.side === 'top') {
        style.top = elemTop - this.height - ARROW_PIXELS;
        style.left = (elemLeft + elemRef.offsetWidth / 2) - (this.width / 2);
      } else if (this.config.side === 'right') {
        style.top = (elemTop + elemHeight / 2) - (this.height / 2);
        style.left = elemRight + ARROW_PIXELS;
      } else if (this.config.side === 'left') {
        style.top = elemTop + elemRef.offsetHeight / 2 - this.height / 2;
        style.left = elemLeft - ARROW_PIXELS - this.width;
      } else if (this.config.side === 'bottom') {
        style.top = elemBottom + ARROW_PIXELS;
        style.left = (elemLeft + elemRef.offsetWidth / 2) - (this.width / 2);
      }
    }

    Object.keys(style).forEach(pos => {
      const pixels = style[pos];
      delete style[pos];
      style[pos + '.px'] = pixels;
    });
    // console.log(style);
    return style;

  }

  @Input()
  set relativeToElement(ref: BaseComponent | HTMLElement) {
    this.config.relativeToElem = ref;
  }

  @Input()
  set relativeToTemplate(ref: TemplateRef<any>) {
    this.config.relativeToRef = ref;
  }

  @Input()
  set relativeToId(id: string) {
    this.config.relativeToId = id;
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get config(): ToolTipConfig {
    if (!this._config) {
      this._config = {
        side: 'top',
        visible: false,
        dimmed: false,
      };
    }
    return this._config;
  }

  @Input()
  set visible(visible: Observable<boolean>) {
    visible.subscribe(vis => {
      if (vis === this.config.visible) {
        return;
      }

      this.config.visible = vis;

      // this._questions.forEach(q => {
      //   const questionCtrl = this.parentForm.controls[q.name];
      //   assert(questionCtrl, `Missing quesiton control for ${q.name}`);
      //   if (!vis) {
      //     questionCtrl.clearValidators();
      //   } else {
      //     questionCtrl.setValidators([Validators.required]);
      //   }
      //   questionCtrl.updateValueAndValidity();
      // });


    });
  }

  @Input()
  set dimmed(dimmed: boolean) {
    this.config.dimmed = dimmed;
    this.checkDimmed();
  }

  @Input()
  set side(pos: 'left' | 'right' | 'top' | 'bottom') {
    this.config.side = pos;
  }

  // get form() {
  //
  //   return this.parentForm2?.instance?.form;
  // }

  @Input() title: string;

  constructor(private ref: ElementRef, private uiSvc: UiService) {


  }

  ngAfterViewInit(): void {
    // this.originalState = this.questions.map(q => this.parentForm.controls[q.name]?.value);
    // this.saveEnabled = this.questions.filter((q, i) => {
    //   console.log('cehcking save', this.parentForm.controls[q.name]?.value, this.originalState[i]);
    //   return  this.parentForm.controls[q.name]?.value !== this.originalState[i];
    // }).length > 0;
    // this.parentForm.valueChanges.subscribe(() => {
    //   if (!this.config.visible) {
    //     return;
    //   }
    //   console.log(this.parentForm.value);
    //   this.ready = true;
    //   this.saveEnabled = this.questions.filter((q, i) => {
    //     console.log('cehcking save', this.parentForm.controls[q.name]?.value, this.originalState[i]);
    //     return  this.parentForm.controls[q.name]?.value !== this.originalState[i];
    //   }).length > 0;
    //   this.valid = this.questions.map(q => this.parentForm.controls[q.name]).filter(c => !c?.valid).length === 0;
    // });
  }

  ngOnInit(): void {
    this.ready = true;
    // this.parentForm?.addControl(this.form);

  }

  private checkDimmed() {
    // if (this.config.dimmed) {
    //   this.ref.nativeElement.style.zIndex = '9001';
    // } else {
    //   this.ref.nativeElement.style.zIndex = 'unset';
    // }
  }

  saveEnabled = false;
  valid = false;

  save() {
    // const val: {[name: string]: any} = {};
    // this.questions.forEach(q => {
    //   val[q.name] = this.parentForm.controls[q.name]?.value;
    // });
    // this.closed.emit(val);
    // this.config.visible = false;
  }

  close() {
    let val: {[name: string]: any} = null;
    if (!this.saveEnabled) {
      val = {};
      // this.questions.forEach(q => {
      //     val[q.name] = this.parentForm.controls[q.name]?.value;
      //   });
    }

    if (!val) {

    }

    this.closed.emit(val);
    this.config.visible = false;



  }

}

export interface ToolTipConfig {
  side: 'left' | 'right' | 'top' | 'bottom';
  visible?: boolean;
  relativeToRef?: TemplateRef<any>;
  relativeToElem?: BaseComponent | HTMLElement;
  relativeToId?: string;
  dimmed?: boolean;
}

