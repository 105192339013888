import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import {SharedModule} from './shared/shared.module';
import { HeaderComponent } from './nav/header/header.component';
import { LoginComponent } from './login/login.component';
import { SideNavComponent } from './nav/side-nav/side-nav.component';
import {AppInitService} from './app.init';
import { HelpComponent } from './nav/help/help.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaverickComponent } from './external/maverick/maverick.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeaderComponent,
    LoginComponent,
    SideNavComponent,
    HelpComponent,
    MaintenanceComponent,
    MaverickComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appLoadService: AppInitService) => () => appLoadService.init(),
      deps: [AppInitService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
