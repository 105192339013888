import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Button, ButtonStyle, ActionType} from '../../models/dialog-config.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  public buttonTypes = ActionType;
  public buttonStyles = ButtonStyle;
  private _config: Button;
  @Input()
  set config(config: Button) {
    this._config = config;
    this._config.onClick = this._config.onClick ?? (() => {});
    }


  get config(): Button {
    return this._config;
  }
  @Output() clicked = new EventEmitter<void>();

  @Input() disabled = false;

  click() {
    this.config.onClick();
    this.clicked.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
