import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DimmerComponent} from './components/dimmer/dimmer.component';
import {ButtonComponent} from './components/button/button.component';
import {AddressPipe} from './pipes/address.pipe';

import './extensions/string.extension';
import './extensions/array.extensions';
import './extensions/storage.extension';
import './extensions/form-control.extension';
import './extensions/object.extension';
import './extensions/date.extension';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GenericDialogComponent} from './components/generic-dialog/generic-dialog.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpAuthInterceptor} from './intercepters/http-auth.interceptor';
import {DayPipe} from './pipes/day.pipe';
import {PageComponent} from './components/page/page.component';
import {environment} from '../../environments/environment';
import {GetProductFullNamePipe} from './pipes/get-product-full-name.pipe';
import {ProductIconPipe} from './pipes/product-icon.pipe';
import {MatFileUploadModule} from 'mat-file-upload';
import {TargetedDialogComponent} from './components/targeted-dialog/targeted-dialog.component';
import {NgxTippyModule} from 'ngx-tippy-wrapper';
import {PrettyDialogComponent } from './components/pretty-dialog/pretty-dialog.component';
import {NgxMaskModule} from 'ngx-mask';
import {ClipboardModule} from 'ngx-clipboard';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ChatComponent } from './components/chat/chat.component';
import {ScriptApplierInterceptor} from './intercepters/script-applier.interceptor';

@NgModule({
  declarations: [
    DimmerComponent, ButtonComponent, AddressPipe, GenericDialogComponent, DayPipe, PageComponent,
    GetProductFullNamePipe,
    ProductIconPipe,
    TargetedDialogComponent,
    PrettyDialogComponent,
    DisableControlDirective,
    ChatComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFileUploadModule,
    NgxTippyModule,
    NgxMaskModule.forRoot(),
    ClipboardModule
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    DimmerComponent,
    ButtonComponent,
    AddressPipe,
    FormsModule,
    GetProductFullNamePipe,
    ProductIconPipe,
    ReactiveFormsModule,
    HttpClientModule,
    PageComponent,
    MatFileUploadModule,
    NgxTippyModule,
    NgxMaskModule,
    ClipboardModule,
    DisableControlDirective,
    ChatComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScriptApplierInterceptor,
      multi: true
    }
  ]
})
export class SharedModule {
  constructor() {
  }

}
