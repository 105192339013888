import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {QuotingService} from '../../quoting/quoting.service';
import {IQuestionPage} from '../models/quoting/quote-page.model';
import {IGenericQuestion, IQuestionPageSection} from '../models/quoting/questions.model';
import {Interpreter} from '../../dsl/interpreter.dsl';

@Injectable()
export class ScriptApplierInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient) {}

  private static scripts: {[key: string]: string} = {};

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const policyJwt = request.headers.get('policy');

    if (!policyJwt) {
      return next.handle(request);
    }

    const matches = (/.*?\/api\/quote.*\/pages\/(?<page>.*?(?=\/|$|\?))\/?(?<section>.*?(?=\/|$|\?))\/?(?<question>.*?(?=\/|$|\?))/.exec(request.url) ?? {}).groups || {};
    if (!matches?.['page']) {
      return next.handle(request);
    }

    const resp = next.handle(request);

    return resp.pipe(
      switchMap(async (val) => {
        if (val.type !== HttpEventType.Response) {
          return val;
        }
        const body: IQuestionPage|IQuestionPageSection|IGenericQuestion = val.body;
        const policy = QuotingService.jwtToQuote(policyJwt);
        const pageName = matches['page'] as string|null;

        // console.log('val', body);
        // // if (!page) {
        // return val;
        // // }
        //
        const section = matches['section'] as string|null;
        const question = matches['question'] as string|null;
        //
        const getScript = async (url: string) => await Interpreter.Download(url, this.http);

        let currentScript = (body.script ?? '').trim();
        if (currentScript.length) {
          currentScript += ';';
        }

        if (!section && !question) {
          body.script =  currentScript + await getScript(`assets/scripts/${policy.productAbv.toLowerCase()}/${pageName}/page.rule`);
        } else if (!question) {
          body.script = currentScript + await getScript(`assets/scripts/${policy.productAbv.toLowerCase()}/${pageName}/sections/${section}.rule`);
        } else {
          body.script = currentScript + await getScript(`assets/scripts/${policy.productAbv.toLowerCase()}/${pageName}/questions/${question}.rule`);
        }

        return val;
      })
    );
  }
}
