export class Pages {
    static  Eligibility = 'eligibility';
    static  Applicant = 'applicant';
    static  PolicyLevel = 'policylevel';
    static  LocationLevel = 'locationlevel';
    static  BuildingLevel = 'buildinglevel';
    static  DiscountSurcharge = 'discountsurcharges';
    static  Underwriting = 'underwriting';
    static  Payments = 'payments';
}
