import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import {LoggedInGuard} from './shared/guards/logged-in.guard';
import {LoginComponent} from './login/login.component';
import {MaverickComponent} from './external/maverick/maverick.component';


const routes: Routes = [
  {
    path: 'auth',
    component: LoginComponent
  },
  {
    path: 'auth/:id',
    component: LoginComponent
  },
  {
    path: 'quoting',
        canActivate: [LoggedInGuard],
        loadChildren: () => import('./quoting/quoting.module').then(m => m.QuotingModule)
  },
  {
    path: 'maverick',
    component: MaverickComponent
  },
  // {
  //   path: 'policies',
  //       canActivate: [LoggedInGuard],
  //       loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
  // },
  // {
  //   path: 'admin',
  //       canActivate: [LoggedInGuard],
  //       loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  // },
  // {
  //   path: 'agency',
  //       canActivate: [LoggedInGuard],
  //       loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule)
  // },
  // {
  //   path: 'reports',
  //       canActivate: [LoggedInGuard],
  //       loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  // },
  // {
  //   path: 'dashboard',
  //   canActivate: [LoggedInGuard],
  //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  // },
  { path: '**', redirectTo: '/quoting'},
  // {
  //   path: '',
  //   canActivate: [LoggedInGuard],
  //   loadChildren: () => import('./quoting/quoting.module').then(m => m.QuotingModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
