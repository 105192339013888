import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfig, PrettyDialogConfig} from '../../models/dialog-config.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as assert from 'assert';

@Component({
  selector: 'app-pretty-dialog',
  templateUrl: './pretty-dialog.component.html',
  styleUrls: ['./pretty-dialog.component.scss']
})
export class PrettyDialogComponent implements OnInit {

  msg: SafeHtml = '';


  constructor(public dialogRef: MatDialogRef<PrettyDialogComponent>,
              public sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public config: PrettyDialogConfig) {
    assert(config, 'Pretty dialogs must have a config');
    this.msg = sanitizer.bypassSecurityTrustHtml(config?.msg || '');
  }

  ngOnInit(): void {
  }


  submit() {
    this.config.submitBtn?.onClick?.();
    this.dialogRef.close();

  }

}
