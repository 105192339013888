import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models/product.data';

@Pipe({
  name: 'getProductFullName'
})
export class GetProductFullNamePipe implements PipeTransform {

  transform(value: string): string {
    switch (value?.toLowerCase()?.trim()) {
      case Product.BusinessOwnersPolicy:
        return 'Business Owners Policy';
      case Product.BuildersRisk:
        return 'Builders Risk';
      case Product.CommercialUmbrella:
        return 'Commercial Umbrella';
      case Product.ExcessFlood:
        return 'Excess Flood';
      case Product.Homeowners:
        return 'Homeowners';
      case Product.Pristine:
        return 'Pristine Homeowners';
      case Product.HomeOwnersAssociation:
        return 'Homeowners Association';
      case Product.MobileHomePark:
        return 'Mobile Home Park';
      case Product.DwellingProperty:
        return 'Dwelling Property';
      default:
        return value;
    }
  }

}
