import {ContactDetails, Person} from '../person.interface';
import {Address} from '../address.interface';


export interface Agency {
  agencyNum: string;
  legalName: string;
  dbaName: string;
  website: string;
  address: Address;
  contactName: string;
  contactDetails: ContactDetails;
  agents: Agent[];
}


export enum Day {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}


export interface Agent extends Person {
  agencyNum: string;
  userName: string;
  license?: string;
  password?: string;
  access: {
    isInactive?: boolean;
    roles?: string[];
    days: Day[];
    time: { start: number, end: number };
  };
}
