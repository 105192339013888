import {Injectable} from '@angular/core';
import {INavPage, NavPage, NavProvider} from '../shared/models/nav-page.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {NetworkingUtils} from '../shared/networking/networking.utils';
import {BehaviorSubject} from 'rxjs';
import {Report, ReportData} from '../shared/models/report.interface';
import {PaymentActivityReportComponent} from './report-views/payment-activity-report/payment-activity-report.component';

import '../shared/extensions/array.extensions';

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements NavProvider {


  private _reports = new BehaviorSubject<Report[]>([]);

  constructor(private http: HttpClient) {
  }

  public async getReport(idOrName: string | number): Promise<Report> {
    let report = this._reports.value.find(r => {
      if (r.name === idOrName || r.id === idOrName) {
        return r;
      }
      return null;
    });

    if (report) {
      return report;
    }

    report = await this.http.get<Report>(`https://localhost:5002/reports/${idOrName}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })
      ).toPromise();
    this._reports.value.push(report);
    this._reports.next(this._reports.value);
    return report;
  }

  public async getReports(): Promise<Report[]> {

    if (this._reports.value.length) {
      return this._reports.value;
    }

    this._reports.next(await this.http.get<Report[]>(`https://localhost:5002/reports`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })
      ).toPromise());
    return this._reports.value;
  }

  public async loadReport(reportName: string, values: { [key: string]: string | number } = {}): Promise<ReportData> {
    const params = Object.keys(values).map(key => key + '=' + values[key]).join('&');
    return await this.http.get<ReportData>(`https://localhost:5002/reports/${reportName}?${params}`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw NetworkingUtils.CatchNetworkErrors(error);
        })
      ).toPromise();
  }


  get pages(): Promise<INavPage[]> {

    const categoryPages: {[cat: string]: INavPage[]} = {};
    const freePages: INavPage[] = [];
    return new Promise<INavPage[]>(async resolve => {
      // const reports = await this.getReports();
      const reports: Report[] = [];

      reports.forEach(r => {
        const page = new NavPage(
          {
            pageTitle: r.name,
            // isAccordion: !!r.category,
            navLink: r.category ? null : `${r.name.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase()}`
          });

        if (!r.category) {
          freePages.push(page);
        } else {
          if (!categoryPages[r.category]) {
            categoryPages[r.category] = [];
          }
          categoryPages[r.category].push(page);
        }
      });

      resolve([
        new NavPage({
          pageTitle: 'Reports',
          navIcon: 'analytics',
          subPages: [
            ...Object.keys(categoryPages).map(cat => new NavPage({pageTitle: cat, isAccordion: true, subPages: categoryPages[cat]}, 'reports')),
            ...freePages
          ]
        }),
      ]);
    });
  }
}


export interface ReportModifier {
  fieldText?: (item: string | number, options: { isWidgetView: boolean, field: string, row: { [key: string]: string | number } }) => string | number;
  columns?: (defaultColumns: string[], widgetView: boolean) => string[];
}

export const ReportModifiers: { [reportName: string]: ReportModifier } = {
  'Payment Activity': {
    fieldText: (item: string | number, options: { isWidgetView: boolean, field: string, row: { [key: string]: string | number } }) => {
      // console.log(options.field);
      if (options.field === 'Amount') {
        return '$' + item;
      } else if (options.field === 'Policy') {
        return `<a href="/policies/${item}">${item}</a>`;
      } else if (options.isWidgetView && options.field === 'Insured') {
        return `${item}<br/><a href="/policies/${options.row['Policy']}">${options.row['Policy']}</a>`;
      }
      return item;
    },
    columns: (defaultColumns: string[], widgetView: boolean) => {
      return widgetView ?
        defaultColumns.remove('Policy', 'Type', 'Product', 'Payor', 'Status') : defaultColumns;
    }
  }
};
