
Object.isNullOrUndefined = (obj: any) => obj === null || obj === undefined;

Object.isPrimitive = (obj: any) => {
    if (Object.isNullOrUndefined(obj)) {
      return true;
    }
    switch (typeof obj) {
      case 'undefined':
      case 'boolean':
      case 'number':
      case 'string':
      case 'bigint':
        return true;
      default:
        return false;
    }
};


Object.propsEqual = (obj1: {[key: string]: any}, obj2: {[key: string]: any}) => {

  if (obj1 === obj2) {
    return true;
  }
  const obj1Null = Object.isNullOrUndefined(obj1);
  const obj2Null = Object.isNullOrUndefined(obj2);

  if (obj1Null && !obj2Null) {
    return false;
  }
  if (!obj1Null && obj2Null) {
    return false;
  }
  if (obj1Null && obj2Null) {
    return true;
  }


  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();

  if (obj1Keys.length !== obj2Keys.length) {
    // console.log('different key len', obj1Keys, obj2Keys);
    return false;
  }

  for (let i = 0; i < obj1Keys.length; i++) {
    const key1 = obj1Keys[i];
    const key2 = obj2Keys[i];

    const val1 = obj1[key1];
    const val2 = obj2[key2];

    // console.group(key1, obj1, key2, val2);

    let same: boolean;
    if (key1 !== key2) {
      same = false;
    } else if (typeof val1 !== typeof val2) {
      same = false;
    } else if (typeof val1 === 'object') {
      same = Object.propsEqual(val1, val2);
    } else {
      same = val1 === val2;
    }
    // console.log(key1, same, val1, val2);
    // console.groupEnd();
    if (!same) {
      return same;
    }

  }

  return true;
};
