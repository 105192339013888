import {Injectable, RendererFactory2, TemplateRef} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ActionType, ButtonStyle, DialogConfig, PrettyDialogConfig} from './models/dialog-config.interface';
import {GenericDialogComponent} from './components/generic-dialog/generic-dialog.component';
import {IApiErrorResponse} from './networking/networking.utils';
import {NgxTippyService} from 'ngx-tippy-wrapper';
import {PrettyDialogComponent} from './components/pretty-dialog/pretty-dialog.component';
import {StorageKeys} from './extensions/storage.extension';
import * as jwtDecode from 'jwt-decode';
import {Product} from './models/product.data';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private dimmerChanged = new BehaviorSubject(false);
  private popupChanged = new BehaviorSubject(false);
  private titleChanged = new BehaviorSubject('');
  private messageChanged = new BehaviorSubject('');

  private startedDimTime = 0;

  private dimLockId = -1;

  private keyListeners: ((event: KeyboardEvent) => void)[] = [];

  get title(): Observable<string> {
    return this.titleChanged.asObservable();
  }

  get message(): Observable<string> {
    return this.messageChanged.asObservable();
  }

  get dimmed(): Observable<boolean> {
    return this.dimmerChanged.asObservable();
  }

  get popupDisplayed(): Observable<boolean> {
    return this.popupChanged.asObservable();
  }

  private _showingError = false;
  public get showingError() {
    return this._showingError;
  }

  constructor(public dialog: MatDialog, private renderFactory: RendererFactory2, private tippySvc: NgxTippyService) {

    renderFactory.createRenderer(null, null).listen('document', 'keydown', (event) => {
      // console.log('keydown1', event);
      this.keyListeners.forEach(handler => handler(event));
    });

    renderFactory.createRenderer(null, null).listen('window', 'keydown', (event) => {
      // console.log('keydown2', event);
      this.keyListeners.forEach(handler => handler(event));
    });

    this.tippySvc.instancesChanges.subscribe(instance => {
      // if (instance.instance.props.theme === 'fullscreen') {
      //   console.log('showing', instance.name, instance.reason, instance.instance.state);
        this.popupChanged.next(instance.instance.state.isVisible);
        // console.log('popupidmmer-', this.popupChanged.value);
      // }
    });
  }

  // @HostListener('window:keydown', ['$event'])
  // onKeyDown(event) {
  // ...
  // }
  public listenToKeyPress = (onKey: (key: KeyboardEvent) => void) => this.keyListeners.push(onKey);

  public dimLock(title?: string, message?: string) {
    this.dimLockId++;
    this.dim(title, message);

    return this.dimLockId;
  }

  public dim(title?: string, message?: string) {
    this.startedDimTime = Date.now();
    this.dimmerChanged.next(true);
    this.titleChanged.next(title ?? '');
    this.messageChanged.next(message ?? '');
  }

  public async dimFor(call: Promise<any>, title?: string, message?: string) {
    this.dim(title, message);
    try {
      await call;
    } catch (e) {
      this.undim();
      throw e;
    }
  }

  public async undim(dimLockId: number = -1): Promise<void> {
    if (dimLockId !== this.dimLockId && this.dimLockId !== -1) {
      return;
    }
    const stopTime = Date.now();
    return new Promise<void>(resolve => {
      setTimeout(() => {
        if (dimLockId !== this.dimLockId && this.dimLockId !== -1) {
          resolve();
          return;
        }
        if (dimLockId !== -1) {
          console.groupEnd();
        }
        this.dimLockId = -1;
        this.dimmerChanged.next(false);
        this.titleChanged.next('');
        this.messageChanged.next('');
        this.startedDimTime = 0;
        resolve();
      }, stopTime - this.startedDimTime > 500 ? 0 : 500);
    });
  }

  public alert(title: string, msg: string, buttonTitle?: string) {
    this.undim(this.dimLockId);
    return new Promise<void>(resolve => {
      const dialogRef = this.dialog.open<GenericDialogComponent, DialogConfig>(GenericDialogComponent, {
        data: {
          title,
          msg,
          submitBtn: {text: buttonTitle ?? 'Okay', type: ActionType.Success, style: ButtonStyle.Stroked}
        }
      });

      dialogRef.afterClosed().subscribe(() => resolve());
    });
  }

  public success(title?: string, msg?: string, template?: TemplateRef<any>): Promise<void> {
    this.undim(this.dimLockId);
    return new Promise<void>(resolve => {
      const dialogRef = this.dialog.open<GenericDialogComponent, DialogConfig>(GenericDialogComponent, {
        data: {
          title,
          msg,
          template,
          submitBtn: {text: 'Okay', type: ActionType.Success, style: ButtonStyle.Stroked}
        }
      });

      dialogRef.afterClosed().subscribe(() => resolve());
    });
  }

  public prettyDialog(config: PrettyDialogConfig, allowClickOutsideClose: boolean = false) {
    this.undim(this.dimLockId);
    return new Promise<void>(resolve => {
      const dialogRef = this.dialog.open<PrettyDialogComponent, PrettyDialogConfig>(PrettyDialogComponent, {
        data: config,
        panelClass: 'pretty-dialog',
        disableClose: !allowClickOutsideClose
      });
      dialogRef.afterClosed().subscribe(() => resolve());
    });
  }


  public error(title?: string, msg?: string | IApiErrorResponse, template?: TemplateRef<any>) {

    this._showingError = true;
    const errorMsg = UiService.MapErrorMessage(msg);

    console.error(title, msg);

    return new Promise<void>(async resolve => {
      await this.prettyDialog({
        msg: errorMsg,
        submitBtn: {text: 'Okay', type: ActionType.Info, style: ButtonStyle.None},
        template,
        title: title?.toProperCase() ?? '',
        type: ActionType.Danger
      });
      this._showingError = false;
      resolve();
    });

  }

  public info(title?: string, msg?: string, template?: TemplateRef<any>) {

    return this.prettyDialog({
      msg,
      submitBtn: {text: 'Okay', type: ActionType.Info, style: ButtonStyle.None},
      template,
      title: title?.toProperCase() ?? '',
      type: ActionType.Info
    });

  }

  public confirm(config: {title?: string, msg?: string, noText?: string, yesText?: string, size?: number|string}, template?: TemplateRef<any>): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.dialog.open<PrettyDialogComponent, PrettyDialogConfig>(PrettyDialogComponent, {
        data: {
          title: config.title,
          msg: config.msg,
          template,
          cancelBtn: {text: config.noText ?? 'Cancel', type: ActionType.Warn, style: ButtonStyle.None, onClick: () => resolve(false)},
          submitBtn: {text: config.yesText ?? 'Confirm', type: ActionType.Success, style: ButtonStyle.Solid, onClick: () => resolve(true)},
          type: ActionType.Info,
          size: config.size
        },
        panelClass: 'pretty-dialog',
        disableClose: true
      });
    });
  }

  public input<T>(title?: string, msg?: string, template?: TemplateRef<any>): Promise<T> {
    return new Promise<T>(resolve => {
      const dialogRef = this.dialog.open<GenericDialogComponent, DialogConfig, T>(GenericDialogComponent, {
        data: {
          title,
          msg,
          template,
          cancelBtn: {text: 'Cancel', type: ActionType.Warn, style: ButtonStyle.None},
          submitBtn: {text: 'Submit', type: ActionType.Success, style: ButtonStyle.Solid},
        }
      });

      dialogRef.afterClosed().subscribe(choice => resolve(choice));
    });
  }

  public static async sleep(ms: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => resolve(), ms);
    });
  }


  private static MapErrorMessage(error: string|IApiErrorResponse): string {
    let message = 'Unknown Error';
    if (error) {
      console.log(error);
      const apiFriendly = (error as IApiErrorResponse).message;
      if (apiFriendly) {
        message = apiFriendly;
      } else {
        message = error as string;
      }
    }
    let html = `<p style="width: 100%; text-align: center" class="paragraph">${message}</p>`;
    const apiErr = (error as IApiErrorResponse);
    if (apiErr.description ?? apiErr.Description) {
      html += `<span style="width: 100%; text-align: center"  class="paragraph-sub">Reference: ${apiErr.description ?? apiErr.Description}</span>`;
    }
    return html;
  }

  static ShowChat(show: boolean): void {
    const onlineChat = document.getElementById('online-chat');
    if (onlineChat) {
      if (show) {
        onlineChat.style.display = '';
      } else {
        onlineChat.style.display = 'none';
      }
    }
  }

  static openAskBChat(): void {
    const authToken = localStorage.get(StorageKeys.Token);
    let url = `https://askb.bankersinsurance.com`;
    if (authToken) {
      const decoded: any = jwtDecode(authToken);
      let params = `AgentId=${decoded.AgentNumber}&AgentName=${decoded.AgentFirstName} ${decoded.AgentLastName}`;
      const currentQuote = localStorage.get(StorageKeys.CurrentQuote);
      if (currentQuote) {
        params += `&Product=${currentQuote.productAbv}&State=${currentQuote.stateAbv}`;
      }
      url = `${url}?${params}`;
    }
    window.open(url, '_blank', 'width=600,height=600');
  }

  static isAskBChat(): boolean {
    const currentQuote = localStorage.get(StorageKeys.CurrentQuote);
    if (currentQuote) {
      if (currentQuote.productAbv?.toLowerCase() === Product.ModelBusinessOwnersPolicy) {
        return true;
      }
    }
    return false;
  }
}
